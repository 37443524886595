import { Loading } from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import {
  App,
  Objective,
  Organization,
} from '@event-horizon/graphql-api-schema';
import { ReactElement } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { useQuery } from 'urql';

const GetObjectiveOutlet = graphql(`
  query GetObjectiveOutlet($orgId: ID!, $appId: ID!, $objectiveId: ID!) {
    me {
      id
      organization(id: $orgId) {
        id
        name
        app(id: $appId) {
          id
          name
          objective(id: $objectiveId) {
            id
            name
            indicator {
              id
              name
            }
            threshold {
              ... on BoundedThreshold {
                gte
                lte
              }
              ... on LowerThreshold {
                lte
              }
              ... on UpperThreshold {
                gte
              }
            }
            percentile
          }
        }
        apps {
          id
          name
        }
      }
    }
  }
`);

type ContextType = {
  application: Pick<App, 'id' | 'name'>;
  applications: Pick<App, 'id' | 'name'>[];
  objective: Pick<
    Objective,
    'id' | 'name' | 'indicator' | 'threshold' | 'percentile'
  >;
  organization: Pick<Organization, 'id' | 'name'>;
};

export function ObjectiveOutlet(): ReactElement {
  const { appId, objectiveId, orgId } = useParams<{
    orgId: string;
    appId: string;
    objectiveId: string;
  }>();

  if (!orgId) {
    throw new Error('The orgId parameter is required.');
  }

  if (!appId) {
    throw new Error('The appId parameter is required.');
  }

  if (!objectiveId) {
    throw new Error('The objectiveId parameter is required.');
  }

  const [{ data }] = useQuery({
    query: GetObjectiveOutlet,
    variables: {
      orgId,
      appId,
      objectiveId,
    },
  });

  if (!data) {
    return <Loading />;
  }

  if (!data.me.organization) {
    throw new Error('The organization was not found.');
  }
  if (!data.me.organization.app) {
    throw new Error('The application was not found.');
  }
  if (!data.me.organization.app.objective) {
    throw new Error('The objective was not found.');
  }

  return (
    <Outlet
      context={{
        application: data.me.organization.app,
        applications: data.me.organization.apps,
        objective: data.me.organization.app.objective,
        organization: data.me.organization,
      }}
    />
  );
}

export function useObjective() {
  return useOutletContext<ContextType>();
}
