import { MeasurementResult } from '@getpolaris.ai/sdk';
import {
  Profiler,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
} from 'react';
import useSdk from '../hooks/use-sdk';
import { flush, getQueue, push } from './measurement-queue';

interface ProfilerProps {
  id: string;
  children: ReactNode;
  connected: boolean;
  onRender?: (id: string, phase: string, actualDuration: number) => void;
}

export default function ({
  children,
  connected,
  id,
  onRender,
}: ProfilerProps): ReactElement {
  const { logMeasurement } = useSdk();

  const handleOnRender = useCallback(
    (id: string, phase: string, actualDuration: number) => {
      push(id, phase, actualDuration);

      if (onRender) {
        onRender(id, phase, actualDuration);
      }
    },
    [onRender]
  );

  useEffect(() => {
    if (connected && getQueue().length > 0) {
      getQueue().forEach((item) => {
        logMeasurement(
          item.phase,
          MeasurementResult.SUCCESS,
          item.actualDuration,
          window?.location?.href ?? null,
          {
            id: item.id,
          }
        );
      });
      flush();
    }
  }, [connected, logMeasurement]);

  return (
    <Profiler id={id} onRender={handleOnRender}>
      {children}
    </Profiler>
  );
}
