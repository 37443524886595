import {
  Button,
  CodeEditor,
  Dialog,
  Input,
} from '@event-horizon/app-components';
import { FormikHelpers, useFormik } from 'formik';
import { ReactElement, useCallback } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

export interface AddPropertyFormValues {
  headerName: string;
  mapFunction: string;
}

interface AddPropertyDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (
    values: AddPropertyFormValues,
    formikHelpers: FormikHelpers<AddPropertyFormValues>
  ) => void | Promise<void>;
}

const Form = styled.form`
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Small = styled.small`
  color: rgba(255, 255, 255, 0.56);
  font: 500 10px/12px Lexend, sans-serif;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

export function AddPropertyDialog({
  open,
  onClose,
  onSubmit,
}: AddPropertyDialogProps): ReactElement {
  const validationSchema = yup.object({
    headerName: yup.string().required('Name is required'),
    mapFunction: yup.string(),
  });

  const handleOnAddProperty = useCallback(() => {
    onClose();
  }, [onClose]);

  const {
    dirty,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    touched,
    values,
  } = useFormik<AddPropertyFormValues>({
    initialValues: {
      headerName: '',
      mapFunction: [
        'function map(measurement) {',
        '  // example: return measurement.duration;',
        '  ',
        '}',
      ].join('\n'),
    },
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Dialog open={open} onClose={onClose} title="Add Property">
      <Form onSubmit={handleSubmit}>
        <Input
          error={touched.headerName && Boolean(errors.headerName)}
          fullWidth
          helperText={touched.headerName && errors.headerName}
          label="Name"
          name="headerName"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.headerName}
          variant="outlined"
        />
        <div>
          <CodeEditor
            name="mapFunction"
            value={values.mapFunction}
            onChange={handleChange}
          />
          <Small>
            TIP: provide a map function that is invoked with the measurement.
          </Small>
        </div>
        <Actions>
          <Button type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!isValid}
            onClick={handleOnAddProperty}
            raised
            type="submit"
          >
            Add Property
          </Button>
        </Actions>
      </Form>
    </Dialog>
  );
}
