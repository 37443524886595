import {
  ConnectionQuality,
  DeviceType,
  MeasurementResult,
} from '@event-horizon/graphql-api-schema';
import { getApiUrl } from './config/api-url';

interface LogMeasurementBody {
  apiKey: string;
  duration: number;
  metadata: {
    connectionQuality: ConnectionQuality | null;
    deviceType: DeviceType;
    userMetadata: object;
    pluginMetadata?: object;
  };
  name: string;
  result: MeasurementResult;
  timestamp: string;
  url: string | null;
}

/**
 * Send a measurement to the Polaris API.
 *
 * This javascript function is isomorphic, meaning it can be run in both the browser and Node.js.
 * First, we attempt to use the `window.navigator.sendBeacon` API, which is supported in most modern browsers.
 * If that fails, we fall back to using the `fetch` API.
 */
export const sendMeasurement = (
  body: LogMeasurementBody
): Promise<void | Response> => {
  if (
    typeof window !== 'undefined' &&
    window.navigator &&
    window.navigator.sendBeacon
  ) {
    window.navigator.sendBeacon(
      `${getApiUrl()}/measurement`,
      JSON.stringify(body)
    );
    return Promise.resolve();
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (globalThis.fetch) {
    return fetch(`${getApiUrl()}/measurement`, {
      method: 'POST',
      body: JSON.stringify(body),

      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  return Promise.reject('No method for sending measurement.');
};
