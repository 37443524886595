/**
 * @todo Replace with the production URL
 */
let apiUrl = 'https://api.getpolaris.ai/api/v1';

export function setApiUrl(url: string): void {
  apiUrl = url;
}

export function getApiUrl(): string {
  return apiUrl;
}
