import { useAuth } from '@event-horizon/app-auth';
import { glassSurface } from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { FormikHelpers } from 'formik';
import { ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation } from 'urql';
import { SignUpForm, SignUpFormValues } from './SignUpForm';

const CreateApp = graphql(`
  mutation CreateApp($input: CreateAppInput!) {
    createApp(input: $input) {
      id
    }
  }
`);

const CreateOrganization = graphql(`
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      id
    }
  }
`);

const UpdateUser = graphql(`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`);

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 16px 0 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
`;

const Heading = styled.div`
  width: 100%;
  max-width: 30em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  > h2 {
    color: transparent;
    background-clip: text;
    font-size: 32px;
    line-height: 1.5;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    background-image: radial-gradient(
      81.96% 2884.43% at 86.39% 19.83%,
      rgba(16, 221, 211, 0.8) 0%,
      rgba(95, 117, 249, 0.72) 72%,
      rgba(181, 87, 255, 0.6) 100%
    );
  }

  > p {
    font-size: 18px;
    text-align: center;
  }
`;

const Card = styled.div`
  ${glassSurface};
  --glass-background-color-a: rgba(16, 221, 211, 0.12);
  --glass-background-color-b: rgba(95, 117, 249, 0.12);
  --glass-background-color-c: rgba(181, 87, 255, 0.12);
  width: 100%;
  max-width: 30em;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 32px;
  padding: 24px;

  > p {
    font-size: 14px;
    text-align: center;
  }
`;

export function SignUp(): ReactElement {
  const { user } = useAuth();
  const [, createOrganization] = useMutation(CreateOrganization);
  const [, createApp] = useMutation(CreateApp);
  const [, updateUser] = useMutation(UpdateUser);
  const navigate = useNavigate();

  const handleOnSubmit = useCallback(
    async (
      values: SignUpFormValues,
      formikHelpers: FormikHelpers<SignUpFormValues>
    ) => {
      await updateUser({
        input: {
          id: user!.sub,
          email: user?.email ?? '',
          name: values.userName,
        },
      });
      const createOrganizationResult = await createOrganization({
        input: { name: values.orgName, logo: '' },
      });
      const organizationId =
        createOrganizationResult.data!.createOrganization.id;
      const createApplicationResult = await createApp({
        input: {
          name: values.appName,
          organizationId,
          webhooks: [],
          sdk: values.sdk,
        },
      });
      const appId = createApplicationResult.data!.createApp.id;
      navigate(`/org/${organizationId}/app/${appId}/`);
    },
    [createApp, createOrganization, updateUser, navigate, user]
  );

  return (
    <Container>
      <Heading>
        <h2>Try Polaris for Free</h2>
        <p>
          Get $10 in credits.
          <br />
          No credit card required.
        </p>
      </Heading>
      <Card>
        <p>Just need a few things to get you going</p>
        <SignUpForm onSubmit={handleOnSubmit} />
      </Card>
    </Container>
  );
}
