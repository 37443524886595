/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  query GetApplicationOutlet($orgId: ID!, $appId: ID!) {\n    me {\n      id\n      name\n      email\n      organization(id: $orgId) {\n        id\n        name\n        logo\n        app(id: $appId) {\n          id\n          name\n          apiKey\n          measurementBudget\n          webhooks {\n            url\n          }\n          sdk\n        }\n        apps {\n          id\n          name\n        }\n        userConnection {\n          edges {\n            role\n          }\n        }\n      }\n    }\n  }\n':
    types.GetApplicationOutletDocument,
  '\n  query GetCreateApplicationPage {\n    me {\n      id\n      organizations {\n        id\n        name\n      }\n    }\n  }\n':
    types.GetCreateApplicationPageDocument,
  '\n  mutation CreateApp($input: CreateAppInput!) {\n    createApp(input: $input) {\n      id\n    }\n  }\n':
    types.CreateAppDocument,
  '\n  query GetDeleteApplicationPage($orgId: ID!, $appId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        app(id: $appId) {\n          id\n          name\n          objectives {\n            id\n          }\n          indicators {\n            id\n          }\n        }\n      }\n    }\n  }\n':
    types.GetDeleteApplicationPageDocument,
  '\n  mutation DeleteApp($input: DeleteAppInput!) {\n    deleteApp(input: $input)\n  }\n':
    types.DeleteAppDocument,
  '\n  mutation UpdateAppSdk($input: UpdateAppInput!) {\n    updateApp(input: $input) {\n      id\n      sdk\n    }\n  }\n':
    types.UpdateAppSdkDocument,
  '\n  mutation CreateIndicator($input: CreateIndicatorInput!) {\n    createIndicator(input: $input) {\n      id\n    }\n  }\n':
    types.CreateIndicatorDocument,
  '\n  query GetDeleteIndicatorPage($orgId: ID!, $appId: ID!, $indicatorId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        app(id: $appId) {\n          id\n          indicator(id: $indicatorId) {\n            id\n            name\n            objectives {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.GetDeleteIndicatorPageDocument,
  '\n  mutation DeleteIndicator($input: DeleteIndicatorInput!) {\n    deleteIndicator(input: $input)\n  }\n':
    types.DeleteIndicatorDocument,
  '\n  query GetHistoryForIndicator(\n    $orgId: ID!\n    $appId: ID!\n    $indicatorId: ID!\n    $query: IndicatorHistoryQueryInput!\n  ) {\n    me {\n      organization(id: $orgId) {\n        app(id: $appId) {\n          indicator(id: $indicatorId) {\n            history(input: $query) {\n              state\n              start\n              end\n              result {\n                __typename\n                ... on AverageResult {\n                  values {\n                    percentile\n                    value\n                  }\n                }\n                ... on RateResult {\n                  value\n                }\n                ... on CountResult {\n                  value\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.GetHistoryForIndicatorDocument,
  '\n  query GetIndicatorHistory(\n    $orgId: ID!\n    $appId: ID!\n    $indicatorId: ID!\n    $query: IndicatorHistoryQueryInput!\n  ) {\n    me {\n      organization(id: $orgId) {\n        app(id: $appId) {\n          indicator(id: $indicatorId) {\n            operation\n            history(input: $query) {\n              state\n              start\n              end\n              result {\n                __typename\n                ... on AverageResult {\n                  values {\n                    percentile\n                    value\n                  }\n                }\n                ... on RateResult {\n                  value\n                }\n                ... on CountResult {\n                  value\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.GetIndicatorHistoryDocument,
  '\n  query GetIndicatorOutlet($orgId: ID!, $appId: ID!, $indicatorId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        name\n        app(id: $appId) {\n          id\n          name\n          indicator(id: $indicatorId) {\n            id\n            name\n            operation\n            predicateFunction\n            window\n            description\n            objectives {\n              id\n              name\n              state\n              stateLastUpdated\n            }\n            logMessagesConnection {\n              edges {\n                node {\n                  id\n                  message\n                  timestamp\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.GetIndicatorOutletDocument,
  '\n  query GetIndicatorsWithHistory($orgId: ID!, $appId: ID!) {\n    me {\n      organization(id: $orgId) {\n        app(id: $appId) {\n          indicators {\n            id\n            name\n            operation\n            logMessagesConnection(first: 100) {\n              edges {\n                node {\n                  message\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.GetIndicatorsWithHistoryDocument,
  '\n  query GetMeasurementOutlet(\n    $orgId: ID!\n    $appId: ID!\n    $indicatorId: ID!\n    $measurementId: ID!\n  ) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        name\n        app(id: $appId) {\n          id\n          name\n          indicator(id: $indicatorId) {\n            id\n            name\n            measurement(id: $measurementId) {\n              id\n              name\n              result\n              timestamp\n              metadata {\n                connectionQuality\n                deviceType\n                userMetadata\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.GetMeasurementOutletDocument,
  '\n  query GetMeasurementPage(\n    $orgId: ID!\n    $appId: ID!\n    $indicatorId: ID!\n    $measurementId: ID!\n  ) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        name\n        app(id: $appId) {\n          id\n          name\n          indicator(id: $indicatorId) {\n            id\n            name\n            measurement(id: $measurementId) {\n              id\n              duration\n              metadata {\n                connectionQuality\n                deviceType\n                userMetadata\n              }\n              name\n              result\n              timestamp\n              url\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.GetMeasurementPageDocument,
  '\n  query GetIndicatorMeasurements(\n    $orgId: ID!\n    $appId: ID!\n    $indicatorId: ID!\n    $size: Int!\n    $after: String\n  ) {\n    me {\n      organization(id: $orgId) {\n        app(id: $appId) {\n          indicator(id: $indicatorId) {\n            measurementsConnection(first: $size, after: $after) {\n              pageInfo {\n                endCursor\n                hasNextPage\n              }\n              edges {\n                node {\n                  id\n                  duration\n                  metadata {\n                    connectionQuality\n                    deviceType\n                    userMetadata\n                  }\n                  name\n                  result\n                  timestamp\n                  url\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.GetIndicatorMeasurementsDocument,
  '\n  mutation SuggestIndicators($input: SuggestIndicatorsInput!) {\n    suggestIndicators(input: $input) {\n      appId\n      name\n      operation\n      predicateFunction\n      window\n    }\n  }\n':
    types.SuggestIndicatorsDocument,
  '\n  query PreviewIndicator(\n    $orgId: ID!\n    $appId: ID!\n    $input: PreviewIndicatorInput!\n  ) {\n    me {\n      organization(id: $orgId) {\n        app(id: $appId) {\n          previewIndicator(input: $input) {\n            history {\n              start\n              end\n              state\n              result {\n                __typename\n                ... on RateResult {\n                  value\n                }\n                ... on AverageResult {\n                  values {\n                    percentile\n                    value\n                  }\n                }\n                ... on CountResult {\n                  value\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.PreviewIndicatorDocument,
  '\n  mutation SaveSuggestedIndicator($input: CreateIndicatorInput!) {\n    createIndicator(input: $input) {\n      id\n    }\n  }\n':
    types.SaveSuggestedIndicatorDocument,
  '\n  mutation UpdateIndicator($input: UpdateIndicatorInput!) {\n    updateIndicator(input: $input) {\n      id\n    }\n  }\n':
    types.UpdateIndicatorDocument,
  '\n  mutation CreateObjective($input: CreateObjectiveInput!) {\n    createObjective(input: $input) {\n      id\n    }\n  }\n':
    types.CreateObjectiveDocument,
  '\n  query GetDeleteObjectivePage($orgId: ID!, $appId: ID!, $objectiveId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        app(id: $appId) {\n          id\n          objective(id: $objectiveId) {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n':
    types.GetDeleteObjectivePageDocument,
  '\n  mutation DeleteObjective($input: DeleteObjectiveInput!) {\n    deleteObjective(input: $input)\n  }\n':
    types.DeleteObjectiveDocument,
  '\n  query GetIndicators($orgId: ID!, $appId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        app(id: $appId) {\n          id\n          indicators {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n':
    types.GetIndicatorsDocument,
  '\n  query GetObjectiveHistory(\n    $orgId: ID!\n    $appId: ID!\n    $objectiveId: ID!\n    $objectiveQuery: ObjectiveHistoryQueryInput!\n    $indicatorQuery: IndicatorHistoryQueryInput!\n  ) {\n    me {\n      organization(id: $orgId) {\n        app(id: $appId) {\n          objective(id: $objectiveId) {\n            id\n            history(input: $objectiveQuery) {\n              state\n              start\n              end\n            }\n            indicator {\n              history(input: $indicatorQuery) {\n                state\n                start\n                end\n                result {\n                  __typename\n                  ... on AverageResult {\n                    values {\n                      percentile\n                      value\n                    }\n                  }\n                  ... on RateResult {\n                    value\n                  }\n                }\n              }\n            }\n            percentile\n            threshold {\n              ... on LowerThreshold {\n                __typename\n                lte\n              }\n              ... on BoundedThreshold {\n                __typename\n                lte\n                gte\n              }\n              ... on UpperThreshold {\n                __typename\n                gte\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.GetObjectiveHistoryDocument,
  '\n  query GetObjectiveOutlet($orgId: ID!, $appId: ID!, $objectiveId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        name\n        app(id: $appId) {\n          id\n          name\n          objective(id: $objectiveId) {\n            id\n            name\n            indicator {\n              id\n              name\n            }\n            threshold {\n              ... on BoundedThreshold {\n                gte\n                lte\n              }\n              ... on LowerThreshold {\n                lte\n              }\n              ... on UpperThreshold {\n                gte\n              }\n            }\n            percentile\n          }\n        }\n        apps {\n          id\n          name\n        }\n      }\n    }\n  }\n':
    types.GetObjectiveOutletDocument,
  '\n  query GetObjectivesPage(\n    $orgId: ID!\n    $appId: ID!\n    $query: ObjectiveHistoryQueryInput!\n  ) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        name\n        logo\n        app(id: $appId) {\n          id\n          name\n          objectives {\n            id\n            name\n            percentile\n            state\n            history(input: $query) {\n              start\n              end\n              state\n            }\n            threshold {\n              ... on LowerThreshold {\n                __typename\n                lte\n              }\n              ... on BoundedThreshold {\n                __typename\n                lte\n                gte\n              }\n              ... on UpperThreshold {\n                __typename\n                gte\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.GetObjectivesPageDocument,
  '\n  mutation UpdateObjective($input: UpdateObjectiveInput!) {\n    updateObjective(input: $input) {\n      id\n      name\n    }\n  }\n':
    types.UpdateObjectiveDocument,
  '\n  mutation CreateOrganization($input: CreateOrganizationInput!) {\n    createOrganization(input: $input) {\n      id\n    }\n  }\n':
    types.CreateOrganizationDocument,
  '\n  query GetDeleteOrganizationPage($orgId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        apps {\n          id\n        }\n      }\n    }\n  }\n':
    types.GetDeleteOrganizationPageDocument,
  '\n  mutation DeleteOrganization($input: DeleteOrganizationInput!) {\n    deleteOrganization(input: $input)\n  }\n':
    types.DeleteOrganizationDocument,
  '\n  query GetOrganizationOutlet($id: ID!) {\n    me {\n      id\n      name\n      email\n      organization(id: $id) {\n        id\n        name\n        logo\n        apps {\n          id\n          name\n        }\n        userConnection {\n          edges {\n            role\n          }\n        }\n      }\n    }\n  }\n':
    types.GetOrganizationOutletDocument,
  '\n  query GetOrganizationsPage($objectiveQuery: ObjectiveHistoryQueryInput!) {\n    me {\n      id\n      name\n      email\n      organizations {\n        id\n        name\n        logo\n        apps {\n          id\n          name\n          objectives {\n            history(input: $objectiveQuery) {\n              start\n              end\n              state\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.GetOrganizationsPageDocument,
  '\n  mutation UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n    }\n  }\n':
    types.UpdateUserDocument,
  '\n  mutation UpdateOrganization($input: UpdateOrganizationInput!) {\n    updateOrganization(input: $input) {\n      id\n    }\n  }\n':
    types.UpdateOrganizationDocument,
  '\n  query GetApplicationPage(\n    $orgId: ID!\n    $appId: ID!\n    $input: ConsumptionQueryInput!\n  ) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        name\n        app(id: $appId) {\n          id\n          name\n          measurementBudget\n          consumptionHistory(input: $input) {\n            start\n            end\n            measurements\n          }\n          objectives {\n            id\n            name\n            state\n            stateLastUpdated\n          }\n          indicators {\n            id\n          }\n          webhooks {\n            url\n          }\n        }\n      }\n    }\n  }\n':
    types.GetApplicationPageDocument,
  '\n  query GetCreatePaymentPage {\n    me {\n      id\n      email\n      name\n    }\n  }\n':
    types.GetCreatePaymentPageDocument,
  '\n  query GetPaymentsPage(\n    $orgId: ID!\n    $appId: ID!\n    $input: ConsumptionQueryInput!\n  ) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        app(id: $appId) {\n          id\n          name\n          measurementBudget\n          consumption(input: $input) {\n            start\n            end\n            measurements\n          }\n        }\n      }\n    }\n  }\n':
    types.GetPaymentsPageDocument,
  '\n  mutation UpdateApp($input: UpdateAppInput!) {\n    updateApp(input: $input) {\n      id\n    }\n  }\n':
    types.UpdateAppDocument,
  '\n  mutation CreateInvite($input: CreateInviteInput!) {\n    createInvite(input: $input) {\n      email\n    }\n  }\n':
    types.CreateInviteDocument,
  '\n  query GetOrganizationInvitesPage($orgId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        invitesConnection {\n          edges {\n            node {\n              email\n              createdAt\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.GetOrganizationInvitesPageDocument,
  '\n  query GetOrganizationUsersPage($orgId: ID!) {\n    me {\n      id\n      name\n      email\n      organization(id: $orgId) {\n        id\n        usersConnection {\n          edges {\n            role\n            node {\n              id\n              name\n              email\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.GetOrganizationUsersPageDocument,
  '\n  mutation UpdateUserRole($input: UpdateUserRoleInput!) {\n    updateUserRole(input: $input) {\n      __typename\n    }\n  }\n':
    types.UpdateUserRoleDocument,
  '\n  mutation RemoveUserFromOrganization(\n    $input: RemoveUserFromOrganizationInput!\n  ) {\n    removeUserFromOrganization(input: $input) {\n      __typename\n    }\n  }\n':
    types.RemoveUserFromOrganizationDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetApplicationOutlet($orgId: ID!, $appId: ID!) {\n    me {\n      id\n      name\n      email\n      organization(id: $orgId) {\n        id\n        name\n        logo\n        app(id: $appId) {\n          id\n          name\n          apiKey\n          measurementBudget\n          webhooks {\n            url\n          }\n          sdk\n        }\n        apps {\n          id\n          name\n        }\n        userConnection {\n          edges {\n            role\n          }\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GetApplicationOutlet($orgId: ID!, $appId: ID!) {\n    me {\n      id\n      name\n      email\n      organization(id: $orgId) {\n        id\n        name\n        logo\n        app(id: $appId) {\n          id\n          name\n          apiKey\n          measurementBudget\n          webhooks {\n            url\n          }\n          sdk\n        }\n        apps {\n          id\n          name\n        }\n        userConnection {\n          edges {\n            role\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetCreateApplicationPage {\n    me {\n      id\n      organizations {\n        id\n        name\n      }\n    }\n  }\n'
): typeof documents['\n  query GetCreateApplicationPage {\n    me {\n      id\n      organizations {\n        id\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateApp($input: CreateAppInput!) {\n    createApp(input: $input) {\n      id\n    }\n  }\n'
): typeof documents['\n  mutation CreateApp($input: CreateAppInput!) {\n    createApp(input: $input) {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetDeleteApplicationPage($orgId: ID!, $appId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        app(id: $appId) {\n          id\n          name\n          objectives {\n            id\n          }\n          indicators {\n            id\n          }\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GetDeleteApplicationPage($orgId: ID!, $appId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        app(id: $appId) {\n          id\n          name\n          objectives {\n            id\n          }\n          indicators {\n            id\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteApp($input: DeleteAppInput!) {\n    deleteApp(input: $input)\n  }\n'
): typeof documents['\n  mutation DeleteApp($input: DeleteAppInput!) {\n    deleteApp(input: $input)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateAppSdk($input: UpdateAppInput!) {\n    updateApp(input: $input) {\n      id\n      sdk\n    }\n  }\n'
): typeof documents['\n  mutation UpdateAppSdk($input: UpdateAppInput!) {\n    updateApp(input: $input) {\n      id\n      sdk\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateIndicator($input: CreateIndicatorInput!) {\n    createIndicator(input: $input) {\n      id\n    }\n  }\n'
): typeof documents['\n  mutation CreateIndicator($input: CreateIndicatorInput!) {\n    createIndicator(input: $input) {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetDeleteIndicatorPage($orgId: ID!, $appId: ID!, $indicatorId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        app(id: $appId) {\n          id\n          indicator(id: $indicatorId) {\n            id\n            name\n            objectives {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GetDeleteIndicatorPage($orgId: ID!, $appId: ID!, $indicatorId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        app(id: $appId) {\n          id\n          indicator(id: $indicatorId) {\n            id\n            name\n            objectives {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteIndicator($input: DeleteIndicatorInput!) {\n    deleteIndicator(input: $input)\n  }\n'
): typeof documents['\n  mutation DeleteIndicator($input: DeleteIndicatorInput!) {\n    deleteIndicator(input: $input)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetHistoryForIndicator(\n    $orgId: ID!\n    $appId: ID!\n    $indicatorId: ID!\n    $query: IndicatorHistoryQueryInput!\n  ) {\n    me {\n      organization(id: $orgId) {\n        app(id: $appId) {\n          indicator(id: $indicatorId) {\n            history(input: $query) {\n              state\n              start\n              end\n              result {\n                __typename\n                ... on AverageResult {\n                  values {\n                    percentile\n                    value\n                  }\n                }\n                ... on RateResult {\n                  value\n                }\n                ... on CountResult {\n                  value\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GetHistoryForIndicator(\n    $orgId: ID!\n    $appId: ID!\n    $indicatorId: ID!\n    $query: IndicatorHistoryQueryInput!\n  ) {\n    me {\n      organization(id: $orgId) {\n        app(id: $appId) {\n          indicator(id: $indicatorId) {\n            history(input: $query) {\n              state\n              start\n              end\n              result {\n                __typename\n                ... on AverageResult {\n                  values {\n                    percentile\n                    value\n                  }\n                }\n                ... on RateResult {\n                  value\n                }\n                ... on CountResult {\n                  value\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetIndicatorHistory(\n    $orgId: ID!\n    $appId: ID!\n    $indicatorId: ID!\n    $query: IndicatorHistoryQueryInput!\n  ) {\n    me {\n      organization(id: $orgId) {\n        app(id: $appId) {\n          indicator(id: $indicatorId) {\n            operation\n            history(input: $query) {\n              state\n              start\n              end\n              result {\n                __typename\n                ... on AverageResult {\n                  values {\n                    percentile\n                    value\n                  }\n                }\n                ... on RateResult {\n                  value\n                }\n                ... on CountResult {\n                  value\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GetIndicatorHistory(\n    $orgId: ID!\n    $appId: ID!\n    $indicatorId: ID!\n    $query: IndicatorHistoryQueryInput!\n  ) {\n    me {\n      organization(id: $orgId) {\n        app(id: $appId) {\n          indicator(id: $indicatorId) {\n            operation\n            history(input: $query) {\n              state\n              start\n              end\n              result {\n                __typename\n                ... on AverageResult {\n                  values {\n                    percentile\n                    value\n                  }\n                }\n                ... on RateResult {\n                  value\n                }\n                ... on CountResult {\n                  value\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetIndicatorOutlet($orgId: ID!, $appId: ID!, $indicatorId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        name\n        app(id: $appId) {\n          id\n          name\n          indicator(id: $indicatorId) {\n            id\n            name\n            operation\n            predicateFunction\n            window\n            description\n            objectives {\n              id\n              name\n              state\n              stateLastUpdated\n            }\n            logMessagesConnection {\n              edges {\n                node {\n                  id\n                  message\n                  timestamp\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GetIndicatorOutlet($orgId: ID!, $appId: ID!, $indicatorId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        name\n        app(id: $appId) {\n          id\n          name\n          indicator(id: $indicatorId) {\n            id\n            name\n            operation\n            predicateFunction\n            window\n            description\n            objectives {\n              id\n              name\n              state\n              stateLastUpdated\n            }\n            logMessagesConnection {\n              edges {\n                node {\n                  id\n                  message\n                  timestamp\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetIndicatorsWithHistory($orgId: ID!, $appId: ID!) {\n    me {\n      organization(id: $orgId) {\n        app(id: $appId) {\n          indicators {\n            id\n            name\n            operation\n            logMessagesConnection(first: 100) {\n              edges {\n                node {\n                  message\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GetIndicatorsWithHistory($orgId: ID!, $appId: ID!) {\n    me {\n      organization(id: $orgId) {\n        app(id: $appId) {\n          indicators {\n            id\n            name\n            operation\n            logMessagesConnection(first: 100) {\n              edges {\n                node {\n                  message\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetMeasurementOutlet(\n    $orgId: ID!\n    $appId: ID!\n    $indicatorId: ID!\n    $measurementId: ID!\n  ) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        name\n        app(id: $appId) {\n          id\n          name\n          indicator(id: $indicatorId) {\n            id\n            name\n            measurement(id: $measurementId) {\n              id\n              name\n              result\n              timestamp\n              metadata {\n                connectionQuality\n                deviceType\n                userMetadata\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GetMeasurementOutlet(\n    $orgId: ID!\n    $appId: ID!\n    $indicatorId: ID!\n    $measurementId: ID!\n  ) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        name\n        app(id: $appId) {\n          id\n          name\n          indicator(id: $indicatorId) {\n            id\n            name\n            measurement(id: $measurementId) {\n              id\n              name\n              result\n              timestamp\n              metadata {\n                connectionQuality\n                deviceType\n                userMetadata\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetMeasurementPage(\n    $orgId: ID!\n    $appId: ID!\n    $indicatorId: ID!\n    $measurementId: ID!\n  ) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        name\n        app(id: $appId) {\n          id\n          name\n          indicator(id: $indicatorId) {\n            id\n            name\n            measurement(id: $measurementId) {\n              id\n              duration\n              metadata {\n                connectionQuality\n                deviceType\n                userMetadata\n              }\n              name\n              result\n              timestamp\n              url\n            }\n          }\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GetMeasurementPage(\n    $orgId: ID!\n    $appId: ID!\n    $indicatorId: ID!\n    $measurementId: ID!\n  ) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        name\n        app(id: $appId) {\n          id\n          name\n          indicator(id: $indicatorId) {\n            id\n            name\n            measurement(id: $measurementId) {\n              id\n              duration\n              metadata {\n                connectionQuality\n                deviceType\n                userMetadata\n              }\n              name\n              result\n              timestamp\n              url\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetIndicatorMeasurements(\n    $orgId: ID!\n    $appId: ID!\n    $indicatorId: ID!\n    $size: Int!\n    $after: String\n  ) {\n    me {\n      organization(id: $orgId) {\n        app(id: $appId) {\n          indicator(id: $indicatorId) {\n            measurementsConnection(first: $size, after: $after) {\n              pageInfo {\n                endCursor\n                hasNextPage\n              }\n              edges {\n                node {\n                  id\n                  duration\n                  metadata {\n                    connectionQuality\n                    deviceType\n                    userMetadata\n                  }\n                  name\n                  result\n                  timestamp\n                  url\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GetIndicatorMeasurements(\n    $orgId: ID!\n    $appId: ID!\n    $indicatorId: ID!\n    $size: Int!\n    $after: String\n  ) {\n    me {\n      organization(id: $orgId) {\n        app(id: $appId) {\n          indicator(id: $indicatorId) {\n            measurementsConnection(first: $size, after: $after) {\n              pageInfo {\n                endCursor\n                hasNextPage\n              }\n              edges {\n                node {\n                  id\n                  duration\n                  metadata {\n                    connectionQuality\n                    deviceType\n                    userMetadata\n                  }\n                  name\n                  result\n                  timestamp\n                  url\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation SuggestIndicators($input: SuggestIndicatorsInput!) {\n    suggestIndicators(input: $input) {\n      appId\n      name\n      operation\n      predicateFunction\n      window\n    }\n  }\n'
): typeof documents['\n  mutation SuggestIndicators($input: SuggestIndicatorsInput!) {\n    suggestIndicators(input: $input) {\n      appId\n      name\n      operation\n      predicateFunction\n      window\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query PreviewIndicator(\n    $orgId: ID!\n    $appId: ID!\n    $input: PreviewIndicatorInput!\n  ) {\n    me {\n      organization(id: $orgId) {\n        app(id: $appId) {\n          previewIndicator(input: $input) {\n            history {\n              start\n              end\n              state\n              result {\n                __typename\n                ... on RateResult {\n                  value\n                }\n                ... on AverageResult {\n                  values {\n                    percentile\n                    value\n                  }\n                }\n                ... on CountResult {\n                  value\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query PreviewIndicator(\n    $orgId: ID!\n    $appId: ID!\n    $input: PreviewIndicatorInput!\n  ) {\n    me {\n      organization(id: $orgId) {\n        app(id: $appId) {\n          previewIndicator(input: $input) {\n            history {\n              start\n              end\n              state\n              result {\n                __typename\n                ... on RateResult {\n                  value\n                }\n                ... on AverageResult {\n                  values {\n                    percentile\n                    value\n                  }\n                }\n                ... on CountResult {\n                  value\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation SaveSuggestedIndicator($input: CreateIndicatorInput!) {\n    createIndicator(input: $input) {\n      id\n    }\n  }\n'
): typeof documents['\n  mutation SaveSuggestedIndicator($input: CreateIndicatorInput!) {\n    createIndicator(input: $input) {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateIndicator($input: UpdateIndicatorInput!) {\n    updateIndicator(input: $input) {\n      id\n    }\n  }\n'
): typeof documents['\n  mutation UpdateIndicator($input: UpdateIndicatorInput!) {\n    updateIndicator(input: $input) {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateObjective($input: CreateObjectiveInput!) {\n    createObjective(input: $input) {\n      id\n    }\n  }\n'
): typeof documents['\n  mutation CreateObjective($input: CreateObjectiveInput!) {\n    createObjective(input: $input) {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetDeleteObjectivePage($orgId: ID!, $appId: ID!, $objectiveId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        app(id: $appId) {\n          id\n          objective(id: $objectiveId) {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GetDeleteObjectivePage($orgId: ID!, $appId: ID!, $objectiveId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        app(id: $appId) {\n          id\n          objective(id: $objectiveId) {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteObjective($input: DeleteObjectiveInput!) {\n    deleteObjective(input: $input)\n  }\n'
): typeof documents['\n  mutation DeleteObjective($input: DeleteObjectiveInput!) {\n    deleteObjective(input: $input)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetIndicators($orgId: ID!, $appId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        app(id: $appId) {\n          id\n          indicators {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GetIndicators($orgId: ID!, $appId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        app(id: $appId) {\n          id\n          indicators {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetObjectiveHistory(\n    $orgId: ID!\n    $appId: ID!\n    $objectiveId: ID!\n    $objectiveQuery: ObjectiveHistoryQueryInput!\n    $indicatorQuery: IndicatorHistoryQueryInput!\n  ) {\n    me {\n      organization(id: $orgId) {\n        app(id: $appId) {\n          objective(id: $objectiveId) {\n            id\n            history(input: $objectiveQuery) {\n              state\n              start\n              end\n            }\n            indicator {\n              history(input: $indicatorQuery) {\n                state\n                start\n                end\n                result {\n                  __typename\n                  ... on AverageResult {\n                    values {\n                      percentile\n                      value\n                    }\n                  }\n                  ... on RateResult {\n                    value\n                  }\n                }\n              }\n            }\n            percentile\n            threshold {\n              ... on LowerThreshold {\n                __typename\n                lte\n              }\n              ... on BoundedThreshold {\n                __typename\n                lte\n                gte\n              }\n              ... on UpperThreshold {\n                __typename\n                gte\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GetObjectiveHistory(\n    $orgId: ID!\n    $appId: ID!\n    $objectiveId: ID!\n    $objectiveQuery: ObjectiveHistoryQueryInput!\n    $indicatorQuery: IndicatorHistoryQueryInput!\n  ) {\n    me {\n      organization(id: $orgId) {\n        app(id: $appId) {\n          objective(id: $objectiveId) {\n            id\n            history(input: $objectiveQuery) {\n              state\n              start\n              end\n            }\n            indicator {\n              history(input: $indicatorQuery) {\n                state\n                start\n                end\n                result {\n                  __typename\n                  ... on AverageResult {\n                    values {\n                      percentile\n                      value\n                    }\n                  }\n                  ... on RateResult {\n                    value\n                  }\n                }\n              }\n            }\n            percentile\n            threshold {\n              ... on LowerThreshold {\n                __typename\n                lte\n              }\n              ... on BoundedThreshold {\n                __typename\n                lte\n                gte\n              }\n              ... on UpperThreshold {\n                __typename\n                gte\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetObjectiveOutlet($orgId: ID!, $appId: ID!, $objectiveId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        name\n        app(id: $appId) {\n          id\n          name\n          objective(id: $objectiveId) {\n            id\n            name\n            indicator {\n              id\n              name\n            }\n            threshold {\n              ... on BoundedThreshold {\n                gte\n                lte\n              }\n              ... on LowerThreshold {\n                lte\n              }\n              ... on UpperThreshold {\n                gte\n              }\n            }\n            percentile\n          }\n        }\n        apps {\n          id\n          name\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GetObjectiveOutlet($orgId: ID!, $appId: ID!, $objectiveId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        name\n        app(id: $appId) {\n          id\n          name\n          objective(id: $objectiveId) {\n            id\n            name\n            indicator {\n              id\n              name\n            }\n            threshold {\n              ... on BoundedThreshold {\n                gte\n                lte\n              }\n              ... on LowerThreshold {\n                lte\n              }\n              ... on UpperThreshold {\n                gte\n              }\n            }\n            percentile\n          }\n        }\n        apps {\n          id\n          name\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetObjectivesPage(\n    $orgId: ID!\n    $appId: ID!\n    $query: ObjectiveHistoryQueryInput!\n  ) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        name\n        logo\n        app(id: $appId) {\n          id\n          name\n          objectives {\n            id\n            name\n            percentile\n            state\n            history(input: $query) {\n              start\n              end\n              state\n            }\n            threshold {\n              ... on LowerThreshold {\n                __typename\n                lte\n              }\n              ... on BoundedThreshold {\n                __typename\n                lte\n                gte\n              }\n              ... on UpperThreshold {\n                __typename\n                gte\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GetObjectivesPage(\n    $orgId: ID!\n    $appId: ID!\n    $query: ObjectiveHistoryQueryInput!\n  ) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        name\n        logo\n        app(id: $appId) {\n          id\n          name\n          objectives {\n            id\n            name\n            percentile\n            state\n            history(input: $query) {\n              start\n              end\n              state\n            }\n            threshold {\n              ... on LowerThreshold {\n                __typename\n                lte\n              }\n              ... on BoundedThreshold {\n                __typename\n                lte\n                gte\n              }\n              ... on UpperThreshold {\n                __typename\n                gte\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateObjective($input: UpdateObjectiveInput!) {\n    updateObjective(input: $input) {\n      id\n      name\n    }\n  }\n'
): typeof documents['\n  mutation UpdateObjective($input: UpdateObjectiveInput!) {\n    updateObjective(input: $input) {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateOrganization($input: CreateOrganizationInput!) {\n    createOrganization(input: $input) {\n      id\n    }\n  }\n'
): typeof documents['\n  mutation CreateOrganization($input: CreateOrganizationInput!) {\n    createOrganization(input: $input) {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetDeleteOrganizationPage($orgId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        apps {\n          id\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GetDeleteOrganizationPage($orgId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        apps {\n          id\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteOrganization($input: DeleteOrganizationInput!) {\n    deleteOrganization(input: $input)\n  }\n'
): typeof documents['\n  mutation DeleteOrganization($input: DeleteOrganizationInput!) {\n    deleteOrganization(input: $input)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetOrganizationOutlet($id: ID!) {\n    me {\n      id\n      name\n      email\n      organization(id: $id) {\n        id\n        name\n        logo\n        apps {\n          id\n          name\n        }\n        userConnection {\n          edges {\n            role\n          }\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GetOrganizationOutlet($id: ID!) {\n    me {\n      id\n      name\n      email\n      organization(id: $id) {\n        id\n        name\n        logo\n        apps {\n          id\n          name\n        }\n        userConnection {\n          edges {\n            role\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetOrganizationsPage($objectiveQuery: ObjectiveHistoryQueryInput!) {\n    me {\n      id\n      name\n      email\n      organizations {\n        id\n        name\n        logo\n        apps {\n          id\n          name\n          objectives {\n            history(input: $objectiveQuery) {\n              start\n              end\n              state\n            }\n          }\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GetOrganizationsPage($objectiveQuery: ObjectiveHistoryQueryInput!) {\n    me {\n      id\n      name\n      email\n      organizations {\n        id\n        name\n        logo\n        apps {\n          id\n          name\n          objectives {\n            history(input: $objectiveQuery) {\n              start\n              end\n              state\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n    }\n  }\n'
): typeof documents['\n  mutation UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateOrganization($input: UpdateOrganizationInput!) {\n    updateOrganization(input: $input) {\n      id\n    }\n  }\n'
): typeof documents['\n  mutation UpdateOrganization($input: UpdateOrganizationInput!) {\n    updateOrganization(input: $input) {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetApplicationPage(\n    $orgId: ID!\n    $appId: ID!\n    $input: ConsumptionQueryInput!\n  ) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        name\n        app(id: $appId) {\n          id\n          name\n          measurementBudget\n          consumptionHistory(input: $input) {\n            start\n            end\n            measurements\n          }\n          objectives {\n            id\n            name\n            state\n            stateLastUpdated\n          }\n          indicators {\n            id\n          }\n          webhooks {\n            url\n          }\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GetApplicationPage(\n    $orgId: ID!\n    $appId: ID!\n    $input: ConsumptionQueryInput!\n  ) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        name\n        app(id: $appId) {\n          id\n          name\n          measurementBudget\n          consumptionHistory(input: $input) {\n            start\n            end\n            measurements\n          }\n          objectives {\n            id\n            name\n            state\n            stateLastUpdated\n          }\n          indicators {\n            id\n          }\n          webhooks {\n            url\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetCreatePaymentPage {\n    me {\n      id\n      email\n      name\n    }\n  }\n'
): typeof documents['\n  query GetCreatePaymentPage {\n    me {\n      id\n      email\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetPaymentsPage(\n    $orgId: ID!\n    $appId: ID!\n    $input: ConsumptionQueryInput!\n  ) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        app(id: $appId) {\n          id\n          name\n          measurementBudget\n          consumption(input: $input) {\n            start\n            end\n            measurements\n          }\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GetPaymentsPage(\n    $orgId: ID!\n    $appId: ID!\n    $input: ConsumptionQueryInput!\n  ) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        app(id: $appId) {\n          id\n          name\n          measurementBudget\n          consumption(input: $input) {\n            start\n            end\n            measurements\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateApp($input: UpdateAppInput!) {\n    updateApp(input: $input) {\n      id\n    }\n  }\n'
): typeof documents['\n  mutation UpdateApp($input: UpdateAppInput!) {\n    updateApp(input: $input) {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateInvite($input: CreateInviteInput!) {\n    createInvite(input: $input) {\n      email\n    }\n  }\n'
): typeof documents['\n  mutation CreateInvite($input: CreateInviteInput!) {\n    createInvite(input: $input) {\n      email\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetOrganizationInvitesPage($orgId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        invitesConnection {\n          edges {\n            node {\n              email\n              createdAt\n            }\n          }\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GetOrganizationInvitesPage($orgId: ID!) {\n    me {\n      id\n      organization(id: $orgId) {\n        id\n        invitesConnection {\n          edges {\n            node {\n              email\n              createdAt\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetOrganizationUsersPage($orgId: ID!) {\n    me {\n      id\n      name\n      email\n      organization(id: $orgId) {\n        id\n        usersConnection {\n          edges {\n            role\n            node {\n              id\n              name\n              email\n            }\n          }\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GetOrganizationUsersPage($orgId: ID!) {\n    me {\n      id\n      name\n      email\n      organization(id: $orgId) {\n        id\n        usersConnection {\n          edges {\n            role\n            node {\n              id\n              name\n              email\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateUserRole($input: UpdateUserRoleInput!) {\n    updateUserRole(input: $input) {\n      __typename\n    }\n  }\n'
): typeof documents['\n  mutation UpdateUserRole($input: UpdateUserRoleInput!) {\n    updateUserRole(input: $input) {\n      __typename\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RemoveUserFromOrganization(\n    $input: RemoveUserFromOrganizationInput!\n  ) {\n    removeUserFromOrganization(input: $input) {\n      __typename\n    }\n  }\n'
): typeof documents['\n  mutation RemoveUserFromOrganization(\n    $input: RemoveUserFromOrganizationInput!\n  ) {\n    removeUserFromOrganization(input: $input) {\n      __typename\n    }\n  }\n'];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
