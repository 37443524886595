import { ReactElement } from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  width: 16px;
  height: 16px;
`;

export function IconAngular(): ReactElement {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height="100"
      viewBox="0 0 96 100"
      fill="none"
    >
      <path
        d="M59.2297 0L91.8649 70.2297L95.2568 16.6622L59.2297 0Z"
        fill="white"
      />
      <path
        d="M67.6487 73.7973H28.3784L23.3649 85.9459L48.0135 100L72.6622 85.9459L67.6487 73.7973Z"
        fill="white"
      />
      <path
        d="M35.1082 58.0405H60.9325L48.0136 26.6487L35.1082 58.0405Z"
        fill="white"
      />
      <path
        d="M36.7973 0L0.770264 16.6622L4.16216 70.2297L36.7973 0Z"
        fill="white"
      />
    </Icon>
  );
}
