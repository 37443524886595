import React, { ComponentType, FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

export interface WithAuthenticationRequiredOptions {
  redirectTo: string | (() => string);
  returnTo: string | (() => string);
  onLoading: () => JSX.Element;
}

export const withAuthenticationRequired = <P extends object>(
  Component: ComponentType<P>,
  options?: Partial<WithAuthenticationRequiredOptions>
): FC<P> => {
  const { redirectTo, onLoading }: WithAuthenticationRequiredOptions = {
    ...options,
    redirectTo: () => '/login',
    returnTo: () => window.location.pathname,
    // eslint-disable-next-line react/jsx-no-useless-fragment
    onLoading: () => <></>,
  };

  return function WithAuthenticationRequired(props: P) {
    const { isLoading, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
      if (isLoading || !!user) {
        return;
      }
      const redirectToPath =
        typeof redirectTo === 'function' ? redirectTo() : redirectTo;
      navigate(redirectToPath, { replace: true });
    }, [isLoading, navigate, user]);

    if (isLoading) {
      return onLoading();
    }

    if (!isLoading && !!user) {
      return <Component {...props} />;
    }
  };
};
