import {
  ChangeEvent,
  createContext,
  FocusEvent,
  ReactElement,
  ReactNode,
} from 'react';
import styled from 'styled-components';
import { glassSurface } from './GlassSurface';

interface RadioGroupProps {
  children: ReactNode;
  errors?: string;
  name: string;
  onBlur: (event: FocusEvent<HTMLInputElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  touched?: boolean;
  value: string;
}

const Container = styled.div`
  ${glassSurface};

  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RadioGroupContext = createContext<{
  errors?: string;
  name: string;
  onBlur: (event: FocusEvent<HTMLInputElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  touched?: boolean;
  value: string;
}>({
  name: '',
  onBlur: () => {
    throw new Error('RadioGroupContext onBlur not implemented');
  },
  onChange: () => {
    throw new Error('RadioGroupContext onChange not implemented');
  },
  value: '',
});

export function RadioGroup(props: RadioGroupProps): ReactElement {
  return (
    <Container>
      <RadioGroupContext.Provider
        value={{
          errors: props.errors,
          name: props.name,
          onBlur: props.onBlur,
          onChange: props.onChange,
          touched: props.touched,
          value: props.value,
        }}
      >
        {props.children}
      </RadioGroupContext.Provider>
    </Container>
  );
}
