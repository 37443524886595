import { useApplication } from '@event-horizon/app-applications';
import {
  CannedDateRange,
  Fab,
  Ghost,
  Input,
  PageContainer,
  PercentilePicker,
} from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { Aggregation, Percentile } from '@event-horizon/graphql-api-schema';
import { Add, Bolt, Search } from '@mui/icons-material';
import { InputAdornment, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, Outlet, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from 'urql';
import { IndicatorCard, IndicatorCardViewModel } from './IndicatorCard';

const GetIndicatorsWithHistory = graphql(`
  query GetIndicatorsWithHistory($orgId: ID!, $appId: ID!) {
    me {
      organization(id: $orgId) {
        app(id: $appId) {
          indicators {
            id
            name
            operation
            logMessagesConnection(first: 100) {
              edges {
                node {
                  message
                }
              }
            }
          }
        }
      }
    }
  }
`);

const FabWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;

  @media (min-width: 960px) {
    padding: 24px;
  }
`;

const GroupHeader = styled.h2`
  color: rgba(255, 255, 255, 0.87);
  font-family: Singolare, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  padding: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
`;

const FilterInput = styled(Input)`
  width: 100%;
  max-width: 300px;
`;

const ControlActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const FetchingFilteredIndicators = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding: 24px;
`;

const FilteredIndicators = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding: 24px;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CardGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @media (min-width: 960px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
`;

const NOW = DateTime.now();

export function IndicatorsPage() {
  const { application, organization } = useApplication();
  const [percentile, setPercentile] = useState(Percentile.NINETIETH);
  const [aggregation, setAggregation] = useState<Aggregation>(
    Aggregation.BY_HOUR
  );
  const [start, setStart] = useState<DateTime>(
    NOW.startOf('hour').minus({ days: 1 })
  );
  const [end, setEnd] = useState<DateTime>(NOW.startOf('hour'));
  const [query, setQuery] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [{ data: result, fetching }] = useQuery({
    query: GetIndicatorsWithHistory,
    variables: {
      appId: application.id,
      orgId: organization.id,
    },
  });

  const indicatorsByGroup = useMemo(() => {
    return (
      result?.me.organization?.app?.indicators.reduce(
        (
          acc: [name: string, indicators: IndicatorCardViewModel[]][],
          indicator
        ) => {
          const [groupName, indicatorName] = indicator.name.split(' / ');
          const viewModel: IndicatorCardViewModel = {
            id: indicator.id,
            name: indicatorName,
            operation: indicator.operation,
            consoleMessages: {
              warn: 0,
              error: 0,
              info: 0,
            },
          };

          const group = acc.find(([name]) => name === groupName);
          if (group) {
            group[1].push(viewModel);
          } else {
            acc.push([groupName, [viewModel]]);
          }

          return acc;
        },
        []
      ) ?? []
    );
  }, [result]);

  const filteredIndicators = useMemo(() => {
    if (query.length === 0) return indicatorsByGroup;
    const regEx = new RegExp(`${query}`, 'i');
    return indicatorsByGroup.map(([group, indicators]) => {
      return [
        group,
        indicators.filter((indicator) => {
          return regEx.test(indicator.name);
        }),
      ] as [string, IndicatorCardViewModel[]];
    });
  }, [indicatorsByGroup, query]);

  const handleOnDateRangeChange = useCallback(
    (start: DateTime, end: DateTime, aggregation: Aggregation) => {
      searchParams.set('aggregation', aggregation);
      searchParams.set('start', start.toISODate()!);
      searchParams.set('end', end.toISODate()!);
      setSearchParams(searchParams);
    },
    []
  );

  const handleOnQueryChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      searchParams.set('query', event.target.value);
      setSearchParams(searchParams);
    },
    []
  );

  useEffect(() => {
    if (searchParams.has('aggregation')) {
      setAggregation((searchParams.get('aggregation') as Aggregation) ?? '');
    }
    if (searchParams.has('query')) {
      setQuery(searchParams.get('query') ?? '');
    }
    if (searchParams.has('start')) {
      setStart(DateTime.fromISO(searchParams.get('start') ?? ''));
    }
    if (searchParams.has('end')) {
      setEnd(DateTime.fromISO(searchParams.get('end') ?? ''));
    }
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.has('aggregation')) {
      searchParams.set('aggregation', aggregation);
    }
    if (!searchParams.has('start')) {
      searchParams.set('start', start.toISODate()!);
    }
    if (!searchParams.has('end')) {
      searchParams.set('end', end.toISODate()!);
    }
    setSearchParams(searchParams);
  }, []);

  return (
    <PageContainer>
      <Controls>
        <FilterInput
          label="Filter"
          variant="outlined"
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          value={query}
          onChange={handleOnQueryChange}
        />
        <ControlActions>
          <PercentilePicker
            percentile={percentile}
            onChange={(percentile) => setPercentile(percentile)}
          />
          <CannedDateRange
            start={start}
            end={end}
            now={NOW}
            onChange={handleOnDateRangeChange}
          />
        </ControlActions>
      </Controls>
      {fetching && (
        <FetchingFilteredIndicators>
          <Group>
            <Ghost height="24px" width="24%" />
            <CardGroup>
              <Ghost />
              <Ghost />
              <Ghost />
            </CardGroup>
          </Group>
          <Group>
            <Ghost height="24px" width="36%" />
            <CardGroup>
              <Ghost />
              <Ghost />
            </CardGroup>
          </Group>
        </FetchingFilteredIndicators>
      )}
      {!fetching && (
        <FilteredIndicators>
          {filteredIndicators
            .filter(([, indicators]) => indicators.length > 0)
            .map(([groupName, indicators]) => (
              <Group key={groupName}>
                <GroupHeader>{groupName}</GroupHeader>
                <CardGroup>
                  {indicators.map((indicator) => (
                    <IndicatorCard
                      indicator={indicator}
                      start={start}
                      end={end}
                      aggregation={aggregation}
                      percentile={percentile}
                      key={indicator.name}
                    />
                  ))}
                </CardGroup>
              </Group>
            ))}
        </FilteredIndicators>
      )}
      <Outlet />
      <FabWrapper>
        <Tooltip title="Generate Indicators with AI" enterDelay={350}>
          <Fab as={NavLink} to="./suggest" variant="small">
            <Bolt />
          </Fab>
        </Tooltip>
        <Tooltip title="Create Indicator" enterDelay={350}>
          <Fab as={NavLink} to="./add">
            <Add />
          </Fab>
        </Tooltip>
      </FabWrapper>
    </PageContainer>
  );
}
