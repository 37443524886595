import { glassSurface, Sdks } from '@event-horizon/app-components';
import { Sdk } from '@event-horizon/graphql-api-schema';
import { ArrowDropDown } from '@mui/icons-material';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import { MouseEvent, ReactNode, useCallback, useState } from 'react';
import styled from 'styled-components';

const SdkButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;

  > svg {
    height: 20px;
    width: 20px;
  }
`;

const GlassMenu = styled(Menu)`
  & .MuiPaper-root {
    ${glassSurface};
    min-width: 220px;
  }
`;

interface SdkMenuProps {
  onChange: (sdk: Sdk) => void;
  sdk: Sdk;
}

export function SdkMenu({ onChange, sdk }: SdkMenuProps): ReactNode {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnClick = useCallback(
    (sdk: Sdk) => {
      onChange(sdk);
      setAnchorEl(null);
    },
    [onChange]
  );

  return (
    <>
      <SdkButton
        id="sdk-menu-button"
        aria-controls={open ? 'sdk-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {Sdks[sdk].icon}
        <ArrowDropDown />
      </SdkButton>
      <GlassMenu
        id="sdk-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'sdk-menu-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {Object.keys(Sdks).map((sdk) => (
          <MenuItem key={sdk} onClick={() => handleOnClick(sdk as Sdk)}>
            <ListItemIcon>{Sdks[sdk as Sdk].icon}</ListItemIcon>
            <span>{Sdks[sdk as Sdk].title}</span>
          </MenuItem>
        ))}
      </GlassMenu>
    </>
  );
}
