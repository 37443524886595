import { Loading } from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import {
  App,
  Indicator,
  Measurement,
  Organization,
} from '@event-horizon/graphql-api-schema';
import { ReactElement } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { useQuery } from 'urql';

const GetMeasurementOutlet = graphql(`
  query GetMeasurementOutlet(
    $orgId: ID!
    $appId: ID!
    $indicatorId: ID!
    $measurementId: ID!
  ) {
    me {
      id
      organization(id: $orgId) {
        id
        name
        app(id: $appId) {
          id
          name
          indicator(id: $indicatorId) {
            id
            name
            measurement(id: $measurementId) {
              id
              name
              result
              timestamp
              metadata {
                connectionQuality
                deviceType
                userMetadata
              }
            }
          }
        }
      }
    }
  }
`);

type ContextType = {
  application: Pick<App, 'id' | 'name'>;
  indicator: Pick<Indicator, 'id' | 'name'>;
  measurement: Pick<
    Measurement,
    'id' | 'name' | 'result' | 'timestamp' | 'metadata'
  >;
  organization: Pick<Organization, 'id' | 'name'>;
};

export function MeasurementOutlet(): ReactElement {
  const { appId, indicatorId, measurementId, orgId } = useParams<{
    orgId: string;
    appId: string;
    indicatorId: string;
    measurementId: string;
  }>();

  if (!orgId) {
    throw new Error('The orgId parameter is required.');
  }

  if (!appId) {
    throw new Error('The appId parameter is required.');
  }

  if (!indicatorId) {
    throw new Error('The indicatorId parameter is required.');
  }

  if (!measurementId) {
    throw new Error('The measurementId parameter is required.');
  }

  const [{ data }] = useQuery({
    query: GetMeasurementOutlet,
    variables: {
      orgId,
      appId,
      indicatorId,
      measurementId,
    },
  });

  if (!data) {
    return <Loading />;
  }

  if (!data.me.organization) {
    throw new Error('The organization was not found.');
  }
  if (!data.me.organization.app) {
    throw new Error('The application was not found.');
  }
  if (!data.me.organization.app.indicator) {
    throw new Error('The indicator was not found.');
  }
  if (!data.me.organization.app.indicator.measurement) {
    throw new Error('The measurement was not found.');
  }

  return (
    <Outlet
      context={{
        application: data.me.organization.app,
        indicator: data.me.organization.app.indicator,
        measurement: data.me.organization.app.indicator.measurement,
        organization: data.me.organization,
      }}
    />
  );
}

export function useMeasurement() {
  return useOutletContext<ContextType>();
}
