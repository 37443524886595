import Prism from 'prismjs';
import { ChangeEvent, ReactElement, useMemo } from 'react';
import styled from 'styled-components';
import { glassSurface } from './GlassSurface';

export interface CodeEditorProps {
  value: string;
  name?: string;
  onChange: (event: ChangeEvent) => void;
  title?: ReactElement;
}

const Container = styled.div`
  ${glassSurface};
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  background: rgba(0, 0, 0, 0.48);
  padding: 16px;
  font-size: 12px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.72);
  margin: 1px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const CodeEditorWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 160px;
  overflow: hidden;
  font-family: 'Operator Mono', monospace;
  padding: 16px;
  font-weight: 500;
  font-size: 12px;
`;

const CodePre = styled.pre`
  position: relative;
  padding: 0;
  margin: 0;
  overflow: hidden;
  border: none;
  resize: none;
  line-height: 1.5;
  tab-size: 2;
  overflow: auto;
  resize: none;
  white-space: pre-wrap;
  word-break: break-all;
  width: 100%;
  height: 100%;
`;

const TransparentTextarea = styled.textarea`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: transparent;
  line-height: 1.5;
  tab-size: 2;
  background: transparent;
  caret-color: white;
  border: none;
  font-family: 'Operator Mono', monospace;
  font-weight: 500;
  font-size: 12px;
  padding: 16px;
  overflow: auto;
  resize: none;
  word-break: break-all;
`;

export function CodeEditor({ name, value, onChange, title }: CodeEditorProps) {
  const highlighted = useMemo(() => {
    return Prism.highlight(value, Prism.languages.javascript, 'javascript');
  }, [value]);

  return (
    <Container>
      {title && <Header>{title}</Header>}
      <CodeEditorWrapper>
        <CodePre>
          <code dangerouslySetInnerHTML={{ __html: highlighted }} />
        </CodePre>
        <TransparentTextarea
          name={name}
          onChange={onChange}
          value={value}
          spellCheck={false}
          rows={5}
        />
      </CodeEditorWrapper>
    </Container>
  );
}
