import { ConnectionQuality } from '@event-horizon/graphql-api-schema';

/**
 * Augment the navigator type definition with the effectiveType property
 */
declare global {
  interface Navigator {
    connection?: {
      effectiveType: string;
    };
  }
}

/**
 * Returns the current connection quality as FAST,
 * OK, SLOW, or SLOWEST depending on navigator.connection.effectiveType.
 *
 * If the browser does not support navigator.connection.effectType, assume
 * return null
 */
export function getConnectionQuality(): ConnectionQuality | null {
  if (!!globalThis.window && globalThis.window.navigator?.connection) {
    switch (window.navigator.connection?.effectiveType) {
      case 'slow-2g':
        return ConnectionQuality.SLOWEST;
      case '2g':
        return ConnectionQuality.SLOW;
      case '3g':
        return ConnectionQuality.OK;
      case '4g':
      default:
        return ConnectionQuality.FAST;
    }
  }
  return null;
}
