import { InfoOutlined } from '@mui/icons-material';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

interface InfoProps {
  children: ReactNode;
  title: string;
}

const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 16px;
  margin: 16px 0;
  padding: 16px;
  border: 1px solid var(--color-slate-500);
  border-radius: 8px;
  background-color: var(--color-slate-800);
`;

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InfoTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: var(--color-slate-50);
`;

const InfoChildren = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 15px;
  font-weight: 300;
  color: var(--color-slate-50);
`;

export function Info(props: InfoProps): ReactElement {
  return (
    <InfoContainer>
      <InfoOutlined />
      <InfoContent>
        <InfoTitle>{props.title}</InfoTitle>
        <InfoChildren>{props.children}</InfoChildren>
      </InfoContent>
    </InfoContainer>
  );
}
