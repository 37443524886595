import {
  BackLink,
  Button,
  glassSurface,
  PageContainer,
} from '@event-horizon/app-components';
import { Settings } from '@mui/icons-material';
import { DateTime } from 'luxon';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { ObjectiveHistory } from './ObjectiveHistory';
import { useObjective } from './ObjectiveOutlet';
import { UpdateObjectiveDrawer } from './UpdateObjectiveDrawer';

const ObjectiveContainer = styled.div`
  padding: 24px;
  flex: 1 auto;
`;

const ObjectiveCard = styled.div`
  ${glassSurface};
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1 auto;
  position: relative;
`;

const Header = styled.header`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > h3 {
    color: #b557ff;
    font-size: 11px;
    font-weight: 900;
    text-transform: uppercase;
    margin: 0;
  }

  > h2 {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: -6px;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 16px;
`;

const NOW = DateTime.now();

export function ObjectivePage(): ReactElement {
  const { application, applications, organization, objective } = useObjective();
  const [editorOpen, setEditorOpen] = useState(false);
  const [start, setStart] = useState<DateTime>(
    NOW.minus({ hours: 24 }).set({ minute: 0, second: 0, millisecond: 0 })
  );
  const [end, setEnd] = useState<DateTime>(
    NOW.set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnChange = useCallback((start: DateTime, end: DateTime) => {
    searchParams.set('start', start.toISODate()!);
    searchParams.set('end', end.toISODate()!);
    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    if (searchParams.has('start')) {
      setStart(DateTime.fromISO(searchParams.get('start')!));
    }
    if (searchParams.has('end')) {
      setEnd(
        DateTime.fromISO(searchParams.get('end')!).set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 0,
        })
      );
    }
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.has('start')) {
      searchParams.set('start', start.toISODate()!);
    }
    if (!searchParams.has('end')) {
      searchParams.set('end', end.toISODate()!);
    }
    setSearchParams(searchParams);
  }, []);

  return (
    <PageContainer
      header={<BackLink to="../../objectives">Back to Objectives</BackLink>}
    >
      <ObjectiveContainer>
        <ObjectiveCard>
          <Header>
            <Heading>
              <h3>{objective.indicator.name}</h3>
              <h2>{objective.name}</h2>
            </Heading>
            <Nav>
              <Button
                onClick={() => setEditorOpen(true)}
                color="secondary"
                raised
                condensed
              >
                <Settings />
              </Button>
            </Nav>
          </Header>
          <ObjectiveHistory
            appId={application.id}
            orgId={organization.id}
            objectiveId={objective.id}
            start={start}
            end={end}
            onChange={handleOnChange}
          />
          <Outlet
            context={{
              application,
              applications,
              objective,
              organization,
            }}
          />
        </ObjectiveCard>
      </ObjectiveContainer>
      <UpdateObjectiveDrawer
        open={editorOpen}
        onClose={() => setEditorOpen(false)}
      />
    </PageContainer>
  );
}
