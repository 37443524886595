import { ComponentType, forwardRef, ReactNode } from 'react';
import styled from 'styled-components';

interface ListLinkProps {
  children: ReactNode;
  component?: ComponentType<any>;
  [key: string]: any;
}

const Container = styled.a`
  font-size: 18px;
  font-weight: 500;
  color: var(--color-slate-50);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const ListLink = forwardRef(function (
  { children, component, ...rest }: ListLinkProps,
  ref
) {
  return (
    <Container as={component} {...rest}>
      {children}
    </Container>
  );
});
