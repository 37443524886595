import { AlertDialog, Drawer } from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import {
  RemoveUserFromOrganizationInput,
  UpdateUserInput,
} from '@event-horizon/graphql-api-schema';
import { Button } from '@mui/material';
import { FormikHelpers } from 'formik';
import { ReactElement, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from 'urql';
import { UserForm, UserFormValues } from './UserForm';

const RemoveUserFromOrganization = graphql(`
  mutation RemoveUserFromOrganization(
    $input: RemoveUserFromOrganizationInput!
  ) {
    removeUserFromOrganization(input: $input) {
      __typename
    }
  }
`);

const UpdateUser = graphql(`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`);

const DrawerContainer = styled.div`
  flex: 1 auto;
  display: flex;
  flex-direction: column;
`;

interface UpdateUserDrawerProps {
  onClose: () => void;
  open: boolean;
  organization?: {
    id: string;
  };
  user?: {
    id: string;
    name: string | null;
    email: string;
  };
}

export function UpdateUserDrawer({
  onClose,
  open,
  organization,
  user,
}: UpdateUserDrawerProps): ReactElement {
  const [cancelAlertIsOpen, setCancelAlertIsOpen] = useState(false);
  const [disassociateAlertOpen, setDisassociateAlertOpen] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [, updateUser] = useMutation(UpdateUser);
  const [, removeUserFromOrganization] = useMutation(
    RemoveUserFromOrganization
  );

  const handleOnClose = useCallback(() => {
    if (dirty) {
      setCancelAlertIsOpen(true);
      return;
    }
    onClose();
  }, [onClose, dirty, setCancelAlertIsOpen]);

  const handleOnDisassociate = useCallback(async () => {
    if (!organization || !user) {
      return;
    }
    const input: RemoveUserFromOrganizationInput = {
      organizationId: organization.id,
      userId: user.id,
    };
    await removeUserFromOrganization({ input });
    setDisassociateAlertOpen(false);
    onClose();
  }, [removeUserFromOrganization, onClose, organization, user]);

  const handleOnSubmit = useCallback(
    async (
      values: UserFormValues,
      formikHelpers: FormikHelpers<UserFormValues>
    ) => {
      const input: UpdateUserInput = {
        ...values,
        id: user!.id,
      };
      const result = await updateUser({ input });
      if (result.error) {
        formikHelpers.setSubmitting(false);
        formikHelpers.setErrors(result.error);
        return;
      }
      onClose();
    },
    [onClose, updateUser, user]
  );

  return (
    <>
      <Drawer onClose={handleOnClose} open={open} title="Update User">
        <DrawerContainer>
          <UserForm
            onClose={handleOnClose}
            onDirty={setDirty}
            onDisassociate={() => setDisassociateAlertOpen(true)}
            onSubmit={handleOnSubmit}
            user={user}
          />
        </DrawerContainer>
      </Drawer>
      <AlertDialog
        actions={
          <>
            <Button onClick={() => setDisassociateAlertOpen(false)}>No</Button>
            <Button onClick={handleOnDisassociate}>Yes</Button>
          </>
        }
        description="Are you sure you want to remove this member?"
        onClose={() => setDisassociateAlertOpen(false)}
        open={disassociateAlertOpen}
        title="Remove?"
      />
      <AlertDialog
        actions={
          <>
            <Button onClick={() => setCancelAlertIsOpen(false)}>No</Button>
            <Button
              onClick={() => {
                onClose();
                setCancelAlertIsOpen(false);
              }}
            >
              Yes
            </Button>
          </>
        }
        description="Are you sure you want to cancel and discard?"
        onClose={() => setCancelAlertIsOpen(false)}
        open={cancelAlertIsOpen}
        title="Cancel?"
      />
    </>
  );
}
