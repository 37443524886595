import {
  DateRange,
  glassSurface,
  PercentilePicker,
} from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import {
  Aggregation,
  IndicatorOperation,
  Percentile,
} from '@event-horizon/graphql-api-schema';
import { DateTime } from 'luxon';
import { ReactElement, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'urql';
import {
  IndicatorExpandedChart,
  IndicatorExpandedChartViewModel,
} from './IndicatorExpandedChart';
import { CircularProgress } from '@mui/material';
import { Timeline } from '@mui/icons-material';

interface IndicatorHistoryProps {
  orgId: string;
  appId: string;
  indicatorId: string;
  description: string | null;
  start: DateTime;
  end: DateTime;
  onChange: (start: DateTime, end: DateTime) => void;
}

const GetIndicatorHistory = graphql(`
  query GetIndicatorHistory(
    $orgId: ID!
    $appId: ID!
    $indicatorId: ID!
    $query: IndicatorHistoryQueryInput!
  ) {
    me {
      organization(id: $orgId) {
        app(id: $appId) {
          indicator(id: $indicatorId) {
            operation
            history(input: $query) {
              state
              start
              end
              result {
                __typename
                ... on AverageResult {
                  values {
                    percentile
                    value
                  }
                }
                ... on RateResult {
                  value
                }
                ... on CountResult {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`);

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const HistoryNav = styled.nav`
  ${glassSurface};
  --glass-border-radius: 0;
  --glass-border-opacity: 0%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
`;

const HistoryActions = styled.div`
  display: flex;
  gap: 16px;
  flex-shrink: 0;
`;

const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 578px;
`;

const AiDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(255, 255, 255, 0.72);
  font-family: Lexend, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  svg {
    flex-shrink: 0;
  }
`;

const CenteredBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.54);
  font-style: italic;
  flex-direction: column;
  gap: 8px;
`;

export function IndicatorHistory({
  orgId,
  appId,
  indicatorId,
  description,
  start,
  end,
  onChange,
}: IndicatorHistoryProps): ReactElement {
  const [percentile, setPercentile] = useState(Percentile.NINETY_NINTH);
  const aggregation = useMemo(
    () =>
      end.diff(start, 'hours').hours > 72
        ? Aggregation.BY_DAY
        : Aggregation.BY_HOUR,
    [end, start]
  );
  const query = useMemo(
    () => ({
      start: start.toISO()!,
      end: end.toISO()!,
      aggregation,
    }),
    [end, start, aggregation]
  );
  const [{ data: result, fetching }] = useQuery({
    query: GetIndicatorHistory,
    variables: {
      appId,
      orgId,
      indicatorId,
      query,
    },
  });
  const data = useMemo((): IndicatorExpandedChartViewModel[] => {
    return (
      result?.me?.organization?.app?.indicator?.history?.map((history) => {
        return {
          date: DateTime.fromISO(history.start),
          value:
            history.result?.__typename === 'RateResult'
              ? history.result.value
              : history.result?.__typename === 'CountResult'
              ? history.result.value
              : history.result?.values.find((v) => v.percentile === percentile)
                  ?.value || 0,
        };
      }) ?? []
    );
  }, [result, percentile]);
  const hasData = useMemo(() => {
    if (data.length < 4) return false;

    return true;
  }, [data]);
  const operation = useMemo(() => {
    return (
      result?.me?.organization?.app?.indicator?.operation ??
      IndicatorOperation.RATE
    );
  }, [result]);

  return (
    <Container>
      <HistoryNav>
        {description ? (
          <AiDescription>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M11 9.47V11H14.76L13 14.53V13H9.24L11 9.47ZM13 1L6 15H11V23L18 9H13V1Z"
                fill="url(#paint0_radial_100_610)"
              />
              <defs>
                <radialGradient
                  id="paint0_radial_100_610"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(6 1) rotate(61.3895) scale(25.0599 13.6691)"
                >
                  <stop stopColor="#10DDD3" />
                  <stop offset="0.692708" stopColor="#5F75F9" />
                  <stop offset="1" stopColor="#B557FF" />
                </radialGradient>
              </defs>
            </svg>
            <p>{description}</p>
          </AiDescription>
        ) : (
          <span />
        )}
        <HistoryActions>
          {operation === IndicatorOperation.AVERAGE && (
            <PercentilePicker
              percentile={percentile}
              onChange={(percentile) => setPercentile(percentile)}
            />
          )}
          <DateRange end={end} start={start} onChange={onChange} />
        </HistoryActions>
      </HistoryNav>
      <ChartContainer>
        {fetching ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : !hasData ? (
          <CenteredBox>
            <Timeline />
            Not enough data for the selected date range
          </CenteredBox>
        ) : (
          <IndicatorExpandedChart
            data={data}
            aggregation={aggregation}
            operation={operation}
          />
        )}
      </ChartContainer>
    </Container>
  );
}
