import { Snackbar as MuiSnackbar, SnackbarOrigin } from '@mui/material';
import { ReactElement, SyntheticEvent, useCallback } from 'react';
import styled from 'styled-components';
import { glassSurface } from './GlassSurface';

interface SnackbarProps {
  anchorOrigin?: SnackbarOrigin;
  onClose: () => void;
  open: boolean;
  message: string;
}

const GlassSnackbar = styled(MuiSnackbar)`
  & .MuiSnackbarContent-root {
    ${glassSurface};
    color: rgba(255, 255, 255, 0.87);
  }
`;

export function Snackbar({
  anchorOrigin,
  message,
  onClose,
  open,
}: SnackbarProps): ReactElement {
  const handleOnClose = useCallback(
    (event: SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      onClose();
    },
    []
  );

  anchorOrigin = {
    horizontal: 'center',
    vertical: 'bottom',
    ...anchorOrigin,
  };

  return (
    <GlassSnackbar
      anchorOrigin={anchorOrigin}
      autoHideDuration={5000}
      message={message}
      onClose={handleOnClose}
      open={open}
    />
  );
}
