import { ErrorMessage } from '@event-horizon/app-components';
import { ReactElement } from 'react';
import { useRouteError } from 'react-router-dom';

export function ErrorPage(): ReactElement {
  const error = useRouteError() as Error;
  return (
    <ErrorMessage
      title="Beep beep 👾"
      description={`Oops! We ran into an error: ${error.message}`}
    />
  );
}
