import { Percentile } from '@event-horizon/graphql-api-schema';
import { ArrowDropDown } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { MouseEvent, ReactElement, useState } from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import { GlassMenu } from './GlassMenu';

interface PercentilePickerProps {
  onChange: (percentile: Percentile) => void;
  percentile: Percentile;
}

const labelsForPercentile = (percentile: Percentile): [string, string] => {
  switch (percentile) {
    case Percentile.FIFTIETH:
      return ['50p', '50th Percentile'];
    case Percentile.NINETIETH:
      return ['90p', '90th Percentile'];
    case Percentile.NINETY_FIFTH:
      return ['95p', '95th Percentile'];
    case Percentile.NINETY_NINTH:
      return ['99p', '99th Percentile'];
    case Percentile.ONE_HUNDREDTH:
      return ['All', 'All'];
  }
};

const percentiles = new Map(
  Object.entries(Percentile).map(([key, percentile]) => [
    percentile,
    labelsForPercentile(percentile),
  ])
);

const MenuButton = styled(Button)`
  width: 80px;
`;

const PercentileLabel = styled.div`
  width: 116px;
  display: flex;
  align-items: center;
`;

export function PercentilePicker({
  onChange,
  percentile: selectedPercentile,
}: PercentilePickerProps): ReactElement {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MenuButton
        raised
        color="secondary"
        id="percentile-picker-menu-button"
        aria-controls={open ? 'percentile-picker-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <PercentileLabel>
          {labelsForPercentile(selectedPercentile)[0]}
        </PercentileLabel>
        <ArrowDropDown />
      </MenuButton>
      <GlassMenu
        id="percentile-picker-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'percentile-picker-menu-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {Array.from(percentiles.entries()).map(([percentile, label]) => (
          <MenuItem
            key={percentile}
            selected={selectedPercentile === percentile}
            onClick={() => {
              onChange(percentile);
              handleClose();
            }}
          >
            {label[1]}
          </MenuItem>
        ))}
      </GlassMenu>
    </>
  );
}
