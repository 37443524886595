import { useApplication } from '@event-horizon/app-applications';
import {
  ApplicationWebhooks,
  Button,
  ClickToCopy,
  Input,
  Option,
  PageContainer,
  RadioGroup,
  Sdks,
} from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { Sdk, UpdateAppInput } from '@event-horizon/graphql-api-schema';
import { FormikHelpers, useFormik } from 'formik';
import { ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation } from 'urql';
import * as yup from 'yup';

const UpdateApp = graphql(`
  mutation UpdateApp($input: UpdateAppInput!) {
    updateApp(input: $input) {
      id
    }
  }
`);

interface FormValues {
  name: string;
  webhooks: string[];
  sdk: Sdk;
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const Settings = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  max-width: 80ch;
`;

const Setting = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
`;

const Heading = styled.h2`
  color: rgba(255, 255, 255, 0.64);
  font-size: 11px;
`;

const Footer = styled.div`
  padding: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
`;

export function SettingsPage(): ReactElement {
  const { application, organization } = useApplication();
  const navigate = useNavigate();
  const [, updateApp] = useMutation(UpdateApp);

  const handleDelete = useCallback(async () => {
    navigate(`/org/${organization.id}/app/${application.id}/delete`);
  }, [application.id, navigate, organization.id]);

  const handleOnSubmit = useCallback(
    async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const input: UpdateAppInput = {
        ...values,
        appId: application.id,
      };
      const result = await updateApp({ input });
      if (result.error) {
        formikHelpers.setSubmitting(false);
        formikHelpers.setErrors(result.error);
        return;
      }
    },
    [application.id, updateApp]
  );

  const validationSchema = yup.object({
    name: yup.string().required('Please provide an application name'),
    webhooks: yup
      .array()
      .of(yup.string().url().required('Please provide a valid URL.')),
    sdk: yup.string().required('Please select an SDK'),
  });

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    setFieldValue,
    touched,
    values,
  } = useFormik<FormValues>({
    initialValues: {
      name: application.name,
      webhooks: application.webhooks.map((webhook) => webhook.url),
      sdk: application.sdk,
    },
    validationSchema: validationSchema,
    onSubmit: handleOnSubmit,
  });

  return (
    <PageContainer>
      <Form onSubmit={handleSubmit}>
        <Settings>
          <Setting>
            <Heading>Application API Key</Heading>
            <ClickToCopy>{application.apiKey}</ClickToCopy>
          </Setting>
          <Setting>
            <Heading>Application Name</Heading>
            <Input
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
            />
          </Setting>
          <Setting>
            <Heading>Webhooks</Heading>
            <ApplicationWebhooks
              name="webhooks"
              onAdd={() => {
                setFieldValue('webhooks', [...values.webhooks, '']);
              }}
              onBlur={handleBlur}
              onChange={handleChange}
              onRemove={(index) => {
                setFieldValue('webhooks', [
                  ...values.webhooks.slice(0, index),
                  ...values.webhooks.slice(index + 1),
                ]);
              }}
              touched={touched.webhooks}
              value={values.webhooks}
            />
          </Setting>
          <Setting>
            <Heading>SDK</Heading>
            <RadioGroup
              name="sdk"
              value={values.sdk}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              {Object.keys(Sdk).map((sdk) => (
                <Option
                  key={sdk}
                  icon={Sdks[sdk as Sdk].icon}
                  label={Sdks[sdk as Sdk].title}
                  value={sdk}
                />
              ))}
            </RadioGroup>
          </Setting>
        </Settings>
        <Footer>
          <Button type="button" color="warn" onClick={handleDelete}>
            Delete Application
          </Button>
          <Button type="submit" disabled={!isValid} raised>
            Save Application
          </Button>
        </Footer>
      </Form>
    </PageContainer>
  );
}
