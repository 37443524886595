import { glassSurface } from '@event-horizon/app-components';
import styled from 'styled-components';
import { Dangerous, Warning, Chat } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

export interface ConsoleMessagesPreviewViewModel {
  error: number;
  warn: number;
  info: number;
}

export interface ConsoleMessagesPreviewProps {
  messages: ConsoleMessagesPreviewViewModel;
}

const Wrapper = styled.div`
  ${glassSurface};
  --glass-border-radius: 2px;
  padding: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  & > div {
    display: flex;
    flex-direction: row;
    gap: 6px;
    padding: 0 4px;
  }

  svg {
    width: 12px;
    height: 12px;
  }

  span {
    color: rgba(255, 255, 255, 0.64);
    font-family: Lexend, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .error {
    color: #ff0000;
  }

  .warn {
    color: #ffe27b;
  }

  .info {
    color: #5f75f9;
  }
`;

export function ConsoleMessagesPreview(props: ConsoleMessagesPreviewProps) {
  if (!props.messages.error && !props.messages.warn && !props.messages.info) {
    return null;
  }

  return (
    <Wrapper>
      {props.messages.error > 0 && (
        <Tooltip title="Errors">
          <div className="error">
            <Dangerous />
            <span>{props.messages.error}</span>
          </div>
        </Tooltip>
      )}
      {props.messages.warn > 0 && (
        <Tooltip title="Warnings">
          <div className="warn">
            <Warning />
            <span>{props.messages.warn}</span>
          </div>
        </Tooltip>
      )}
      {props.messages.info > 0 && (
        <Tooltip title="Info">
          <div className="info">
            <Chat />
            <span>{props.messages.info}</span>
          </div>
        </Tooltip>
      )}
    </Wrapper>
  );
}
