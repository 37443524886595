import { Sdk } from '@event-horizon/graphql-api-schema';
import { ReactElement } from 'react';
import { IconAngular } from './IconAngular';
import { IconReact } from './IconReact';
import { IconTypeScript } from './IconTypeScript';

export const Sdks: Record<
  Sdk,
  { title: string; uri: string; icon: ReactElement }
> = {
  [Sdk.ANGULAR]: {
    title: 'Angular',
    uri: 'angular',
    icon: <IconAngular />,
  },
  [Sdk.REACT]: {
    title: 'React',
    uri: 'react',
    icon: <IconReact />,
  },
  [Sdk.TYPESCRIPT]: {
    title: 'TypeScript',
    uri: 'typescript',
    icon: <IconTypeScript />,
  },
};
