import { Instrument } from './measure';

export class InstrumentWrapper {
  private instrument: Instrument | null = null;

  constructor(private readonly eventName: string) {}

  start = (userMeasurementMetadata: object = {}) => {
    if (this.instrument) {
      console.warn(
        '@getpolaris.ai/sdk: Instrument already started',
        this.eventName
      );
    } else {
      this.instrument = new Instrument(this.eventName, userMeasurementMetadata);
    }
  };

  done = (resultMetadata: object = {}) => {
    if (!this.instrument) {
      console.warn(
        '@getpolaris.ai/sdk: Instrument not started',
        this.eventName
      );
    } else {
      this.instrument.done(resultMetadata);
      this.instrument = null;
    }
  };

  fail = (resultMetadata: object = {}) => {
    if (!this.instrument) {
      console.warn(
        '@getpolaris.ai/sdk: Instrument not started',
        this.eventName
      );
    } else {
      this.instrument.fail(resultMetadata);
      this.instrument = null;
    }
  };
}

const registry = new Map<string, InstrumentWrapper>();

export function getInstrument(eventName: string) {
  if (!registry.has(eventName)) {
    registry.set(eventName, new InstrumentWrapper(eventName));
  }

  const instrumentWrapper = registry.get(eventName);

  if (!instrumentWrapper) {
    throw new Error('Instrument not found');
  }

  return instrumentWrapper;
}
