import { useAuth } from '@event-horizon/app-auth';
import { Button, Input } from '@event-horizon/app-components';
import { User } from '@event-horizon/graphql-api-schema';
import { Help } from '@mui/icons-material';
import { FormikHelpers, useFormik } from 'formik';
import { ReactElement, useEffect } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

export interface UserFormValues {
  name: string;
  email: string;
}

interface UserFormProps {
  onClose: () => void;
  onDirty: (dirty: boolean) => void;
  onDisassociate?: () => void;
  onSubmit: (
    values: UserFormValues,
    formikHelpers: FormikHelpers<UserFormValues>
  ) => void | Promise<any>;
  user?: Pick<User, 'id' | 'name' | 'email'>;
}

const Info = styled.div`
  padding: 24px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 24px;
  color: rgba(255, 255, 255, 0.72);
  font: 400 13px/16px 'Lexend', sans-serif;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);

  > a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.72);
  }
`;

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
`;

const Spacer = styled.div`
  flex: 1 auto;
`;

export function UserForm(props: UserFormProps): ReactElement {
  const { user } = useAuth();

  const validationSchema = yup.object({
    email: yup.string().email().required(),
    name: yup.string().required(),
  });

  const {
    dirty,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    touched,
    values,
  } = useFormik({
    initialValues: {
      name: props.user?.name ?? '',
      email: props.user?.email ?? '',
    },
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: props.onSubmit,
  });

  useEffect(() => {
    props.onDirty(dirty);
  }, [dirty, props.onDirty]);

  return (
    <>
      <Info>
        <Help />
        <p>
          All members have full access to monitor and update all applications.
        </p>
      </Info>
      <Form onSubmit={handleSubmit}>
        <FormBody>
          <Input
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
            label="Name"
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
          />
          <Input
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            label="Email"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email}
            disabled={Boolean(props.user)}
          />
        </FormBody>
        <Spacer />
        <Actions>
          {props.user &&
            props.user.id !== user?.sub &&
            props.onDisassociate && (
              <Button type="button" onClick={props.onDisassociate} color="warn">
                Remove Member
              </Button>
            )}
          <Button type="button" onClick={props.onClose}>
            Cancel
          </Button>
          <Button type="submit" disabled={!isValid} raised>
            Save User
          </Button>
        </Actions>
      </Form>
    </>
  );
}

const Actions = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 24px;
`;
