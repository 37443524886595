import { css } from 'styled-components';

if (
  typeof window !== 'undefined' &&
  'CSS' in window &&
  window.CSS &&
  'registerProperty' in CSS
) {
  CSS.registerProperty({
    name: '--glass-border-radius',
    syntax: '<length>',
    inherits: false,
    initialValue: '0px',
  });
  CSS.registerProperty({
    name: '--glass-border-opacity',
    syntax: '<percentage>',
    inherits: false,
    initialValue: '0%',
  });
  CSS.registerProperty({
    name: '--glass-background-color-a',
    syntax: '<color>',
    inherits: false,
    initialValue: 'rgba(69, 86, 85, 0.12)',
  });
  CSS.registerProperty({
    name: '--glass-background-color-b',
    syntax: '<color>',
    inherits: false,
    initialValue: 'rgba(95, 117, 249, 0.05)',
  });
  CSS.registerProperty({
    name: '--glass-background-color-c',
    syntax: '<color>',
    inherits: false,
    initialValue: 'rgba(181, 87, 255, 0)',
  });

  if ('paintWorklet' in CSS) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    CSS.paintWorklet.addModule('/worklets/gradient-border.js');
  }
}

export const glassSurface = css`
  --glass-background-color-a: rgba(69, 86, 85, 0.12);
  --glass-background-color-b: rgba(95, 117, 249, 0.05);
  --glass-background-color-c: rgba(181, 87, 255, 0);
  --glass-border-radius: 4px;
  --glass-border-opacity: 12%;
  --glass-noise: url('/backgrounds/noise.png');
  border-radius: var(--glass-border-radius);
  background: paint(gradientBorder), var(--glass-noise),
    radial-gradient(
      circle at 0% 0%,
      var(--glass-background-color-a) 0%,
      var(--glass-background-color-b) 75%,
      var(--glass-background-color-c) 100%
    );
  backdrop-filter: blur(24px);
`;
