import { useAuth } from '@event-horizon/app-auth';
import GraphemeSplitter from 'grapheme-splitter';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import Typewriter from 'typewriter-effect';

interface GetStartedProps {
  title: string;
  description?: string;
  actions: ReactNode;
}

const stringSplitter = (value: string) => {
  const splitter = new GraphemeSplitter();
  return splitter.splitGraphemes(value) as unknown as string;
};

const GetStartedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 100%;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 32px;
  text-align: center;
`;

const GetStartedTitle = styled.div`
  font-size: 32px;
  font-weight: 500;
  line-height: 3rem;
  color: var(--color-slate-50);
`;

const GetStartedDescription = styled.div`
  font-size: 16px;
  color: var(--color-slate-500);
`;

export function GetStarted(props: GetStartedProps): ReactElement {
  const { user } = useAuth();
  const givenName = user?.given_name || 'there';

  return (
    <GetStartedContainer>
      <GetStartedTitle>
        <Typewriter
          options={{
            delay: 50,
            stringSplitter,
          }}
          onInit={(typewriter) => {
            typewriter
              .typeString(`Hi, ${givenName} 👋`)
              .pauseFor(1500)
              .typeString('<br/>')
              .typeString(props.title)
              .start();
          }}
        />
      </GetStartedTitle>
      {props.description && (
        <GetStartedDescription>{props.description}</GetStartedDescription>
      )}
      {props.actions}
    </GetStartedContainer>
  );
}
