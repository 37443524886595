import { Percentile } from '@event-horizon/graphql-api-schema';
import { ChangeEvent, FocusEvent, ReactElement } from 'react';
import styled from 'styled-components';
import { Option } from './Option';
import { RadioGroup } from './RadioGroup';

interface ObjectivePercentileProps {
  errors?: string;
  name: string;
  onBlur: (event: FocusEvent<HTMLInputElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  touched?: boolean;
  value: string;
}

const ObjectivePercentileContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export function ObjectivePercentile(
  props: ObjectivePercentileProps
): ReactElement {
  return (
    <ObjectivePercentileContainer>
      <RadioGroup
        name={props.name}
        value={props.value}
        onBlur={props.onBlur}
        onChange={props.onChange}
      >
        <Option label="50th Percentile" value={Percentile.FIFTIETH} />
        <Option label="90th Percentile" value={Percentile.NINETIETH} />
        <Option label="95th Percentile" value={Percentile.NINETY_FIFTH} />
        <Option label="99th Percentile" value={Percentile.NINETY_NINTH} />
        <Option label="100th Percentile" value={Percentile.ONE_HUNDREDTH} />
      </RadioGroup>
    </ObjectivePercentileContainer>
  );
}
