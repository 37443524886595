import {
  borderColor,
  objectiveAnnotations,
  options as defaultOptions,
} from '@event-horizon/app-chart';
import { DateRange, glassSurface } from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { Aggregation } from '@event-horizon/graphql-api-schema';
import { ChartData, ChartOptions } from 'chart.js';
import { DateTime } from 'luxon';
import { ReactElement, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';
import { useQuery } from 'urql';

interface ObjectiveHistoryProps {
  orgId: string;
  appId: string;
  objectiveId: string;
  start: DateTime;
  end: DateTime;
  onChange: (start: DateTime, end: DateTime) => void;
}

const GetObjectiveHistory = graphql(`
  query GetObjectiveHistory(
    $orgId: ID!
    $appId: ID!
    $objectiveId: ID!
    $objectiveQuery: ObjectiveHistoryQueryInput!
    $indicatorQuery: IndicatorHistoryQueryInput!
  ) {
    me {
      organization(id: $orgId) {
        app(id: $appId) {
          objective(id: $objectiveId) {
            id
            history(input: $objectiveQuery) {
              state
              start
              end
            }
            indicator {
              history(input: $indicatorQuery) {
                state
                start
                end
                result {
                  __typename
                  ... on AverageResult {
                    values {
                      percentile
                      value
                    }
                  }
                  ... on RateResult {
                    value
                  }
                }
              }
            }
            percentile
            threshold {
              ... on LowerThreshold {
                __typename
                lte
              }
              ... on BoundedThreshold {
                __typename
                lte
                gte
              }
              ... on UpperThreshold {
                __typename
                gte
              }
            }
          }
        }
      }
    }
  }
`);

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const HistoryNav = styled.nav`
  ${glassSurface};
  --glass-border-radius: 0;
  --glass-border-opacity: 0%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  padding: 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
`;

const HistoryActions = styled.div`
  display: flex;
  gap: 16px;
  flex-shrink: 0;
`;

const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export function ObjectiveHistory({
  orgId,
  appId,
  objectiveId,
  start,
  end,
  onChange,
}: ObjectiveHistoryProps): ReactElement {
  const query = useMemo(
    () => ({
      start: start.toISO()!,
      end: end.toISO()!,
      aggregation:
        end.diff(start, 'days').toObject().days! > 3
          ? Aggregation.BY_DAY
          : Aggregation.BY_HOUR,
    }),
    [end, start]
  );

  const [{ data: result }] = useQuery({
    query: GetObjectiveHistory,
    variables: {
      appId,
      orgId,
      objectiveId,
      objectiveQuery: query,
      indicatorQuery: query,
    },
  });

  const NO_DATA: ChartData<'line'> = useMemo(
    () => ({
      labels: [],
      datasets: [],
    }),
    []
  );
  const data = useMemo((): ChartData<'line'> => {
    if (
      !result ||
      !result.me.organization ||
      !result.me.organization.app ||
      !result.me.organization.app.objective
      // result.me.organization.app.objective.history.length === 0
    ) {
      return NO_DATA;
    }

    const labels = result.me.organization.app.objective.history.map((h) =>
      end.diff(start, 'days').toObject().days! > 3
        ? DateTime.fromISO(h.start).toFormat('LLL dd')
        : DateTime.fromISO(h.start).toLocaleString(DateTime.TIME_SIMPLE)
    );

    return {
      labels,
      datasets: [
        {
          data: result.me.organization.app.objective.indicator.history.map(
            (history) => {
              if (
                history.result &&
                history.result.__typename === 'AverageResult'
              ) {
                return (
                  history.result.values.reduce(
                    (acc, curr) => acc + curr.value,
                    0
                  ) / history.result.values.length
                );
              } else if (
                history.result &&
                history.result.__typename === 'RateResult'
              ) {
                return history.result.value;
              } else {
                return null;
              }
            }
          ),
          borderColor,
          tension: 0.1,
        },
      ],
    };
  }, [result, NO_DATA]);

  const NO_OPTIONS: ChartOptions<'line'> = useMemo(() => ({}), []);
  const options = useMemo((): ChartOptions<'line'> => {
    if (
      !result ||
      !result.me.organization ||
      !result.me.organization.app ||
      !result.me.organization.app.objective
    ) {
      return NO_OPTIONS;
    }

    const dataset = data.datasets
      ? data.datasets[0].data.filter((d): d is number => typeof d === 'number')
      : [];
    const min = Math.min(...dataset);
    const max = Math.max(...dataset);

    const annotations = objectiveAnnotations(
      result.me.organization.app.objective.history,
      min,
      max,
      result.me.organization.app.objective.threshold
    );

    return {
      ...defaultOptions,
      scales: {
        ...defaultOptions.scales,
        x: {
          ...(defaultOptions.scales?.x ?? {}),
          ticks: {
            ...(defaultOptions.scales?.x?.ticks ?? {}),
            color: '#fff',
          },
        },
        y: {
          ...(defaultOptions.scales?.y ?? {}),
          suggestedMin: min - min * 0.1,
          suggestedMax: max + max * 0.1,
          ticks: {
            ...(defaultOptions.scales?.y?.ticks ?? {}),
            color: '#fff',
          },
        },
      },
      plugins: {
        annotation: {
          annotations,
        },
      },
    };
  }, [data, result, NO_OPTIONS]);

  return (
    <Container>
      <HistoryNav>
        <HistoryActions>
          <DateRange end={end} start={start} onChange={onChange} />
        </HistoryActions>
      </HistoryNav>
      <ChartContainer>
        <Line data={data} options={options} />
      </ChartContainer>
    </Container>
  );
}
