import { UserRole } from '@event-horizon/graphql-api-schema';
import { FocusTrap } from '@mui/base/FocusTrap';
import { Close, Menu, Person, PersonAdd, Settings } from '@mui/icons-material';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { AppSwitcher } from './AppSwitcher';
import { glassSurface } from './GlassSurface';
import { UserMenu } from './UserMenu';

interface OrganizationMenuProps {
  apps: {
    id: string;
    name: string;
  }[];
  organization: {
    id: string;
    logo: string | null;
    name: string;
  };
  user: {
    email: string;
    name: string | null;
  };
  role: UserRole;
}

const MobileMenu = styled.div<{ open: boolean }>`
  display: none;

  @media (max-width: 960px) {
    display: ${({ open }) => (open ? 'flex' : 'none')};
    flex-direction: column;
    z-index: 1000;
    position: fixed;
    top: 81px;
    left: 0;
    height: calc(100% - 81px);
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(24px);
  }
`;

const MobileNav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
`;

const RailMenu = styled.div<{ open: boolean }>`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);

  @media (min-width: 960px) {
    height: 100%;
    width: 260px;
    position: fixed;
    display: grid;
    grid-template-rows: auto 1fr 72px;
    border-right: 1px solid rgba(255, 255, 255, 0.12);
    border-bottom: none;
  }

  @media (max-width: 960px) {
    ${({ open }) => (open ? glassSurface : '')};
    --glass-border-opacity: 0%;
  }
`;

const GlobalMenu = styled.div`
  display: flex;
  padding: 24px;
  gap: 24px;
`;

const LogoLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  height: 32px;
  width: 32px;
`;

const Nav = styled.nav`
  display: none;

  @media (min-width: 960px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    gap: 32px;
  }
`;

const NavMenuLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  color: rgba(255, 255, 255, 0.72);
  font-family: Lexend, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: color 0.3s ease-in-out;

  span {
    display: block;
  }

  &:hover {
    color: rgba(255, 255, 255, 1);
  }

  &.active {
    color: #5f75f9;
    span {
      color: white;
    }
  }
`;

const MenuLink = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  color: rgba(255, 255, 255, 0.72);

  @media (min-width: 960px) {
    display: none;
  }
`;

export function OrganizationMenu(props: OrganizationMenuProps): ReactElement {
  const [open, setOpen] = useState<boolean>(false);

  const handleOnClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleToggleClose = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  useEffect(() => {
    if (open) {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [open]);

  return (
    <FocusTrap open={open}>
      <>
        <MobileMenu open={open}>
          <MobileNav>
            <NavMenuLink onClick={handleOnClose} to="./edit">
              <Settings />
              <span>Settings</span>
            </NavMenuLink>
            <NavMenuLink onClick={handleOnClose} to="./users">
              <Person />
              <span>Members</span>
            </NavMenuLink>
            <NavMenuLink onClick={handleOnClose} to="./invites">
              <PersonAdd />
              <span>Invites</span>
            </NavMenuLink>
          </MobileNav>
        </MobileMenu>
        <RailMenu open={open}>
          <GlobalMenu>
            <LogoLink to="/">
              <Logo src="/icons/polaris-c.svg" />
            </LogoLink>
            <AppSwitcher
              apps={props.apps}
              organization={props.organization}
              role={props.role}
            />
          </GlobalMenu>
          <Nav>
            <NavMenuLink to="./edit">
              <Settings />
              <span>Settings</span>
            </NavMenuLink>
            <NavMenuLink to="./users">
              <Person />
              <span>Members</span>
            </NavMenuLink>
            <NavMenuLink to="./invites">
              <PersonAdd />
              <span>Invites</span>
            </NavMenuLink>
          </Nav>
          <UserMenu
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            user={props.user}
          />
          <MenuLink onClick={handleToggleClose}>
            {open && <Close />}
            {!open && <Menu />}
          </MenuLink>
        </RailMenu>
      </>
    </FocusTrap>
  );
}
