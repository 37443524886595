import { ChartOptions } from 'chart.js';

export const options: ChartOptions<'line'> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        display: true,
        callback: function (val, index) {
          return index % 2 === 0 ? this.getLabelForValue(Number(val)) : '';
        },
      },
    },
    y: {
      grid: {
        display: true,
      },
      ticks: {
        display: true,
        major: {
          enabled: true,
        },
      },
    },
  },
  spanGaps: true,
};
