import auth0 from 'auth0-js';
import React, {
  createContext,
  FC,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

type AuthContextType = {
  authenticate: (email: string) => Promise<void>;
  getToken: () => Promise<string | null>;
  isLoading: boolean;
  logout: () => void;
  user: auth0.Auth0UserProfile | null;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const checkSession = function (
  webAuth: auth0.WebAuth,
  searchParams: URLSearchParams
): Promise<auth0.Auth0DecodedHash | null> {
  return new Promise<auth0.Auth0DecodedHash | null>((resolve, reject) => {
    webAuth.checkSession(
      {
        invitation: searchParams.get('invitation') ?? undefined,
        organization: searchParams.get('organization') ?? undefined,
      },
      function (error, result) {
        if (error) {
          return reject(error);
        }
        if (result && result.accessToken) {
          window.localStorage.setItem('access_token', result.accessToken);
        }
        resolve(result);
      }
    );
  });
};

const getUserInfo = function (
  webAuth: auth0.WebAuth,
  searchParams: URLSearchParams,
  attempt = 0
): Promise<auth0.Auth0UserProfile | null> {
  const accessToken = window.localStorage.getItem('access_token');
  if (!accessToken) {
    return Promise.resolve(null);
  }
  return new Promise<auth0.Auth0UserProfile>((resolve, reject) => {
    webAuth.client.userInfo(accessToken, function (error, user) {
      if (error) {
        if (attempt > 3) {
          return reject(error);
        }
        if (error.statusCode === 401) {
          return checkSession(webAuth, searchParams)
            .then(() => getUserInfo(webAuth, searchParams, ++attempt))
            .catch(reject);
        }
        return reject(error);
      }
      resolve(user);
    });
  });
};

const parseHash = function (
  webAuth: auth0.WebAuth
): Promise<auth0.Auth0DecodedHash | null> {
  return new Promise<auth0.Auth0DecodedHash | null>((resolve, reject) => {
    webAuth.parseHash({ hash: window.location.hash }, function (error, result) {
      window.location.hash = '';
      if (error) {
        return reject(error);
      }
      if (result && result.accessToken) {
        window.localStorage.setItem('access_token', result.accessToken);
      }
      resolve(result);
    });
  });
};

export const AuthProvider: FC<PropsWithChildren> = ({
  children,
}): ReactElement => {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<auth0.Auth0UserProfile | null>(null);
  const initialized = useRef(false);

  const webAuth = useMemo(
    () =>
      new auth0.WebAuth({
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        domain: import.meta.env.VITE_AUTH0_DOMAIN,
        clientID: import.meta.env.VITE_AUTH0_CLIENT_ID,
        redirectUri: window.location.origin,
        responseType: 'token',
      }),
    []
  );

  const init = useCallback(() => {
    if (initialized.current) {
      return Promise.resolve();
    }
    initialized.current = true;
    return parseHash(webAuth)
      .then(() => getUserInfo(webAuth, searchParams))
      .then(setUser)
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, [searchParams, webAuth]);

  const authenticate = useCallback(
    async (email: string) => {
      return new Promise<void>((resolve, reject) => {
        webAuth.passwordlessStart(
          {
            connection: 'email',
            send: 'link',
            email,
            authParams: {
              scope: 'openid profile email',
            },
          },
          function (error) {
            if (error) {
              reject(new Error(error.description));
              return;
            }
            resolve();
          }
        );
      });
    },
    [webAuth]
  );

  const getToken = useCallback(async (): Promise<string | null> => {
    await init();
    return window.localStorage.getItem('access_token');
  }, [init]);

  const logout = useCallback(() => {
    window.localStorage.removeItem('access_token');
    webAuth.logout({
      returnTo: window.location.origin,
    });
  }, [webAuth]);

  const value = useMemo(
    () => ({
      authenticate,
      getToken,
      isLoading,
      logout,
      user,
    }),
    [authenticate, getToken, isLoading, logout, user]
  );

  useEffect(() => {
    init();
  }, [init]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    if (process.env.NODE_ENV === 'test') {
      return {
        authenticate: () => Promise.resolve(),
        getToken: () => Promise.resolve(null),
        isLoading: false,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        logout: () => {},
        user: {
          email: 'test@getpolaris.ai',
          email_verified: false,
          family_name: 'Test',
          given_name: 'User',
          locale: 'en',
          name: 'Test User',
          sub: 'auth0|test',
        } as auth0.Auth0UserProfile,
      };
    }
    throw new Error('The useAuth hook must be used within an AuthProvider');
  }
  return context;
}
