import { DateTime } from 'luxon';
import { ReactElement, useCallback, useState } from 'react';
import { IndicatorHistory } from './IndicatorHistory';
import { useIndicator } from './IndicatorOutlet';

export function IndicatorDataPage(): ReactElement {
  const { application, indicator, organization } = useIndicator();
  const [start, setStart] = useState<DateTime>(DateTime.now().startOf('day'));
  const [end, setEnd] = useState<DateTime>(DateTime.now().startOf('hour'));

  const handleOnChange = useCallback(
    (start: DateTime, end: DateTime) => {
      setStart(start);
      setEnd(end);
    },
    [setStart, setEnd]
  );

  return (
    <IndicatorHistory
      orgId={organization.id}
      appId={application.id}
      indicatorId={indicator.id}
      description={indicator.description}
      start={start}
      end={end}
      onChange={handleOnChange}
    />
  );
}
