import { ThresholdType } from '@event-horizon/app-models';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  Typography,
} from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import {
  ChangeEvent,
  FocusEvent,
  ReactElement,
  SyntheticEvent,
  useCallback,
} from 'react';
import styled from 'styled-components';

interface ObjectiveThresholdProps {
  errors?: FormikErrors<{
    gte?: string;
    lte: string;
  }>;
  name: string;
  onBlur: (event: FocusEvent<HTMLInputElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onThresholdTypeChange: (thresholdType: ThresholdType | null) => void;
  thresholdType: ThresholdType | null;
  touched?: FormikTouched<{
    gte?: string;
    lte: string;
  }>;
  value: {
    gte?: string;
    lte?: string;
  };
}

const ObjectiveThresholdContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ObjectiveThresholdDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ObjectiveThresholdDetailsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

export function ObjectiveThreshold(
  props: ObjectiveThresholdProps
): ReactElement {
  const handleChange = useCallback(
    (panel: ThresholdType) => (event: SyntheticEvent, isExpanded: boolean) => {
      props.onThresholdTypeChange(isExpanded ? panel : null);
    },
    []
  );

  return (
    <ObjectiveThresholdContainer>
      <Accordion
        expanded={props.thresholdType === ThresholdType.BOUNDED}
        onChange={handleChange(ThresholdType.BOUNDED)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls={`objective-threshold-${ThresholdType.BOUNDED}-content`}
        >
          Bounded Threshold
        </AccordionSummary>
        <ObjectiveThresholdDetails>
          <ObjectiveThresholdDetailsRow>
            <TextField
              error={props.touched?.gte && Boolean(props.errors?.gte)}
              helperText={props.touched?.gte && props.errors?.gte}
              label="Greater-than or equal to"
              name={`${props.name}.gte`}
              onBlur={props.onBlur}
              onChange={props.onChange}
              value={props.value.gte}
            />
            <TextField
              error={props.touched?.lte && Boolean(props.errors?.lte)}
              helperText={props.touched?.lte && props.errors?.lte}
              label="Less-than or equal to"
              name={`${props.name}.lte`}
              onBlur={props.onBlur}
              onChange={props.onChange}
              value={props.value.lte}
            />
          </ObjectiveThresholdDetailsRow>
        </ObjectiveThresholdDetails>
      </Accordion>
      <Accordion
        expanded={props.thresholdType === ThresholdType.LOWER}
        onChange={handleChange(ThresholdType.LOWER)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls={`objective-threshold-${ThresholdType.LOWER}-content`}
        >
          Lower Threshold
        </AccordionSummary>
        <ObjectiveThresholdDetails>
          <TextField
            error={props.touched?.lte && Boolean(props.errors?.lte)}
            fullWidth
            helperText={props.touched?.lte && props.errors?.lte}
            label="Less-than or equal to"
            name={`${props.name}.lte`}
            onBlur={props.onBlur}
            onChange={props.onChange}
            value={props.value.lte}
          />
        </ObjectiveThresholdDetails>
      </Accordion>
      <Accordion
        expanded={props.thresholdType === ThresholdType.UPPER}
        onChange={handleChange(ThresholdType.UPPER)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls={`objective-threshold-${ThresholdType.UPPER}-content`}
        >
          Upper Threshold
        </AccordionSummary>
        <ObjectiveThresholdDetails>
          <TextField
            error={props.touched?.gte && Boolean(props.errors?.gte)}
            fullWidth
            helperText={props.touched?.gte && props.errors?.gte}
            label="Greater-than or equal to"
            name={`${props.name}.gte`}
            onBlur={props.onBlur}
            onChange={props.onChange}
            value={props.value.gte}
          />
        </ObjectiveThresholdDetails>
      </Accordion>
    </ObjectiveThresholdContainer>
  );
}
