import { ChevronLeft } from '@mui/icons-material';
import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  opacity: 0.72;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  span {
    font-family: Lexend;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export interface BackLinkProps {
  to: string;
  children: string;
}

export function BackLink(props: BackLinkProps) {
  return (
    <StyledNavLink to={props.to}>
      <ChevronLeft />
      <span>{props.children}</span>
    </StyledNavLink>
  );
}
