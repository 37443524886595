import { Chart } from 'chart.js';
import { tooltip } from './tooltip';

export function chartDefaults() {
  Chart.defaults.elements = {
    ...Chart.defaults.elements,
    line: {
      ...Chart.defaults.elements.line,
      borderWidth: 4,
    },
    point: {
      ...Chart.defaults.elements.point,
      radius: 5,
    },
  };

  Chart.defaults.font = {
    ...Chart.defaults.font,
    family: 'Lexend, sans-serif',
    size: 10,
    weight: 300,
  };

  Chart.defaults.interaction = {
    ...Chart.defaults.interaction,
    mode: 'index',
    intersect: false,
  };

  Chart.defaults.plugins.legend = {
    ...Chart.defaults.plugins.legend,
    display: false,
  };

  Chart.defaults.plugins.tooltip = {
    ...Chart.defaults.plugins.tooltip,
    enabled: false,
    position: 'nearest',
    external: tooltip,
  };

  Chart.defaults.backgroundColor = '#131723';
  Chart.defaults.borderColor = 'rgba(255, 255, 255, 0.08)';
  Chart.defaults.color = '#000';
  Chart.defaults.elements = {
    ...Chart.defaults.elements,
    point: {
      ...Chart.defaults.elements.point,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      radius: 0,
      hoverBackgroundColor: '#10DED4',
      hoverBorderColor: '#10DED4',
      hoverRadius: 6,
    },
  };
}
