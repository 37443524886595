import { scaleLinear, line, area, curveCatmullRom, NumberValue } from 'd3';
import { DateTime } from 'luxon';
import { useMemo, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { Popper } from '@mui/material';
import styled from 'styled-components';
import useResizeObserver from 'use-resize-observer';
import { glassSurface } from '@event-horizon/app-components';
import {
  Aggregation,
  IndicatorOperation,
} from '@event-horizon/graphql-api-schema';

const BLEED_TOP = 56;
const BLEED_BOTTOM = 24;
const BLEED_LEFT = 24;
const BLEED_RIGHT = 24;

export interface IndicatorExpandedChartViewModel {
  date: DateTime;
  value: number;
}

export interface IndicatorExpandedChartProps {
  data: IndicatorExpandedChartViewModel[];
  aggregation: Aggregation;
  operation: IndicatorOperation;
}

const pathAnimationProps = {
  transition: {
    duration: 1,
    ease: 'easeInOut',
  },
  initial: {
    opacity: 0,
    pathLength: 0,
  },
  animate: {
    opacity: 1,
    pathLength: 1,
  },
};

const fadeInAnimationProps = {
  transition: {
    duration: 1,
    ease: 'easeInOut',
  },
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 88px;
  height: 100%;
`;

const HoverTarget = styled.circle`
  & + circle {
    fill-opacity: 0;
    pointer-events: none;
    transition: fill 0.3s;
  }

  &:hover + circle {
    fill-opacity: 1;
  }
`;

const Tooltip = styled(motion.div)`
  ${glassSurface};
  padding: 8px;
  margin: -26px 0 0 24px;

  .value {
    color: #fff;
    font: normal normal normal 14px/17px Lexend;
  }

  .label {
    color: rgba(255, 255, 255, 0.64);
    font: normal normal 500 11px/13px Lexend;
  }
`;

const MetricsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 64px;
  border-top: 1px solid rgba(255, 255, 255, 0.12);

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;

    .value {
      color: #fff;
      font: normal normal normal 20px/24px Lexend;
    }

    .label {
      color: #b557ff;
      font: normal normal normal 10px/12px Lexend;
      text-transform: uppercase;
    }
  }
`;

const formatPercent = (value: number) =>
  value.toLocaleString('en', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
const formatSeconds = (value: number) =>
  (value / 1000).toLocaleString('en', {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  }) + ' seconds';
const formatCount = (value: number) =>
  value.toLocaleString('en', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
const formatValue = (value: number, operation: IndicatorOperation) => {
  if (operation === IndicatorOperation.RATE) {
    return formatPercent(value);
  }
  if (operation === IndicatorOperation.COUNT) {
    return formatCount(value);
  }

  return formatSeconds(value);
};

export function IndicatorExpandedChart({
  data,
  aggregation,
  operation,
}: IndicatorExpandedChartProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { width: divWidth = 0, height: divHeight = 0 } =
    useResizeObserver<HTMLDivElement>({
      ref: containerRef,
    });
  const width = divWidth;
  const height = divHeight - 88;
  const [label, setLabel] = useState<null | {
    value: string;
    date: string;
    anchorEl: SVGGElement;
  }>(null);
  const xAxis = useMemo(() => {
    const dates = data.map((d) => d.date);
    const minDate = DateTime.min(...dates);
    const maxDate = DateTime.max(...dates);

    return scaleLinear()
      .domain([minDate.toMillis(), maxDate.toMillis()])
      .range([BLEED_LEFT, width + BLEED_LEFT]);
  }, [data, width]);
  const yAxis = useMemo(() => {
    const values = data.map((d) => d.value);
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);

    return scaleLinear()
      .domain([minValue, maxValue])
      .range([height + BLEED_TOP, BLEED_TOP])
      .nice();
  }, [data, height]);
  const ticks = useMemo(() => {
    return yAxis.ticks(5);
  }, [yAxis]);
  const tickFormat = useMemo(() => {
    return (value: NumberValue) => formatValue(value as number, operation);
  }, [operation]);
  const sparkLineFn = useMemo(() => {
    return line<IndicatorExpandedChartViewModel>()
      .curve(curveCatmullRom.alpha(0))
      .x((d) => xAxis(d.date.toMillis()))
      .y((d) => yAxis(d.value));
  }, [xAxis, yAxis]);
  const sparkAreaFn = useMemo(() => {
    return area<IndicatorExpandedChartViewModel>()
      .curve(curveCatmullRom.alpha(0))
      .x((d) => xAxis(d.date.toMillis()))
      .y0(height + BLEED_TOP)
      .y1((d) => yAxis(d.value));
  }, [xAxis, yAxis, height]);
  const sparkLinePath = useMemo(() => {
    return sparkLineFn(data) || '';
  }, [data, sparkLineFn]);
  const sparkAreaPath = useMemo(() => {
    return sparkAreaFn(data) || '';
  }, [data, sparkAreaFn]);
  const markers = useMemo(() => {
    return data.map((d) => {
      const x = xAxis(d.date.toMillis()) || 0;
      const y = yAxis(d.value) || 0;

      return {
        x,
        y,
        label: {
          value: formatValue(d.value, operation),
          date: d.date.toLocaleString(
            aggregation === Aggregation.BY_DAY
              ? DateTime.DATE_FULL
              : DateTime.DATETIME_SHORT
          ),
        },
      };
    });
  }, [data, aggregation, xAxis, yAxis, operation]);
  const averageValue = useMemo(() => {
    const total = data.reduce((acc, d) => acc + d.value, 0);

    return total / data.length;
  }, [data]);
  const medianValue = useMemo(() => {
    const sorted = data.map((d) => d.value).sort((a, b) => a - b);
    const middle = Math.floor(sorted.length / 2);

    if (sorted.length % 2 === 0) {
      return (sorted[middle] + sorted[middle - 1]) / 2;
    }

    return sorted[middle];
  }, [data]);
  const minimumValue = useMemo(() => {
    return Math.min(...data.map((d) => d.value));
  }, [data]);
  const maximumValue = useMemo(() => {
    return Math.max(...data.map((d) => d.value));
  }, [data]);

  const createTooltipMouseoverCallback =
    (value: string, date: string) =>
    (event: React.MouseEvent<SVGGElement, MouseEvent>) => {
      setLabel({ value, date, anchorEl: event.currentTarget });
    };

  const createTooltipMouseoutCallback = () => () => {
    setLabel(null);
  };

  return (
    <Container ref={containerRef}>
      <motion.svg
        width={width + BLEED_LEFT + BLEED_RIGHT}
        height={height + BLEED_TOP + BLEED_BOTTOM}
        style={{
          position: 'relative',
          marginTop: `-${BLEED_TOP}px`,
          marginLeft: `-${BLEED_LEFT}px`,
          marginRight: `-${BLEED_RIGHT}px`,
          marginBottom: `-${BLEED_BOTTOM}px`,
        }}
      >
        <defs>
          <radialGradient
            id="indicator_expanded_chart_radial_gradient"
            cx={BLEED_LEFT}
            cy={height}
            r={Math.max(width, height)}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#10DDD3" />
            <stop offset="75%" stopColor="#5F75F9" />
            <stop offset="100%" stopColor="#B557FF" />
          </radialGradient>
          <clipPath id="indicator_expanded_chart_inner_clip">
            <rect
              x={BLEED_LEFT}
              y={0}
              width={width}
              height={BLEED_TOP + height}
            />
          </clipPath>
        </defs>
        <motion.path
          d={sparkAreaPath}
          fill="url(#indicator_expanded_chart_radial_gradient)"
          fillOpacity="0.12"
          clipPath="url(#indicator_expanded_chart_inner_clip)"
          style={{ filter: 'blur(40px)' }}
          {...pathAnimationProps}
        />
        <motion.path
          d={sparkAreaPath}
          fill="url(#indicator_expanded_chart_radial_gradient)"
          fillOpacity="0.12"
          clipPath="url(#indicator_expanded_chart_inner_clip)"
          {...pathAnimationProps}
        />
        <motion.path
          d={sparkLinePath}
          fill="none"
          stroke="url(#indicator_expanded_chart_radial_gradient)"
          strokeWidth="1"
          clipPath="url(#indicator_expanded_chart_inner_clip)"
          {...pathAnimationProps}
        />
        {ticks.map((tick, index) => {
          const y = yAxis(tick) || 0;

          if (index === 0) {
            return null;
          }

          return (
            <motion.g
              key={index}
              transform={`translate(${BLEED_LEFT} ${y})`}
              {...fadeInAnimationProps}
            >
              <line
                x1={0}
                x2={width}
                stroke="#fff"
                strokeOpacity={0.08}
                strokeWidth="1"
              />
              <text
                x="24"
                y="20"
                fill="#fff"
                fontSize="12"
                dominantBaseline="middle"
                opacity={0.32}
                fontWeight={500}
                fontFamily="Lexend"
              >
                {tickFormat(tick)}
              </text>
            </motion.g>
          );
        })}
        {markers.map((marker, index) => {
          return (
            <motion.g
              key={index}
              transform={`translate(${marker.x}, ${marker.y})`}
            >
              <HoverTarget
                cx="0"
                cy="0"
                r="12"
                fill="transparent"
                onMouseEnter={createTooltipMouseoverCallback(
                  marker.label.value,
                  marker.label.date
                )}
                onMouseLeave={createTooltipMouseoutCallback()}
              />
              <circle
                cx="0"
                cy="0"
                r="8"
                fill="url(#indicator_expanded_chart_radial_gradient)"
              />
            </motion.g>
          );
        })}
      </motion.svg>
      <MetricsContainer>
        <motion.div {...fadeInAnimationProps}>
          <div className="value">{formatValue(averageValue, operation)}</div>
          <div className="label">Average</div>
        </motion.div>
        <motion.div {...fadeInAnimationProps}>
          <div className="value">{formatValue(medianValue, operation)}</div>
          <div className="label">Median</div>
        </motion.div>
        <motion.div {...fadeInAnimationProps}>
          <div className="value">{formatValue(minimumValue, operation)}</div>
          <div className="label">Minimum</div>
        </motion.div>
        <motion.div {...fadeInAnimationProps}>
          <div className="value">{formatValue(maximumValue, operation)}</div>
          <div className="label">Maximum</div>
        </motion.div>
      </MetricsContainer>
      <Popper
        sx={{ pointerEvents: 'none' }}
        open={!!label}
        anchorEl={label?.anchorEl}
        placement="bottom-start"
      >
        <Tooltip
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <div className="value">{label?.value}</div>
          <div className="label">{label?.date}</div>
        </Tooltip>
      </Popper>
    </Container>
  );
}
