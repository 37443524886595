import { OrganizationMenu } from '@event-horizon/app-components';
import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { useOrganization } from './OrganizationOutlet';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 960px) {
    flex-direction: row;
  }
`;

const OrganizationContent = styled.div`
  flex: 1 auto;
  display: flex;
  flex-direction: column;

  @media (min-width: 960px) {
    margin-left: 261px;
  }
`;

export function OrganizationPage(): ReactElement {
  const { apps, organization, user, role } = useOrganization();
  return (
    <Container>
      <OrganizationMenu
        apps={apps}
        organization={organization}
        user={user}
        role={role}
      />
      <OrganizationContent>
        <Outlet />
      </OrganizationContent>
    </Container>
  );
}
