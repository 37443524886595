import { glassSurface } from '@event-horizon/app-components';
import { ContentCopy } from '@mui/icons-material';
import { ReactElement, ReactNode, useCallback, useRef } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  ${glassSurface};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  margin: 24px 0;
`;

const Content = styled.div`
  font: normal normal 400 16px/24px Operator Mono;
  padding: 24px;
`;

const Header = styled.div`
  ${glassSurface};
  --glass-background-color-a: rgba(16, 221, 211, 0.12);
  --glass-background-color-b: rgba(95, 117, 249, 0.12);
  --glass-background-color-c: rgba(181, 87, 255, 0.12);
  --glass-border-radius: 0;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-top-left-radius: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
`;

const CodeType = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font: normal normal 500 14px/17px Lexend;
  color: rgba(255, 255, 255, 0.64);
`;

const Actions = styled.div`
  display: flex;
  align-items: center;

  > button {
    > svg {
      height: 16px;
      width: 16px;
    }
  }
`;

interface CodeBlockProps {
  children: ReactNode;
  icon?: ReactNode;
  title?: string;
}

export function CodeBlock({
  children,
  icon,
  title,
}: CodeBlockProps): ReactElement {
  const contentRef = useRef<HTMLDivElement>(null);

  const handleOnClick = useCallback(async () => {
    if (!contentRef.current) return;
    const selection = getSelection();
    const range = document.createRange();
    range.selectNodeContents(contentRef.current);
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
      await navigator.clipboard.writeText(selection.toString());
      selection.removeAllRanges();
    }
  }, [contentRef]);

  return (
    <Container>
      <Header>
        <CodeType>
          {icon}
          {title}
        </CodeType>
        <Actions>
          <button type="button" onClick={handleOnClick}>
            <ContentCopy />
          </button>
        </Actions>
      </Header>
      <Content ref={contentRef}>{children}</Content>
    </Container>
  );
}
