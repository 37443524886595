import { Percentile as PercentileType } from '@event-horizon/graphql-api-schema';

interface PercentileProps {
  percentile: PercentileType;
}

export function Percentile({ percentile }: PercentileProps) {
  switch (percentile) {
    case PercentileType.FIFTIETH:
      return <>50th Percentile</>;
    case PercentileType.NINETIETH:
      return <>90th Percentile</>;
    case PercentileType.NINETY_FIFTH:
      return <>95th Percentile</>;
    case PercentileType.NINETY_NINTH:
      return <>99th Percentile</>;
    case PercentileType.ONE_HUNDREDTH:
      return <>All</>;
  }
}
