// AUTO-GENERATED FILE - DO NOT MODIFY
import { DateTime } from 'luxon';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ApiKey: string;
  DateTime: DateTime;
  EmailAddress: string;
  JavaScriptSourceCode: string;
  Json: string;
  Url: string;
};

export type AddUserToOrganizationInput = {
  organizationId: Scalars['ID'];
  role: UserRole;
  userId: Scalars['ID'];
};

export enum Aggregation {
  BY_DAY = 'BY_DAY',
  BY_HOUR = 'BY_HOUR',
}

export type App = {
  __typename: 'App';
  apiKey: Scalars['ApiKey'];
  consumption: Consumption;
  consumptionHistory: Array<Consumption>;
  id: Scalars['ID'];
  indicator: Maybe<Indicator>;
  indicators: Array<Indicator>;
  measurementBudget: Scalars['Int'];
  measurementsConnection: MeasurementConnection;
  name: Scalars['String'];
  objective: Maybe<Objective>;
  objectives: Array<Objective>;
  previewIndicator: IndicatorPreview;
  sdk: Sdk;
  webhooks: Array<Webhook>;
};

export type AppConsumptionArgs = {
  input: ConsumptionQueryInput;
};

export type AppConsumptionHistoryArgs = {
  input: ConsumptionQueryInput;
};

export type AppIndicatorArgs = {
  id: Scalars['ID'];
};

export type AppMeasurementsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type AppObjectiveArgs = {
  id: Scalars['ID'];
};

export type AppPreviewIndicatorArgs = {
  input: PreviewIndicatorInput;
};

export type AverageResult = {
  __typename: 'AverageResult';
  values: Array<ValueByPercentile>;
};

export type BoundedThreshold = {
  __typename: 'BoundedThreshold';
  gte: Scalars['Float'];
  lte: Scalars['Float'];
};

export enum ConnectionQuality {
  FAST = 'FAST',
  OK = 'OK',
  SLOW = 'SLOW',
  SLOWEST = 'SLOWEST',
}

export type Consumption = {
  __typename: 'Consumption';
  end: Scalars['DateTime'];
  measurements: Scalars['Int'];
  start: Scalars['DateTime'];
};

export type ConsumptionQueryInput = {
  aggregation: Aggregation;
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type CountResult = {
  __typename: 'CountResult';
  value: Scalars['Float'];
};

export type CreateAppInput = {
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  sdk: Sdk;
  webhooks: Array<Scalars['Url']>;
};

export type CreateIndicatorInput = {
  appId: Scalars['ID'];
  name: Scalars['String'];
  operation: IndicatorOperation;
  predicateFunction: Scalars['JavaScriptSourceCode'];
  window: IndicatorWindow;
};

export type CreateInviteInput = {
  email: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type CreateObjectiveInput = {
  indicatorId: Scalars['ID'];
  name: Scalars['String'];
  percentile: InputMaybe<Percentile>;
  threshold: CreateThresholdInput;
};

export type CreateOrganizationInput = {
  logo: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateThresholdInput = {
  gte: InputMaybe<Scalars['Float']>;
  lte: InputMaybe<Scalars['Float']>;
};

export type CreateUserInput = {
  id: Scalars['ID'];
};

export type DeleteAppInput = {
  appId: Scalars['ID'];
};

export type DeleteIndicatorInput = {
  indicatorId: Scalars['ID'];
};

export type DeleteObjectiveInput = {
  objectiveId: Scalars['ID'];
};

export type DeleteOrganizationInput = {
  organizationId: Scalars['ID'];
};

export enum DeviceType {
  BOT = 'BOT',
  CAR = 'CAR',
  DESKTOP = 'DESKTOP',
  PHONE = 'PHONE',
  SERVER = 'SERVER',
  TABLET = 'TABLET',
  TV = 'TV',
}

export type Indicator = {
  __typename: 'Indicator';
  description: Maybe<Scalars['String']>;
  history: Array<IndicatorHistoryEvent>;
  id: Scalars['ID'];
  logMessagesConnection: IndicatorLogMessageConnection;
  measurement: Measurement;
  measurementsConnection: MeasurementConnection;
  name: Scalars['String'];
  objectives: Array<Objective>;
  operation: IndicatorOperation;
  predicateFunction: Scalars['JavaScriptSourceCode'];
  window: IndicatorWindow;
};

export type IndicatorHistoryArgs = {
  input: IndicatorHistoryQueryInput;
};

export type IndicatorLogMessagesConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
};

export type IndicatorMeasurementArgs = {
  id: Scalars['ID'];
};

export type IndicatorMeasurementsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
};

export type IndicatorHistoryEvent = {
  __typename: 'IndicatorHistoryEvent';
  end: Scalars['DateTime'];
  result: Maybe<IndicatorValue>;
  start: Scalars['DateTime'];
  state: IndicatorState;
};

export type IndicatorHistoryQueryInput = {
  aggregation: Aggregation;
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type IndicatorLogMessageConnection = {
  __typename: 'IndicatorLogMessageConnection';
  edges: Array<IndicatorLogMessageEdge>;
  pageInfo: IndicatorLogMessagePageInfo;
};

export type IndicatorLogMessageEdge = {
  __typename: 'IndicatorLogMessageEdge';
  cursor: Scalars['String'];
  node: LogMessage;
};

export type IndicatorLogMessagePageInfo = {
  __typename: 'IndicatorLogMessagePageInfo';
  endCursor: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
};

export enum IndicatorOperation {
  AVERAGE = 'AVERAGE',
  COUNT = 'COUNT',
  RATE = 'RATE',
}

export type IndicatorPreview = {
  __typename: 'IndicatorPreview';
  history: Array<IndicatorHistoryEvent>;
  logMessages: Array<LogMessage>;
};

export enum IndicatorState {
  OK = 'OK',
  UNKNOWN = 'UNKNOWN',
}

export type IndicatorValue = AverageResult | CountResult | RateResult;

export enum IndicatorWindow {
  LAST_DAY = 'LAST_DAY',
  LAST_FIFTEEN_MINUTES = 'LAST_FIFTEEN_MINUTES',
  LAST_FIVE_MINUTES = 'LAST_FIVE_MINUTES',
  LAST_HOUR = 'LAST_HOUR',
  LAST_MINUTE = 'LAST_MINUTE',
  LAST_MONTH = 'LAST_MONTH',
  LAST_SIX_HOURS = 'LAST_SIX_HOURS',
  LAST_TEN_MINUTES = 'LAST_TEN_MINUTES',
  LAST_THIRTY_MINUTES = 'LAST_THIRTY_MINUTES',
  LAST_TWELVE_HOURS = 'LAST_TWELVE_HOURS',
  LAST_TWO_WEEKS = 'LAST_TWO_WEEKS',
  LAST_WEEK = 'LAST_WEEK',
}

export type Invite = {
  __typename: 'Invite';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  organization: Organization;
};

export type LogMessage = {
  __typename: 'LogMessage';
  id: Scalars['ID'];
  message: Scalars['Json'];
  timestamp: Scalars['DateTime'];
};

export type LowerThreshold = {
  __typename: 'LowerThreshold';
  lte: Scalars['Float'];
};

export type Measurement = {
  __typename: 'Measurement';
  duration: Scalars['Int'];
  id: Scalars['ID'];
  metadata: MeasurementMetadata;
  name: Scalars['String'];
  result: MeasurementResult;
  timestamp: Scalars['DateTime'];
  url: Maybe<Scalars['String']>;
};

export type MeasurementConnection = {
  __typename: 'MeasurementConnection';
  edges: Array<MeasurementEdge>;
  pageInfo: MeasurementPageInfo;
};

export type MeasurementEdge = {
  __typename: 'MeasurementEdge';
  cursor: Scalars['String'];
  node: Measurement;
};

export type MeasurementInput = {
  apiKey: Scalars['ApiKey'];
  duration: Scalars['Int'];
  metadata: MeasurementMetadataInput;
  name: Scalars['String'];
  result: MeasurementResult;
  timestamp: Scalars['DateTime'];
  url: InputMaybe<Scalars['String']>;
};

export type MeasurementMetadata = {
  __typename: 'MeasurementMetadata';
  connectionQuality: Maybe<ConnectionQuality>;
  deviceType: DeviceType;
  userMetadata: Scalars['Json'];
};

export type MeasurementMetadataInput = {
  connectionQuality: InputMaybe<ConnectionQuality>;
  deviceType: DeviceType;
  userMetadata: Scalars['Json'];
};

export type MeasurementPageInfo = {
  __typename: 'MeasurementPageInfo';
  endCursor: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
};

export enum MeasurementResult {
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}

export type Mutation = {
  __typename: 'Mutation';
  _empty: Maybe<Scalars['String']>;
  addUserToOrganization: UserOrganizationConnection;
  createApp: App;
  createIndicator: Indicator;
  createInvite: Invite;
  createObjective: Objective;
  createOrganization: Organization;
  createUser: User;
  deleteApp: Scalars['ID'];
  deleteIndicator: Scalars['ID'];
  deleteObjective: Scalars['ID'];
  deleteOrganization: Scalars['ID'];
  logMeasurement: Measurement;
  removeUserFromOrganization: UserOrganizationConnection;
  suggestIndicators: Array<SuggestedIndicator>;
  suggestObjectives: Array<SuggestedObjective>;
  testConnection: TestConnectionPayload;
  updateApp: App;
  updateIndicator: Indicator;
  updateObjective: Objective;
  updateOrganization: Organization;
  updateUser: User;
  updateUserRole: OrganizationUserConnection;
};

export type MutationAddUserToOrganizationArgs = {
  input: AddUserToOrganizationInput;
};

export type MutationCreateAppArgs = {
  input: CreateAppInput;
};

export type MutationCreateIndicatorArgs = {
  input: CreateIndicatorInput;
};

export type MutationCreateInviteArgs = {
  input: CreateInviteInput;
};

export type MutationCreateObjectiveArgs = {
  input: CreateObjectiveInput;
};

export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDeleteAppArgs = {
  input: DeleteAppInput;
};

export type MutationDeleteIndicatorArgs = {
  input: DeleteIndicatorInput;
};

export type MutationDeleteObjectiveArgs = {
  input: DeleteObjectiveInput;
};

export type MutationDeleteOrganizationArgs = {
  input: DeleteOrganizationInput;
};

export type MutationLogMeasurementArgs = {
  input: MeasurementInput;
};

export type MutationRemoveUserFromOrganizationArgs = {
  input: RemoveUserFromOrganizationInput;
};

export type MutationSuggestIndicatorsArgs = {
  input: SuggestIndicatorsInput;
};

export type MutationSuggestObjectivesArgs = {
  input: SuggestObjectivesInput;
};

export type MutationTestConnectionArgs = {
  input: TestConnectionInput;
};

export type MutationUpdateAppArgs = {
  input: UpdateAppInput;
};

export type MutationUpdateIndicatorArgs = {
  input: UpdateIndicatorInput;
};

export type MutationUpdateObjectiveArgs = {
  input: UpdateObjectiveInput;
};

export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserRoleArgs = {
  input: UpdateUserRoleInput;
};

export type Objective = {
  __typename: 'Objective';
  history: Array<ObjectiveHistoryEvent>;
  id: Scalars['ID'];
  indicator: Indicator;
  name: Scalars['String'];
  percentile: Maybe<Percentile>;
  state: ObjectiveState;
  stateLastUpdated: Scalars['DateTime'];
  threshold: Threshold;
};

export type ObjectiveHistoryArgs = {
  input: ObjectiveHistoryQueryInput;
};

export type ObjectiveHistoryEvent = {
  __typename: 'ObjectiveHistoryEvent';
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
  state: ObjectiveState;
};

export type ObjectiveHistoryQueryInput = {
  aggregation: Aggregation;
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export enum ObjectiveState {
  FAILING = 'FAILING',
  PASSING = 'PASSING',
  UNKNOWN = 'UNKNOWN',
}

export type Organization = {
  __typename: 'Organization';
  app: Maybe<App>;
  apps: Array<App>;
  id: Scalars['ID'];
  invitesConnection: OrganizationInviteConnection;
  logo: Maybe<Scalars['String']>;
  name: Scalars['String'];
  userConnection: OrganizationUserConnection;
  usersConnection: OrganizationUserConnection;
};

export type OrganizationAppArgs = {
  id: Scalars['ID'];
};

export type OrganizationInviteConnection = {
  __typename: 'OrganizationInviteConnection';
  edges: Array<OrganizationInviteEdge>;
};

export type OrganizationInviteEdge = {
  __typename: 'OrganizationInviteEdge';
  node: Invite;
};

export type OrganizationUserConnection = {
  __typename: 'OrganizationUserConnection';
  edges: Array<OrganizationUserEdge>;
};

export type OrganizationUserEdge = {
  __typename: 'OrganizationUserEdge';
  node: User;
  role: UserRole;
};

export enum Percentile {
  FIFTIETH = 'FIFTIETH',
  NINETIETH = 'NINETIETH',
  NINETY_FIFTH = 'NINETY_FIFTH',
  NINETY_NINTH = 'NINETY_NINTH',
  ONE_HUNDREDTH = 'ONE_HUNDREDTH',
}

export type PreviewIndicatorInput = {
  history: IndicatorHistoryQueryInput;
  operation: IndicatorOperation;
  predicateFunction: Scalars['JavaScriptSourceCode'];
};

export type Query = {
  __typename: 'Query';
  _empty: Maybe<Scalars['String']>;
  me: User;
};

export type RateResult = {
  __typename: 'RateResult';
  value: Scalars['Float'];
};

export type RecommendedObjective = {
  __typename: 'RecommendedObjective';
  name: Scalars['String'];
  percentile: Percentile;
  threshold: Threshold;
};

export type RemoveUserFromOrganizationInput = {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};

export enum Sdk {
  ANGULAR = 'ANGULAR',
  REACT = 'REACT',
  TYPESCRIPT = 'TYPESCRIPT',
}

export type SuggestIndicatorsInput = {
  appId: Scalars['ID'];
};

export type SuggestObjectivesInput = {
  indicatorId: Scalars['ID'];
};

export type SuggestedIndicator = {
  __typename: 'SuggestedIndicator';
  appId: Scalars['ID'];
  name: Scalars['String'];
  operation: IndicatorOperation;
  predicateFunction: Scalars['JavaScriptSourceCode'];
  window: IndicatorWindow;
};

export type SuggestedObjective = {
  __typename: 'SuggestedObjective';
  indicatorId: Scalars['ID'];
  name: Scalars['String'];
  percentile: Maybe<Percentile>;
  threshold: Threshold;
};

export type TestConnectionInput = {
  apiKey: Scalars['ApiKey'];
};

export type TestConnectionPayload = {
  __typename: 'TestConnectionPayload';
  success: Scalars['Boolean'];
};

export type Threshold = BoundedThreshold | LowerThreshold | UpperThreshold;

export type UpdateAppInput = {
  appId: Scalars['ID'];
  name: Scalars['String'];
  sdk: Sdk;
  webhooks: Array<Scalars['Url']>;
};

export type UpdateIndicatorInput = {
  indicatorId: Scalars['ID'];
  name: InputMaybe<Scalars['String']>;
  operation: InputMaybe<IndicatorOperation>;
  predicateFunction: InputMaybe<Scalars['JavaScriptSourceCode']>;
  window: InputMaybe<IndicatorWindow>;
};

export type UpdateObjectiveInput = {
  name: InputMaybe<Scalars['String']>;
  objectiveId: Scalars['ID'];
  percentile: InputMaybe<Percentile>;
  threshold: InputMaybe<CreateThresholdInput>;
};

export type UpdateOrganizationInput = {
  logo: InputMaybe<Scalars['String']>;
  name: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
};

export type UpdateUserInput = {
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateUserRoleInput = {
  organizationId: Scalars['ID'];
  role: UserRole;
  userId: Scalars['ID'];
};

export type UpperThreshold = {
  __typename: 'UpperThreshold';
  gte: Scalars['Float'];
};

export type User = {
  __typename: 'User';
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  organization: Maybe<Organization>;
  organizations: Array<Organization>;
  organizationsConnection: UserOrganizationConnection;
};

export type UserOrganizationArgs = {
  id: Scalars['ID'];
};

export type UserOrganizationConnection = {
  __typename: 'UserOrganizationConnection';
  edges: Array<UserOrganizationEdge>;
};

export type UserOrganizationConnectionInput = {
  organizationId: Scalars['ID'];
  role: UserRole;
  userId: Scalars['ID'];
};

export type UserOrganizationEdge = {
  __typename: 'UserOrganizationEdge';
  node: Organization;
  role: UserRole;
};

export enum UserRole {
  MEMBER = 'MEMBER',
  OWNER = 'OWNER',
}

export type ValueByPercentile = {
  __typename: 'ValueByPercentile';
  percentile: Percentile;
  value: Scalars['Int'];
};

export type Webhook = {
  __typename: 'Webhook';
  url: Scalars['Url'];
};
