import { Dialog as MuiDialog, DialogContent, DialogTitle } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { glassSurface } from './GlassSurface';

interface AlertDialogProps {
  children: ReactNode;
  onClose: () => void;
  open: boolean;
  title: string;
}

const StyledDialog = styled(MuiDialog)`
  & .MuiPaper-root {
    ${glassSurface};
    width: 100%;
    height: 100%;
    max-height: inherit;
    padding: 0;
    margin: 0;

    > h2 {
      border-bottom: 1px solid rgba(255, 255, 255, 0.04);
    }

    > .MuiDialogContent-root {
      padding: 0;
    }
  }

  @media (min-width: 480px) {
    & .MuiPaper-root {
      height: unset;
      max-height: calc(100% - 64px);
    }
  }
`;

export function Dialog(props: AlertDialogProps): ReactElement {
  return (
    <StyledDialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="dialog-title"
      transitionDuration={0}
    >
      <DialogTitle id="dialog-title">{props.title}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
    </StyledDialog>
  );
}
