import { Close } from '@mui/icons-material';
import { IHeaderParams } from 'ag-grid-community';
import { ReactElement } from 'react';
import styled from 'styled-components';

export interface RemovableColumnHeaderProps extends IHeaderParams {
  onClick: (colId: string) => void;
}

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const Button = styled.button`
  color: #fff;
  display: flex;
  align-items: center;

  > svg {
    width: 16px;
    height: 16px;
  }
`;

export function RemovableColumnHeader({
  column,
  displayName,
  onClick,
}: RemovableColumnHeaderProps): ReactElement {
  return (
    <Header>
      {displayName}
      <Button onClick={() => onClick(column.getColId())}>
        <Close />
      </Button>
    </Header>
  );
}
