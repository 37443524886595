import { ChevronLeft } from '@mui/icons-material';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface ErrorProps {
  back?: {
    to: string;
    label: string;
  };
  description: string;
  title: string;
}

const ErrorContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Error = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ErrorActions = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;

  > a {
    color: var(--color-primary-50);
  }
`;

const ErrorContentWrapper = styled.div`
  flex: 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
`;

const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 900px;
  padding: 32px;
  border-radius: 8px;
  color: var(--color-primary-50);
  background: rgba(15, 23, 42, 0.6);
`;

const ErrorTitle = styled.h1`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
`;

const ErrorDescription = styled.p`
  font-size: 18px;
  line-height: 1.6rem;
  font-weight: 100;
`;

export function ErrorMessage(props: ErrorProps): ReactElement {
  return (
    <ErrorContainer>
      <Error>
        <ErrorActions>
          <Button
            component={Link}
            startIcon={<ChevronLeft />}
            to={props.back?.to ?? '/'}
          >
            {props.back?.label ?? 'Dashboard'}
          </Button>
        </ErrorActions>
        <ErrorContentWrapper>
          <ErrorContent>
            <ErrorTitle>{props.title}</ErrorTitle>
            <ErrorDescription>{props.description}</ErrorDescription>
          </ErrorContent>
        </ErrorContentWrapper>
      </Error>
    </ErrorContainer>
  );
}
