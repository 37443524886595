import styled from 'styled-components';
import { glassSurface } from './GlassSurface';

export interface GridWrapperProps {
  rowSelection?: 'single' | 'multiple';
}

export const GridWrapper = styled.div<GridWrapperProps>`
  width: 100%;
  height: 600px;
  --ag-material-primary-color: rgba(16, 221, 211, 1);
  --ag-material-accent-color: rgba(181, 87, 255, 1);
  --ag-foreground-color: white;
  --ag-secondary-foreground-color: rgba(255, 255, 255, 0.72);
  --ag-background-color: transparent;
  --ag-header-background-color: transparent;
  --ag-header-cell-hover-background-color: rgba(0, 0, 0, 0.12);
  --ag-column-hover-color: rgba(255, 255, 255, 0.12);
  --ag-border-color: rgba(255, 255, 255, 0.12);

  .ag-header {
    ${glassSurface};
    --glass-border-opacity: 0%;
    border-radius: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }

  .ag-cell,
  .ag-header-cell {
    border-right: 1px solid rgba(255, 255, 255, 0.12);
    padding: 0 24px;
  }

  .ag-header-cell {
    font-size: 14px;
  }

  .ag-row {
    cursor: ${({ rowSelection }) => (rowSelection ? 'pointer;' : 'inherit')};
  }

  .ag-row-selected::before {
    ${glassSurface};
    --glass-border-opacity: 0%;
    border-radius: 0;
  }

  .ag-cell {
    font-size: 12px;
  }

  .measurement-success {
    font-weight: 600;
    color: rgba(16, 221, 211, 1);
  }

  .measurement-failure {
    font-weight: 600;
    color: rgba(255, 0, 0, 1);
  }
`;
