import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { glassSurface } from './GlassSurface';

interface AlertDialogProps {
  actions: ReactNode;
  description: string;
  onClose: () => void;
  open: boolean;
  title: string;
}

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    ${glassSurface};
  }
`;

export function AlertDialog(props: AlertDialogProps): ReactElement {
  return (
    <StyledDialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      transitionDuration={0}
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>{props.actions}</DialogActions>
    </StyledDialog>
  );
}
