import { Threshold } from '@event-horizon/graphql-api-schema';
import { Color } from 'chart.js';
import { AnnotationOptions } from 'chartjs-plugin-annotation';

export function objectiveAnnotations(
  history: {
    state: string;
    start: string;
    end: string;
  }[],
  min: number,
  max: number,
  threshold: Threshold
): AnnotationOptions[] {
  const states = history.map((h) => h.state);
  const segments = states.reduce((segments, state, index) => {
    if (state === 'FAILING') {
      if (index === 0 || state !== states[index - 1]) {
        segments.push({ start: index, end: index });
      } else {
        segments[segments.length - 1].end = index;
      }
    }
    return segments;
  }, [] as { start: number; end: number }[]);

  const objectiveAnnotions: AnnotationOptions<'line'>[] = [];

  if (threshold.__typename === 'LowerThreshold') {
    objectiveAnnotions.push({
      type: 'line',
      scaleID: 'y',
      value: threshold.lte,
      endValue: threshold.lte,
      borderColor: '#fff',
      borderWidth: 2,
      borderDash: [5, 5],
      z: -1,
    });
  } else if (threshold.__typename === 'BoundedThreshold') {
    objectiveAnnotions.push(
      {
        type: 'line',
        scaleID: 'y',
        value: threshold.lte,
        endValue: threshold.lte,
        borderColor: '#fff',
        borderWidth: 2,
        borderDash: [5, 5],
        z: -1,
      },
      {
        type: 'line',
        scaleID: 'y',
        value: threshold.gte,
        endValue: threshold.gte,
        borderColor: '#fff',
        borderWidth: 2,
        borderDash: [5, 5],
        z: -1,
      }
    );
  } else if (threshold.__typename === 'UpperThreshold') {
    objectiveAnnotions.push({
      type: 'line',
      scaleID: 'y',
      value: threshold.gte,
      endValue: threshold.gte,
      borderColor: '#fff',
      borderWidth: 2,
      borderDash: [5, 5],
      z: -1,
    });
  }

  const failureAnnotations = segments.map(
    (segment): AnnotationOptions<'box'> => ({
      drawTime: 'beforeDatasetsDraw',
      type: 'box',
      xMin: segment.start,
      xMax: segment.end,
      yMin: min - min * 0.1,
      yMax: max + max * 0.1,
      borderWidth: 0,
      borderColor: 'rgba(255, 87, 87, 0.38)',
      backgroundColor: (context): Color => {
        const gradient = context.chart.ctx.createLinearGradient(
          0,
          0,
          0,
          context.chart.canvas.height
        );
        gradient.addColorStop(0, 'rgba(255, 87, 87, 0.12)');
        gradient.addColorStop(1, 'rgba(255, 87, 87, 0.00)');
        return gradient;
      },
    })
  );

  return [...objectiveAnnotions, ...failureAnnotations];
}
