import { ErrorMessage } from '@event-horizon/app-components';
import { ReactElement } from 'react';

export function RouteNotFoundPage(): ReactElement {
  return (
    <ErrorMessage
      title="Page not found"
      description="The page you are looking for does not exist."
    />
  );
}
