import { Info } from '@event-horizon/app-components';
import { Organization } from '@event-horizon/graphql-api-schema';
import { Button, TextField } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { ReactElement, useEffect } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

export interface OrganizationFormValues {
  name: string;
  logo: string;
}

interface OrganizationFormProps {
  onDirty: (dirty: boolean) => void;
  onSubmit: (
    values: OrganizationFormValues,
    formikHelpers: FormikHelpers<OrganizationFormValues>
  ) => void | Promise<any>;
  organization?: Pick<Organization, 'id' | 'name' | 'logo'>;
}

const CreateOrganizationForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export function OrganizationForm(props: OrganizationFormProps): ReactElement {
  const validationSchema = yup.object({
    name: yup.string().required('Please provide an organization name'),
  });

  const {
    dirty,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    touched,
    values,
  } = useFormik({
    initialValues: {
      name: props.organization?.name ?? '',
      logo: props.organization?.logo ?? '',
    },
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: props.onSubmit,
  });

  useEffect(() => {
    props.onDirty(dirty);
  }, [dirty, props.onDirty]);

  return (
    <>
      <Info title="More Info">
        <p>
          Tell us about your organization. Organizations are used to group
          applications together.
        </p>
      </Info>
      <CreateOrganizationForm onSubmit={handleSubmit}>
        <TextField
          error={touched.name && Boolean(errors.name)}
          fullWidth
          helperText={touched.name && errors.name}
          label="Organization Name"
          name="name"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.name}
          variant="outlined"
        />
        <TextField
          error={touched.logo && Boolean(errors.logo)}
          fullWidth
          helperText={touched.logo && errors.logo}
          label="Organization Logo"
          name="logo"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.logo}
          variant="outlined"
        />
        <Button
          type="submit"
          size="large"
          variant="contained"
          disabled={!isValid}
        >
          Save Organization
        </Button>
      </CreateOrganizationForm>
    </>
  );
}
