import { Fragment, ReactElement } from 'react';
import styled from 'styled-components';

interface ObjectTreeProps {
  value: object;
}

const DescriptionList = styled.dl<{ level: number }>`
  font: 400 13px/14px 'Operator Mono', monospace;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 4px 8px;
  margin: ${({ level }) => (level > 0 ? '10px 0 10px 20px' : '10px 0')};
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: ${({ level }) => (level > 0 ? '-10px' : '0')};
    bottom: 0;
    width: 4px;
    background-color: rgba(95, 117, 249, 0.12);
    display: ${({ level }) => (level > 0 ? 'block' : 'none')};
  }
`;

const Term = styled.dt`
  display: flex;
  gap: 4px;
  color: #5f75f9;
`;

const Details = styled.dd<{ isObject?: boolean }>`
  display: flex;
  gap: 4px;
  grid-column: ${({ isObject }) => (isObject ? '1 / span 2' : '2')};
`;

const Number = styled.span`
  color: #ffab7b;
`;

const String = styled.span`
  color: #10ddd3;
`;

export function ObjectTree({ value }: ObjectTreeProps): ReactElement {
  const renderObject = (obj: any, level: number) => {
    if (obj === null) return 'null';
    if (obj === undefined) return 'undefined';

    if (typeof obj === 'string') {
      return <String>{obj}</String>;
    }

    if (typeof obj === 'number') {
      return <Number>{obj}</Number>;
    }

    if (typeof obj !== 'object') return obj.toString();

    if (!Array.isArray(obj) && Object.keys(obj).length === 0) return;

    return (
      <DescriptionList level={level}>
        {Array.isArray(obj)
          ? obj.map((item, index) => (
              <Fragment key={index}>
                <Term>{index}</Term>
                <Details>{renderObject(item, level + 1)}</Details>
              </Fragment>
            ))
          : Object.keys(obj).map((key) => (
              <Fragment key={key}>
                <Term>{key}:</Term>
                <Details isObject={typeof obj[key] === 'object'}>
                  {renderObject(obj[key], level + 1)}
                </Details>
              </Fragment>
            ))}
      </DescriptionList>
    );
  };

  return <>{renderObject(value, 0)}</>;
}
