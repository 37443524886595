import { ReactElement } from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  width: 16px;
  height: 16px;
`;

export function IconTypeScript(): ReactElement {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icon">
        <path
          id="Vector"
          d="M3 3H21V21H3V3ZM13.71 17.86C14.21 18.84 15.22 19.59 16.8 19.59C18.4 19.59 19.6 18.76 19.6 17.23C19.6 15.82 18.79 15.19 17.35 14.57L16.93 14.39C16.2 14.08 15.89 13.87 15.89 13.37C15.89 12.96 16.2 12.64 16.7 12.64C17.18 12.64 17.5 12.85 17.79 13.37L19.1 12.5C18.55 11.54 17.77 11.17 16.7 11.17C15.19 11.17 14.22 12.13 14.22 13.4C14.22 14.78 15.03 15.43 16.25 15.95L16.67 16.13C17.45 16.47 17.91 16.68 17.91 17.26C17.91 17.74 17.46 18.09 16.76 18.09C15.93 18.09 15.45 17.66 15.09 17.06L13.71 17.86ZM13 11.25H8V12.75H9.5V20H11.25V12.75H13V11.25Z"
          fill="white"
        />
      </g>
    </Icon>
  );
}
