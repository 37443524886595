import {
  ApplicationOutlet,
  CreateApplicationPage,
  DeleteApplicationPage,
} from '@event-horizon/app-applications';
import { AuthGuard, AuthProvider } from '@event-horizon/app-auth';
import { GlobalStyle } from '@event-horizon/app-components';
import { ApplicationDocPage } from '@event-horizon/app-docs';
import { GraphQLProvider } from '@event-horizon/app-graphql';
import {
  CreateIndicatorPage,
  IndicatorDataPage,
  IndicatorMeasurementsPage,
  IndicatorOutlet,
  IndicatorPage,
  IndicatorsPage,
  MeasurementPage,
  SuggestIndicatorsPage,
} from '@event-horizon/app-indicators';
import {
  CreateObjectivePage,
  DeleteObjectivePage,
  ObjectiveOutlet,
  ObjectivePage,
  ObjectivesPage,
} from '@event-horizon/app-objectives';
import {
  CreateOrganizationPage,
  DeleteOrganizationPage,
  OrganizationOutlet,
  OrganizationPage,
  OrganizationsPage,
  UpdateOrganizationPage,
} from '@event-horizon/app-organizations';
import { ApplicationOverviewPage } from '@event-horizon/app-overview';
import {
  CreatePaymentPage,
  PaymentReturnPage,
  PaymentsPage,
} from '@event-horizon/app-payments';
import { SettingsPage } from '@event-horizon/app-settings';
import {
  InviteUserDrawer,
  OrganizationInvitesPage,
  OrganizationUsersPage,
} from '@event-horizon/app-user';
import { PolarisProvider } from '@getpolaris.ai/sdk-react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import Layout from './components/Layout';
import { ErrorPage } from './pages/ErrorPage';
import { LoginPage } from './pages/LoginPage';
import { RouteNotFoundPage } from './pages/RouteNotFoundPage';

const container = document.getElementById('root');
const root = createRoot(container!);

const router = createBrowserRouter([
  {
    element: (
      <AuthProvider>
        <PolarisProvider
          apiKey={import.meta.env.VITE_POLARIS_API_KEY}
          apiUrl={import.meta.env.VITE_POLARIS_API_URL}
        >
          <GraphQLProvider
            url={import.meta.env.VITE_GRAPHQL_API_URL}
            isDevMode={import.meta.env.DEV}
          >
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <GlobalStyle />
              <Layout>
                <Outlet />
              </Layout>
            </LocalizationProvider>
          </GraphQLProvider>
        </PolarisProvider>
      </AuthProvider>
    ),
    children: [
      {
        element: <AuthGuard component={Outlet} />,
        errorElement: <ErrorPage />,
        children: [
          {
            children: [
              {
                path: '',
                index: true,
                element: <OrganizationsPage />,
              },
              {
                path: 'add',
                element: <CreateApplicationPage />,
              },
              {
                path: 'org',
                children: [
                  {
                    path: 'add',
                    element: <CreateOrganizationPage />,
                  },
                  {
                    path: ':orgId',
                    element: <OrganizationOutlet />,
                    children: [
                      {
                        path: '',
                        element: <OrganizationPage />,
                        children: [
                          {
                            path: 'edit',
                            element: <UpdateOrganizationPage />,
                          },
                          {
                            path: 'invites',
                            element: <OrganizationInvitesPage />,
                            children: [
                              {
                                path: 'add',
                                element: <InviteUserDrawer />,
                              },
                            ],
                          },
                          {
                            path: 'users',
                            element: <OrganizationUsersPage />,
                            children: [
                              {
                                path: 'add',
                                element: <InviteUserDrawer />,
                              },
                            ],
                          },
                          {
                            path: 'delete',
                            element: <DeleteOrganizationPage />,
                          },
                        ],
                      },
                      {
                        path: 'app',
                        children: [
                          {
                            path: ':appId',
                            element: <ApplicationOutlet />,
                            children: [
                              {
                                path: '',
                                index: true,
                                element: <ApplicationOverviewPage />,
                              },
                              {
                                path: 'delete',
                                element: <DeleteApplicationPage />,
                              },
                              {
                                path: 'getting-started',
                                element: <ApplicationDocPage />,
                              },
                              {
                                path: 'overview',
                                element: <ApplicationOverviewPage />,
                              },
                              {
                                path: 'settings',
                                element: <SettingsPage />,
                              },
                              {
                                path: 'payments',
                                element: <PaymentsPage />,
                                children: [
                                  {
                                    path: 'add',
                                    element: <CreatePaymentPage />,
                                  },
                                  {
                                    path: 'return',
                                    element: <PaymentReturnPage />,
                                  },
                                ],
                              },
                              {
                                path: 'indicators',
                                element: <IndicatorsPage />,
                                children: [
                                  {
                                    path: 'add',
                                    element: <CreateIndicatorPage />,
                                  },
                                  {
                                    path: 'suggest',
                                    element: <SuggestIndicatorsPage />,
                                  },
                                ],
                              },
                              {
                                path: 'indicator',
                                children: [
                                  {
                                    path: ':indicatorId',
                                    element: <IndicatorOutlet />,
                                    children: [
                                      {
                                        path: '',
                                        element: <IndicatorPage />,
                                        children: [
                                          {
                                            path: '',
                                            index: true,
                                            element: <IndicatorDataPage />,
                                          },
                                          {
                                            path: 'measurements',
                                            element: (
                                              <IndicatorMeasurementsPage />
                                            ),
                                            children: [
                                              {
                                                path: ':measurementId',
                                                element: <MeasurementPage />,
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                path: 'objectives',
                                children: [
                                  {
                                    path: '',
                                    element: <ObjectivesPage />,
                                    children: [
                                      {
                                        path: 'add',
                                        element: <CreateObjectivePage />,
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                path: 'objective',
                                children: [
                                  {
                                    path: ':objectiveId',
                                    element: <ObjectiveOutlet />,
                                    children: [
                                      {
                                        path: '',
                                        element: <ObjectivePage />,
                                        children: [
                                          {
                                            path: 'delete',
                                            element: <DeleteObjectivePage />,
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: '*',
        element: <RouteNotFoundPage />,
      },
    ],
  },
]);

root.render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
);
