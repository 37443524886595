import {
  Button,
  Input,
  PageContainer,
  Snackbar,
} from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { UpdateOrganizationInput } from '@event-horizon/graphql-api-schema';
import { FormikHelpers, useFormik } from 'formik';
import { ReactElement, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation } from 'urql';
import * as yup from 'yup';
import { useOrganization } from './OrganizationOutlet';

const UpdateOrganization = graphql(`
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      id
    }
  }
`);

interface FormValues {
  name: string;
  logo: string;
}

const Settings = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Setting = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
`;

const Heading = styled.h2`
  color: rgba(255, 255, 255, 0.64);
  font-size: 11px;
`;

const Footer = styled.div`
  padding: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
`;

export function UpdateOrganizationPage(): ReactElement {
  const { organization } = useOrganization();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [, updateOrganization] = useMutation(UpdateOrganization);

  const validationSchema = yup.object({
    name: yup.string().required('Please provide an organization name'),
  });

  const handleOnClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleDelete = useCallback(async () => {
    navigate(`/org/${organization.id}/delete`);
  }, [navigate, organization.id]);

  const handleOnSubmit = useCallback(
    async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const input: UpdateOrganizationInput = {
        ...values,
        organizationId: organization.id,
      };
      const result = await updateOrganization({ input });
      if (result.error) {
        formikHelpers.setSubmitting(false);
        formikHelpers.setErrors(result.error);
        return;
      }
      setOpen(true);
    },
    []
  );

  const {
    dirty,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    touched,
    values,
  } = useFormik({
    initialValues: {
      name: organization?.name ?? '',
      logo: organization?.logo ?? '',
    },
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: handleOnSubmit,
  });

  return (
    <PageContainer>
      <form onSubmit={handleSubmit}>
        <Settings>
          <Setting>
            <Heading>Organization</Heading>
            <Input
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              label="Name"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
            />
            <Input
              error={touched.logo && Boolean(errors.logo)}
              helperText={touched.logo && errors.logo}
              label="Logo URL"
              name="logo"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.logo}
            />
          </Setting>
        </Settings>
        <Footer>
          <Button type="button" color="warn" onClick={handleDelete}>
            Delete Organization
          </Button>
          <Button type="submit" disabled={!isValid} raised>
            Save Organization
          </Button>
        </Footer>
      </form>
      <Snackbar
        open={open}
        onClose={handleOnClose}
        message="Organization updated."
      />
    </PageContainer>
  );
}
