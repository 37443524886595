import { Button, Input } from '@event-horizon/app-components';
import { FormikHelpers, useFormik } from 'formik';
import { ReactElement } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

export interface PaymentAmountFormValues {
  amount?: number;
}

interface PaymentAmountFormProps {
  app: {
    measurementBudget: number;
  };
  onSubmit: (
    values: PaymentAmountFormValues,
    formikHelpers: FormikHelpers<PaymentAmountFormValues>
  ) => void | Promise<any>;
}

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
`;

const Credits = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  row-gap: 24px;
  column-gap: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  padding: 24px 24px;

  > p {
    &:nth-child(even) {
      justify-self: flex-end;
    }

    > strong {
      font-weight: 500;
    }
  }
`;

const Spacer = styled.div`
  flex: 1 auto;
`;

const Actions = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 24px;
`;

export function PaymentAmountForm({
  app,
  onSubmit,
}: PaymentAmountFormProps): ReactElement {
  const validationSchema = yup.object({
    amount: yup.number().positive().integer().required('Amount is required'),
  });

  const {
    isValid,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
  } = useFormik<PaymentAmountFormValues>({
    initialValues: {
      amount: undefined,
    },
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Form onSubmit={handleSubmit}>
      <FormBody>
        <Input
          error={touched.amount && Boolean(errors.amount)}
          fullWidth
          helperText={touched.amount && errors.amount}
          label="Amount (USD)"
          name="amount"
          onBlur={handleBlur}
          onChange={handleChange}
          type="number"
          value={values.amount}
          variant="outlined"
        />
      </FormBody>
      <Credits>
        <p>Credit Balance</p>
        <p>
          {new Intl.NumberFormat('en-us', {
            maximumFractionDigits: 0,
          }).format(app.measurementBudget)}
        </p>
        <p>Add Credit</p>
        <p>
          {values.amount
            ? new Intl.NumberFormat('en-us', {
                maximumFractionDigits: 0,
              }).format(values.amount * 1_000)
            : null}
        </p>
        <p>
          <strong>Total</strong>
        </p>
        <p>
          <strong>
            {values.amount
              ? new Intl.NumberFormat('en-us', {
                  maximumFractionDigits: 0,
                }).format(values.amount * 1_000 + app.measurementBudget)
              : null}
          </strong>
        </p>
      </Credits>
      <Spacer />
      <Actions>
        <Button color="primary" disabled={!isValid} raised type="submit">
          Continue
        </Button>
      </Actions>
    </Form>
  );
}
