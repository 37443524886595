import {
  ConnectionOptions,
  createConnection,
  measure,
  getInstrument,
  logMeasurement,
} from '@getpolaris.ai/sdk';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { SdkContext } from './context';
import Profiler from '../profiler/profiler';

interface ProviderProps {
  apiKey: string;
  apiUrl?: string;
  children: ReactNode;
}

export function Provider({
  apiKey,
  apiUrl,
  children,
}: ProviderProps): ReactElement {
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    const options: ConnectionOptions = { apiKey };
    if (apiUrl) {
      options.apiUrl = apiUrl;
    }
    createConnection(options)
      .then(() => setConnected(true))
      .catch(() => setConnected(false))
      .catch(console.error);
  }, [apiKey, apiUrl]);

  const contextValue = {
    measure,
    getInstrument,
    logMeasurement,
  };

  return (
    <SdkContext.Provider value={contextValue}>
      <Profiler connected={connected} id="app">
        {children}
      </Profiler>
    </SdkContext.Provider>
  );
}
