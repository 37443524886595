import {
  Button,
  CodeEditor,
  IndicatorOperation,
  IndicatorWindow,
  Input,
} from '@event-horizon/app-components';
import {
  Indicator,
  IndicatorWindow as IndicatorWindows,
} from '@event-horizon/graphql-api-schema';
import { Help } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { ReactElement, useEffect } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

export interface IndicatorFormValues {
  name: string;
  window: string;
  operation: string;
  predicateFunction: string;
}

interface IndicatorFormProps {
  indicator?: Pick<
    Indicator,
    'id' | 'name' | 'operation' | 'predicateFunction' | 'window'
  >;
  onClose: () => void;
  onDelete?: () => void;
  onDirty: (dirty: boolean) => void;
  onSubmit: (
    values: IndicatorFormValues,
    formikHelpers: FormikHelpers<IndicatorFormValues>
  ) => void | Promise<any>;
}

const Info = styled.div`
  padding: 24px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 24px;
  color: rgba(255, 255, 255, 0.72);
  font: 400 13px/16px 'Lexend', sans-serif;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);

  > a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.72);
  }
`;

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
`;

const Anchor = styled.a`
  display: flex;
  align-items: center;
`;

const HelpIcon = styled(Help)`
  height: 16px !important;
  width: 16px !important;
`;

const Spacer = styled.div`
  flex: 1 auto;
`;

const Actions = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 24px;
`;

export function IndicatorForm(props: IndicatorFormProps): ReactElement {
  const validationSchema = yup.object({
    name: yup.string().required('Please provide a name for this indicator.'),
    window: yup.string().required('Please select a window for this indicator.'),
    operation: yup.string().required('Please select an operation.'),
    predicateFunction: yup
      .string()
      .required('Please provide a predicate function.'),
  });

  const {
    dirty,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    touched,
    values,
  } = useFormik<IndicatorFormValues>({
    initialValues: {
      name: props.indicator?.name ?? '',
      window: props.indicator?.window ?? IndicatorWindows.LAST_DAY,
      operation: props.indicator?.operation ?? '',
      predicateFunction: props.indicator?.predicateFunction ?? '',
    },
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: props.onSubmit,
  });

  useEffect(() => {
    props.onDirty(dirty);
  }, [dirty, props.onDirty]);

  return (
    <>
      <Info>
        <Tooltip title="Learn more about indicators" enterDelay={350}>
          <a
            href="https://getpolaris.ai/docs/react/guides/indicators/"
            target="_blank"
            rel="noreferrer"
          >
            <Help />
          </a>
        </Tooltip>
        <p>
          An Indicator is a metric that shows how well your app is meeting
          performance goals.
        </p>
      </Info>
      <Form onSubmit={handleSubmit}>
        <FormBody>
          <Input
            error={touched.name && Boolean(errors.name)}
            fullWidth
            helperText={
              touched.name && errors.name
                ? errors.name
                : 'TIP: use "/" to group indicators'
            }
            label="Indicator name"
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
          />
          <IndicatorWindow
            errors={errors.window}
            name="window"
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched.window}
            value={values.window}
          />
          <IndicatorOperation
            errors={errors.operation}
            name="operation"
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched.operation}
            value={values.operation}
          />
          <CodeEditor
            name="predicateFunction"
            value={values.predicateFunction}
            onChange={handleChange}
            title={
              <>
                <span>Predicate Function</span>
                <Anchor
                  href="https://getpolaris.ai/docs/typescript/guides/indicators/#predicate-function"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Tooltip title="Learn more about the predicate function">
                    <HelpIcon />
                  </Tooltip>
                </Anchor>
              </>
            }
          />
        </FormBody>
        <Spacer />
        <Actions>
          {props.indicator && props.onDelete && (
            <Button type="button" onClick={props.onDelete} color="warn">
              Delete Indicator
            </Button>
          )}
          <Button type="button" onClick={props.onClose}>
            Cancel
          </Button>
          <Button type="submit" disabled={!isValid} raised>
            Save Indicator
          </Button>
        </Actions>
      </Form>
    </>
  );
}
