import { Sdk } from '@event-horizon/graphql-api-schema';
import { ReactElement } from 'react';
import styled from 'styled-components';
import { Sdks } from './Sdks';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  > h2 {
    background: radial-gradient(
      16413.7% 106.51% at 67.55% 0%,
      #fff 0%,
      rgba(255, 255, 255, 0.6) 100%
    );
    background-clip: text;
    text-transform: unset;
    font: normal normal 700 24px/2rem Lexend;
  }

  > ul {
    list-style: none;
    align-self: center;
    width: 100%;
    max-width: 480px;
    margin: 48px auto;
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding: 0;

    > li {
      display: flex;
      justify-content: center;

      > button {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
      }

      svg {
        height: 64px !important;
        width: 64px !important;
      }
    }
  }

  @media (min-width: 480px) {
    > ul {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

interface TechnologyListProps {
  onClick: (sdk: Sdk) => void;
}

export function TechnologyList({ onClick }: TechnologyListProps): ReactElement {
  return (
    <Container>
      <h2>What technology are you using?</h2>
      <ul>
        {Object.keys(Sdks).map((sdk, i) => (
          <li key={i}>
            <button onClick={() => onClick(sdk as Sdk)}>
              {Sdks[sdk as Sdk].icon}
              <span>{Sdks[sdk as Sdk].title}</span>
            </button>
          </li>
        ))}
      </ul>
    </Container>
  );
}
