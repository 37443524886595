import {
  Button,
  glassSurface,
  Percentile,
  StatusPillBox,
} from '@event-horizon/app-components';
import { Objective, ObjectiveState } from '@event-horizon/graphql-api-schema';
import { DateTime } from 'luxon';
import { createSearchParams, Link } from 'react-router-dom';
import styled from 'styled-components';

export interface ObjectiveCardViewModel {
  id: Objective['id'];
  name: Objective['name'];
  percentile: Objective['percentile'];
  state: Objective['state'];
  threshold: Objective['threshold'];
  history: Record<string, ObjectiveState>;
}

interface ObjectiveCardProps {
  objective: ObjectiveCardViewModel;
  start: DateTime;
  end: DateTime;
}

interface CardProps {
  state: ObjectiveState;
}

const Card = styled.div<CardProps>`
  ${glassSurface};
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px;
  position: relative;

  &:before {
    content: '';
    border-radius: 512px;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.04;
    filter: blur(24px);

    ${({ state }) => {
      switch (state) {
        case ObjectiveState.FAILING:
          return `
          background-color: #FF0000;
        `;
        case ObjectiveState.PASSING:
          return `
          background-color: #10DDD3;
        `;
        case ObjectiveState.UNKNOWN:
          return `
          background-color: #FFF;
        `;
      }
    }};
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 8px 0;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.h3`
  color: rgba(255, 255, 255, 0.72);
  font-family: Lexend, sans-serif;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`;

const Description = styled.p`
  color: #fff;
  font-family: Lexend, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const Chips = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

const Chip = styled.div`
  ${glassSurface};
  --glass-border-radius: 2px;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  color: #fff;
  font-family: Lexend, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const StatusChip = styled(Chip)<CardProps>`
  ${({ state }) => {
    switch (state) {
      case ObjectiveState.FAILING:
        return `
          --glass-background-color-a: rgba(255,0,0,0.12);
          --glass-background-color-b: rgba(255,0,0,0.2);
          --glass-background-color-c: rgba(255,0,0,0.08);
        `;
      case ObjectiveState.PASSING:
        return `
          --glass-background-color-a: rgba(16,221,211,0.12);
          --glass-background-color-b: rgba(16,221,211,0.2);
          --glass-background-color-c: rgba(16,221,211,0.08);
        `;
      case ObjectiveState.UNKNOWN:
        return `
          --glass-background-color-a: rgba(255,255,255,0.12);
          --glass-background-color-b: rgba(255,255,255,0.2);
          --glass-background-color-c: rgba(255,255,255,0.08);
        `;
    }
  }};
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 0;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
`;

function ObjectiveDescription({
  objective,
}: {
  objective: Pick<Objective, 'threshold'>;
}) {
  switch (objective.threshold.__typename) {
    case 'LowerThreshold':
      return `Below ${objective.threshold.lte}`;
    case 'BoundedThreshold':
      return `Between ${objective.threshold.gte} and ${objective.threshold.lte}`;
    case 'UpperThreshold':
      return `Above ${objective.threshold.gte}`;
    default:
      return '';
  }
}

export function ObjectiveCard({ objective, start, end }: ObjectiveCardProps) {
  const params = createSearchParams({
    start: start.toISODate()!,
    end: end.toISODate()!,
  });
  return (
    <Card state={objective.state}>
      <Row>
        <Title>
          <Header>{objective.name}</Header>
          <Description>
            <ObjectiveDescription objective={objective} />
          </Description>
        </Title>
        <Chips>
          {objective.percentile && (
            <Chip>
              <Percentile percentile={objective.percentile} />
            </Chip>
          )}
          <StatusChip state={objective.state}>{objective.state}</StatusChip>
        </Chips>
      </Row>
      <Row>
        <StatusPillBox history={objective.history} />
      </Row>
      <Footer>
        <Button
          as={Link}
          to={`../objective/${objective.id}?${params.toString()}`}
        >
          Explore
        </Button>
      </Footer>
    </Card>
  );
}
