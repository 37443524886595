import { Error } from '@mui/icons-material';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

interface DangerProps {
  children: ReactNode;
  title: string;
}

const DangerContainer = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 16px;
  margin: 16px 0;
  padding: 16px;
  border: 1px solid var(--color-warn-50);
  border-radius: 8px;
  background: var(--color-warn-400);
`;

const DangerIcon = styled(Error)`
  color: var(--color-warn-50);
`;

const DangerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const DangerTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: var(--color-neutral-50);
`;

const DangerChildren = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 15px;
  font-weight: 300;
  color: var(--color-slate-50);
`;

export function Danger(props: DangerProps): ReactElement {
  return (
    <DangerContainer>
      <DangerIcon />
      <DangerContent>
        <DangerTitle>{props.title}</DangerTitle>
        <DangerChildren>{props.children}</DangerChildren>
      </DangerContent>
    </DangerContainer>
  );
}
