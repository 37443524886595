import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const StyledNav = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 0 12px;

  a {
    color: rgba(255, 255, 255, 0.72);
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    transition: color 0.2s ease-in-out;
  }

  a.active,
  a:hover {
    color: #10ddd3;
  }
`;

export function IndicatorNav() {
  return (
    <StyledNav>
      <NavLink to="." end>
        Data
      </NavLink>
      <NavLink to="./measurements">Measurements</NavLink>
    </StyledNav>
  );
}
