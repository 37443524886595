import {
  Button,
  Input,
  Option,
  RadioGroup,
  Sdks,
} from '@event-horizon/app-components';
import { Sdk } from '@event-horizon/graphql-api-schema';
import { FormikHelpers, useFormik } from 'formik';
import { ReactElement } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

export interface SignUpFormValues {
  appName: string;
  orgName: string;
  sdk: Sdk;
  userName: string;
}

interface SignUpFormProps {
  onSubmit: (
    values: SignUpFormValues,
    formikHelpers: FormikHelpers<SignUpFormValues>
  ) => void | Promise<any>;
}

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export function SignUpForm(props: SignUpFormProps): ReactElement {
  const validationSchema = yup.object({
    appName: yup.string().required('Please provide an app name'),
    orgName: yup.string().required('Please provide an organization name'),
    sdk: yup.string().required('Please select a SDK'),
    userName: yup.string().required('Please provide your name'),
  });

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    touched,
    values,
  } = useFormik({
    initialValues: {
      appName: '',
      orgName: '',
      userName: '',
      sdk: Sdk.TYPESCRIPT,
    },
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: props.onSubmit,
  });

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        error={touched.userName && Boolean(errors.userName)}
        fullWidth
        helperText={touched.userName && errors.userName}
        label="Your name"
        name="userName"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.userName}
        variant="outlined"
      />
      <Input
        error={touched.orgName && Boolean(errors.orgName)}
        fullWidth
        helperText={touched.orgName && errors.orgName}
        label="Organization name"
        name="orgName"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.orgName}
        variant="outlined"
      />
      <Input
        error={touched.appName && Boolean(errors.appName)}
        fullWidth
        helperText={touched.appName && errors.appName}
        label="App name"
        name="appName"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.appName}
        variant="outlined"
      />
      <RadioGroup
        name="sdk"
        value={values.sdk}
        onBlur={handleBlur}
        onChange={handleChange}
      >
        {Object.keys(Sdk).map((sdk) => (
          <Option
            key={sdk}
            icon={Sdks[sdk as Sdk].icon}
            label={Sdks[sdk as Sdk].title}
            value={sdk}
          />
        ))}
      </RadioGroup>
      <Button type="submit" disabled={!isValid} raised>
        Sign Up
      </Button>
    </Form>
  );
}
