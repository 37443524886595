import { MeasurementResult } from '@event-horizon/graphql-api-schema';
import { getApiKey } from './config/api-key';
import { getConnectionQuality } from './metadata/connection-quality';
import { getDeviceType } from './metadata/device-type';
import { sendMeasurement } from './send-measurement';

export function logMeasurement(
  eventName: string,
  result: MeasurementResult,
  duration: number,
  url: string | null = null,
  measurementMetadata: object = {},
  resultMetadata: object = {},
  pluginMetadata: object = {}
): void {
  sendMeasurement({
    result,
    apiKey: getApiKey(),
    name: eventName,
    duration,
    timestamp: new Date().toISOString(),
    url,
    metadata: {
      connectionQuality: getConnectionQuality(),
      deviceType: getDeviceType(),
      userMetadata: {
        ...measurementMetadata,
        ...resultMetadata,
      },
      pluginMetadata,
    },
  });
}
