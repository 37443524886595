let apiKey: string | undefined;

export function setApiKey(key: string): void {
  apiKey = key;
}

export function getApiKey(): string {
  if (apiKey === undefined) {
    throw new Error('API key is not set');
  }

  return apiKey;
}

export function removeApiKey(): void {
  apiKey = undefined;
}
