import { Button, Danger } from '@event-horizon/app-components';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { FormEvent, ReactElement, useCallback, useState } from 'react';
import styled from 'styled-components';

interface PaymentFormProps {
  amount: number;
  app: {
    id: string;
  };
  organization: {
    id: string;
  };
}

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
`;

const Spacer = styled.div`
  flex: 1 auto;
`;

const Actions = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 24px;
`;

export function PaymentForm({
  amount,
  app,
  organization,
}: PaymentFormProps): ReactElement {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      if (!stripe || !elements) {
        return;
      }
      setIsLoading(true);
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/org/${organization.id}/app/${app.id}/payments/return`,
        },
      });
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message);
      } else {
        setMessage('An unexpected error occurred.');
      }
      setIsLoading(false);
    },
    [elements, stripe]
  );

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormBody>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        {message && <Danger title="Error">{message}</Danger>}
      </FormBody>
      <Spacer />
      <Actions>
        <Button
          color="primary"
          disabled={isLoading || !stripe || !elements}
          raised
          type="submit"
        >
          <span>{isLoading ? 'Processing' : 'Pay now'}</span>
        </Button>
      </Actions>
    </Form>
  );
}
