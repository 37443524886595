import { MeasurementResult } from '@event-horizon/graphql-api-schema';
import { getApiKey } from './config/api-key';
import { sendMeasurement } from './send-measurement';
import { getConnectionQuality } from './metadata/connection-quality';
import { getDeviceType } from './metadata/device-type';

export const ɵattachPluginMetadata = Symbol('attachPluginMetadata');

export class Instrument {
  private readonly connectionQuality = getConnectionQuality();
  private readonly deviceType = getDeviceType();
  private readonly created = Date.now();
  private pluginMetadata?: object;
  private result: MeasurementResult | null = null;

  constructor(
    private readonly eventName: string,
    private readonly userMeasurementMetadata: object = {},
    private readonly url: string | null = null
  ) {}

  private sendMeasurement(
    result: MeasurementResult,
    resultMetadata: object = {}
  ) {
    if (this.result) {
      console.warn(
        '@getpolaris.ai/sdk: Instrument already sent measurement',
        this.eventName
      );
      return;
    }

    this.result = result;
    sendMeasurement({
      result,
      apiKey: getApiKey(),
      name: this.eventName,
      duration: Date.now() - this.created,
      timestamp: new Date().toISOString(),
      url: this.url,
      metadata: {
        connectionQuality: this.connectionQuality,
        deviceType: this.deviceType,
        userMetadata: {
          ...this.userMeasurementMetadata,
          ...resultMetadata,
        },
        pluginMetadata: this.pluginMetadata,
      },
    });
  }

  get isDone(): boolean {
    return !!this.result;
  }

  done(resultMetadata: object = {}) {
    this.sendMeasurement(MeasurementResult.SUCCESS, resultMetadata);
  }

  fail(resultMetadata: object = {}) {
    this.sendMeasurement(MeasurementResult.FAILURE, resultMetadata);
  }

  [ɵattachPluginMetadata](pluginMetadata: object) {
    this.pluginMetadata = pluginMetadata;
  }
}

export function measure(
  eventName: string,
  measurementMetadata: object = {},
  url: string | null = null
) {
  return new Instrument(eventName, measurementMetadata, url);
}
