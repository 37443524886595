import { glassSurface } from '@event-horizon/app-components';
import { Aggregation } from '@event-horizon/graphql-api-schema';
import { Popper } from '@mui/material';
import { axisBottom, scaleBand, scaleLinear, select } from 'd3';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import useResizeObserver from 'use-resize-observer';

const BLEED_TOP = 56;
const BLEED_BOTTOM = 24;
const BLEED_LEFT = 48;
const BLEED_RIGHT = 48;

const rectAnimationProps: React.ComponentProps<typeof motion.rect> = {
  transition: {
    duration: 1,
    ease: 'easeInOut',
  },
  initial: 'hidden',
  animate: 'visible',
};

const fadeInAnimationProps = {
  transition: {
    duration: 1,
    ease: 'easeInOut',
  },
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};

const formatCount = (value: number) =>
  value.toLocaleString('en', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 88px;
  height: 100%;
`;

const XAxisGroup = styled.g`
  line,
  path {
    stroke: rgba(255, 255, 255, 0.08);
  }

  text {
    color: rgba(255, 255, 255, 0.32);
  }
`;

const MetricsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 64px;
  border-top: 1px solid rgba(255, 255, 255, 0.12);

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;

    .value {
      color: #fff;
      font: normal normal normal 20px/24px Lexend;
    }

    .label {
      color: #b557ff;
      font: normal normal normal 10px/12px Lexend;
      text-transform: uppercase;
    }
  }
`;

const Tooltip = styled(motion.div)`
  background: rgba(0, 0, 0, 0.43);
  backdrop-filter: blur(4.4px);
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  padding: 8px;
  transform: translate(0, 50%);
  pointer-events: none;

  .value {
    color: #fff;
    font: normal normal normal 14px/17px Lexend;
  }

  .label {
    color: rgba(255, 255, 255, 0.64);
    font: normal normal 500 11px/13px Lexend;
  }
`;

export interface ConsumptionHistoryChartViewModel {
  start: DateTime;
  end: DateTime;
  measurements: number;
}

interface ConsumptionHistoryChartProps {
  aggregation: Aggregation;
  data: ConsumptionHistoryChartViewModel[];
}

export function ConsumptionHistoryChart({
  aggregation,
  data,
}: ConsumptionHistoryChartProps): ReactElement {
  const containerRef = useRef<HTMLDivElement>(null);
  const xAxisScaleRef = useRef<SVGGElement>(null);
  const [tooltip, setTooltip] = useState<null | {
    anchorEl: SVGGElement;
    label: string;
    value: string;
  }>(null);
  const { width = 0, height: containerHeight = 0 } =
    useResizeObserver<HTMLDivElement>({
      ref: containerRef,
    });
  const height = containerHeight - 88;

  const xAxis = useMemo(() => {
    const values = data
      .map((d) => d.start)
      .filter((start): start is DateTime<true> => DateTime.isDateTime(start))
      .map((start) => start.toISO());

    return scaleBand()
      .domain(values)
      .range([BLEED_LEFT, width - BLEED_RIGHT])
      .padding(0.2);
  }, [data, width]);

  const yAxis = useMemo(() => {
    const values = data.map((d) => d.measurements);
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);

    return scaleLinear()
      .domain([minValue, maxValue])
      .range([height - BLEED_TOP, BLEED_BOTTOM]);
  }, [data, height]);

  const yTicks = useMemo(() => {
    return yAxis.ticks(5);
  }, [yAxis]);

  const xAxisScale = useMemo(() => {
    const tickValues = xAxis.domain();

    return axisBottom(xAxis)
      .tickValues(
        aggregation === Aggregation.BY_DAY
          ? tickValues.filter((_, index) => index % 2 === 0)
          : tickValues.filter((_, index) => index % 4 === 0)
      )
      .tickFormat((d) =>
        DateTime.fromISO(d).toLocaleString(
          aggregation === Aggregation.BY_DAY
            ? { month: 'short', day: 'numeric' }
            : { hour: 'numeric' }
        )
      );
  }, [aggregation, xAxis]);

  const totalValue = useMemo(() => {
    return data.reduce((acc, d) => acc + d.measurements, 0);
  }, [data]);

  const averageValue = useMemo(() => {
    return data.reduce((acc, d) => acc + d.measurements, 0) / data.length;
  }, [data]);

  const medianValue = useMemo(() => {
    const values = data.map((d) => d.measurements).sort();
    const middle = Math.floor(values.length / 2);

    if (values.length % 2 === 0) {
      return (values[middle - 1] + values[middle]) / 2;
    }

    return values[middle];
  }, [data]);

  const minimumValue = useMemo(() => {
    return Math.min(...data.map((d) => d.measurements));
  }, [data]);

  const maximumValue = useMemo(() => {
    return Math.max(...data.map((d) => d.measurements));
  }, [data]);

  useEffect(() => {
    if (xAxisScaleRef.current) {
      xAxisScaleRef.current
        .querySelectorAll('*')
        .forEach((node) => node.remove());
      xAxisScale(select(xAxisScaleRef.current));
      select(xAxisScaleRef.current)
        .selectAll('text')
        .attr('transform', 'rotate(-90)')
        .attr('dy', '-0.5em')
        .attr('dx', '-1em')
        .style('text-anchor', 'end');
    }
  }, [xAxisScale]);

  if (width === 0 || height === 0) {
    return <Container ref={containerRef} />;
  }

  return (
    <Container ref={containerRef}>
      <motion.svg width={width} height={height}>
        <defs>
          <radialGradient
            id="consumption_history_chart_radial_gradient"
            cx={BLEED_LEFT}
            cy={height}
            r={Math.max(width, height)}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#10DDD3" />
            <stop offset="75%" stopColor="#5F75F9" />
            <stop offset="100%" stopColor="#B557FF" />
          </radialGradient>
          <clipPath id="consumption_history_chart_inner_clip">
            <rect
              x={BLEED_LEFT}
              y={0}
              width={width}
              height={BLEED_TOP + height}
            />
          </clipPath>
        </defs>
        {data.map((d, i) => (
          <motion.rect
            key={i}
            x={xAxis(d.start.toISO()!)}
            width={xAxis.bandwidth()}
            fill="url(#consumption_history_chart_radial_gradient)"
            fillOpacity="0.24"
            clipPath="url(#consumption_history_chart_inner_clip)"
            variants={{
              hidden: {
                opacity: 0,
                y: height - BLEED_TOP,
                height: 0,
              },
              visible: {
                opacity: 1,
                y: yAxis(d.measurements),
                height: height - BLEED_TOP - yAxis(d.measurements),
              },
            }}
            onMouseEnter={(e) => {
              setTooltip({
                anchorEl: e.currentTarget,
                label: DateTime.fromISO(d.start.toISO()!).toLocaleString(
                  aggregation === Aggregation.BY_DAY
                    ? { month: 'short', day: 'numeric' }
                    : { hour: 'numeric' }
                ),
                value: d.measurements.toString(),
              });
            }}
            onMouseLeave={() => {
              setTooltip(null);
            }}
            {...rectAnimationProps}
          />
        ))}
        {yTicks.map((tick, index) => {
          const y = yAxis(tick) ?? 0;

          if (index === 0) {
            return null;
          }

          return (
            <motion.g key={index} transform={`translate(0 ${y})`}>
              <line
                x1={0}
                x2={width}
                stroke="#fff"
                strokeOpacity={0.08}
                strokeWidth="1"
              />
              <text
                x="24"
                y="20"
                fill="#fff"
                fontSize="12"
                dominantBaseline="middle"
                opacity={0.32}
                fontWeight={500}
                fontFamily="Lexend"
              >
                {tick}
              </text>
            </motion.g>
          );
        })}
        <XAxisGroup
          ref={xAxisScaleRef}
          transform={`translate(0, ${height - BLEED_TOP})`}
        />
      </motion.svg>
      <MetricsContainer>
        <motion.div {...fadeInAnimationProps}>
          <div className="value">{formatCount(totalValue)}</div>
          <div className="label">Total</div>
        </motion.div>
        <motion.div {...fadeInAnimationProps}>
          <div className="value">{formatCount(averageValue)}</div>
          <div className="label">Average</div>
        </motion.div>
        <motion.div {...fadeInAnimationProps}>
          <div className="value">{formatCount(medianValue)}</div>
          <div className="label">Median</div>
        </motion.div>
        <motion.div {...fadeInAnimationProps}>
          <div className="value">{formatCount(minimumValue)}</div>
          <div className="label">Minimum</div>
        </motion.div>
        <motion.div {...fadeInAnimationProps}>
          <div className="value">{formatCount(maximumValue)}</div>
          <div className="label">Maximum</div>
        </motion.div>
      </MetricsContainer>
      <Popper
        sx={{ pointerEvents: 'none' }}
        open={!!tooltip?.anchorEl}
        anchorEl={tooltip?.anchorEl}
        placement="top"
      >
        <Tooltip
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <div className="value">{tooltip?.value}</div>
          <div className="label">{tooltip?.label}</div>
        </Tooltip>
      </Popper>
    </Container>
  );
}
