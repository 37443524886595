import { TextField } from '@mui/material';
import styled from 'styled-components';
import { glassSurface } from './GlassSurface';

export const Input = styled(TextField)`
  .MuiInputBase-root {
    ${glassSurface};
    border-radius: 4px !important;
  }

  label {
    color: rgba(255, 255, 255, 0.64);
    font: 400 11px/13px Lexend, sans-serif;
    top: 4px;
  }

  fieldset {
    border: none;
  }

  svg {
    opacity: 0.56;
  }
`;
