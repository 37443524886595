import { ObjectiveState } from '@event-horizon/graphql-api-schema';
import styled from 'styled-components';

interface StatusPillBoxProps {
  history: Record<string, ObjectiveState>;
}

interface ContainerProps {
  history: StatusPillBoxProps['history'];
}

interface StatusPillProps {
  state: ObjectiveState;
}

const Container = styled.div<ContainerProps>`
  flex: 1 auto;
  display: grid;
  gap: 2px;
  grid-template-columns: ${({ history }) =>
    `repeat(${Object.keys(history).length}, 1fr);`});
`;

const StatusPill = styled.div<StatusPillProps>`
  height: 4px;
  width: 100%;
  border-radius: 1px;

  background: ${({ state }) => {
    switch (state) {
      case ObjectiveState.FAILING:
        return '#FF0000';
      case ObjectiveState.PASSING:
        return '#10DDD3';
      case ObjectiveState.UNKNOWN:
        return '#858585';
    }
  }};
`;

export function StatusPillBox({ history }: StatusPillBoxProps) {
  return (
    <Container history={history}>
      {Object.keys(history).map((key) => (
        <StatusPill key={key} state={history[key]} />
      ))}
    </Container>
  );
}
