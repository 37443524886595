import { AlertDialog, Drawer } from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { CreateOrganizationInput } from '@event-horizon/graphql-api-schema';
import { Button } from '@mui/material';
import { FormikHelpers } from 'formik';
import { ReactElement, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation } from 'urql';
import { OrganizationForm, OrganizationFormValues } from './OrganizationForm';

const CreateOrganization = graphql(`
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      id
    }
  }
`);

const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

export function CreateOrganizationPage(): ReactElement {
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [, createOrganization] = useMutation(CreateOrganization);

  const close = useCallback(() => {
    setDrawerOpen(false);
    navigate(-1);
  }, [navigate, setDrawerOpen]);

  const handleOnClose = useCallback(() => {
    if (dirty) {
      setAlertOpen(true);
      return;
    }
    close();
  }, [close, dirty, setAlertOpen]);

  const handleOnSubmit = useCallback(
    async (
      values: OrganizationFormValues,
      formikHelpers: FormikHelpers<OrganizationFormValues>
    ) => {
      const input: CreateOrganizationInput = values;
      const result = await createOrganization({ input });
      if (result.error) {
        formikHelpers.setSubmitting(false);
        formikHelpers.setErrors(result.error);
        return;
      }
      if (result.data?.createOrganization.id) {
        navigate(`/org/${result.data.createOrganization.id}`);
        return;
      }
      navigate(`/org`);
    },
    []
  );

  return (
    <>
      <Drawer
        onClose={handleOnClose}
        open={drawerOpen}
        title="Create Organization"
      >
        <DrawerContainer>
          <OrganizationForm onDirty={setDirty} onSubmit={handleOnSubmit} />
        </DrawerContainer>
      </Drawer>
      <AlertDialog
        actions={
          <>
            <Button onClick={() => setAlertOpen(false)}>No</Button>
            <Button onClick={close}>Yes</Button>
          </>
        }
        description="Are you sure you want to cancel and discard?"
        onClose={() => setAlertOpen(false)}
        open={alertOpen}
        title="Cancel?"
      />
    </>
  );
}
