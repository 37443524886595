import { glassSurface } from '@event-horizon/app-components';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Card = styled.div`
  ${glassSurface};
  padding: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
  gap: 64px;

  & > a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;

    .value {
      color: #fff;
      font: normal normal normal 20px/24px Lexend;
    }

    .label {
      color: #b557ff;
      font: normal normal normal 10px/12px Lexend;
      text-transform: uppercase;
    }
  }
`;

interface ApplicationStatisticsProps {
  indicators: {
    id: string;
  }[];
  objectives: {
    id: string;
  }[];
  webhooks: {
    url: string;
  }[];
}

const formatCount = (value: number) =>
  value.toLocaleString('en', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

export function ApplicationStatistics({
  indicators,
  objectives,
  webhooks,
}: ApplicationStatisticsProps): ReactElement {
  return (
    <Card>
      <Link to="../indicators">
        <div className="value">{formatCount(indicators.length)}</div>
        <div className="label">Indicators</div>
      </Link>
      <Link to="../objectives">
        <div className="value">{formatCount(objectives.length)}</div>
        <div className="label">Objectives</div>
      </Link>
      <Link to="../settings">
        <div className="value">{formatCount(webhooks.length)}</div>
        <div className="label">Webhooks</div>
      </Link>
    </Card>
  );
}
