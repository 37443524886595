import { AlertDialog, Drawer } from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import {
  DeleteIndicatorInput,
  IndicatorOperation,
  IndicatorWindow,
  UpdateIndicatorInput,
} from '@event-horizon/graphql-api-schema';
import { Button } from '@mui/material';
import { FormikHelpers } from 'formik';
import { ReactElement, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation } from 'urql';
import { IndicatorForm, IndicatorFormValues } from './IndicatorForm';
import { useIndicator } from './IndicatorOutlet';

const DeleteIndicator = graphql(`
  mutation DeleteIndicator($input: DeleteIndicatorInput!) {
    deleteIndicator(input: $input)
  }
`);

const UpdateIndicator = graphql(`
  mutation UpdateIndicator($input: UpdateIndicatorInput!) {
    updateIndicator(input: $input) {
      id
    }
  }
`);

const DrawerContainer = styled.div`
  flex: 1 auto;
  display: flex;
  flex-direction: column;
`;

interface UpdateIndicatorDrawerProps {
  open: boolean;
  onClose: () => void;
}

export function UpdateIndicatorDrawer({
  open,
  onClose,
}: UpdateIndicatorDrawerProps): ReactElement {
  const { application, indicator, organization } = useIndicator();
  const navigate = useNavigate();
  const [cancelAlertIsOpen, setCancelAlertIsOpen] = useState(false);
  const [deleteAlertIsOpen, setDeleteAlertIsOpen] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [, deleteIndicator] = useMutation(DeleteIndicator);
  const [, updateIndicator] = useMutation(UpdateIndicator);

  const handleOnClose = useCallback(() => {
    if (dirty) {
      setCancelAlertIsOpen(true);
      return;
    }
    onClose();
  }, [onClose, dirty, setCancelAlertIsOpen]);

  const handleOnDelete = useCallback(async () => {
    const input: DeleteIndicatorInput = {
      indicatorId: indicator.id,
    };
    const result = await deleteIndicator({ input });
    navigate(`/org/${organization.id}/app/${application.id}/indicators`);
  }, [
    application.id,
    deleteIndicator,
    indicator.id,
    navigate,
    organization.id,
  ]);

  const handleOnSubmit = useCallback(
    async (
      values: IndicatorFormValues,
      formikHelpers: FormikHelpers<IndicatorFormValues>
    ) => {
      const input: UpdateIndicatorInput = {
        ...values,
        indicatorId: indicator.id,
        operation: values.operation as IndicatorOperation,
        window: values.window as IndicatorWindow,
      };
      const result = await updateIndicator({ input });
      if (result.error) {
        formikHelpers.setSubmitting(false);
        formikHelpers.setErrors(result.error);
        return;
      }
      onClose();
    },
    [onClose, updateIndicator, indicator.id]
  );

  return (
    <>
      <Drawer onClose={handleOnClose} open={open} title="Update Indicator">
        <DrawerContainer>
          <IndicatorForm
            indicator={indicator}
            onClose={handleOnClose}
            onDelete={() => setDeleteAlertIsOpen(true)}
            onDirty={setDirty}
            onSubmit={handleOnSubmit}
          />
        </DrawerContainer>
      </Drawer>
      <AlertDialog
        actions={
          <>
            <Button onClick={() => setDeleteAlertIsOpen(false)}>No</Button>
            <Button onClick={handleOnDelete}>Yes</Button>
          </>
        }
        description="Are you sure you want to delete this indicator?"
        onClose={() => setDeleteAlertIsOpen(false)}
        open={deleteAlertIsOpen}
        title="Delete?"
      />
      <AlertDialog
        actions={
          <>
            <Button onClick={() => setCancelAlertIsOpen(false)}>No</Button>
            <Button
              onClick={() => {
                onClose();
                setCancelAlertIsOpen(false);
              }}
            >
              Yes
            </Button>
          </>
        }
        description="Are you sure you want to cancel and discard?"
        onClose={() => setCancelAlertIsOpen(false)}
        open={cancelAlertIsOpen}
        title="Cancel?"
      />
    </>
  );
}
