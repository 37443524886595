import { onCLS, onFID, onLCP } from 'web-vitals';
import { MeasurementResult } from '@event-horizon/graphql-api-schema';
import { logMeasurement } from '../log-measurement';

export default function webVitals(userMeasurementMetadata: object = {}) {
  onCLS((metric) => {
    logMeasurement(
      metric.name,
      MeasurementResult.SUCCESS,
      metric.value,
      window?.location?.href ?? null,
      userMeasurementMetadata,
      metric
    );
  });
  onFID((metric) => {
    logMeasurement(
      metric.name,
      MeasurementResult.SUCCESS,
      metric.value,
      window?.location?.href ?? null,
      userMeasurementMetadata,
      metric
    );
  });
  onLCP((metric) => {
    logMeasurement(
      metric.name,
      MeasurementResult.SUCCESS,
      metric.value,
      window?.location?.href ?? null,
      userMeasurementMetadata,
      metric
    );
  });
}
