import { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const PageHeader = styled.div`
  display: none;

  @media (min-width: 960px) {
    padding: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export interface PageContainerProps {
  header?: ReactNode;
  children?: ReactNode;
}

export function PageContainer(props: PageContainerProps) {
  return (
    <Container>
      {props.header && <PageHeader>{props.header}</PageHeader>}
      {props.children}
    </Container>
  );
}
