import { glassSurface } from '@event-horizon/app-components';
import { Aggregation } from '@event-horizon/graphql-api-schema';
import { DateTime } from 'luxon';
import { ReactElement, useMemo } from 'react';
import styled from 'styled-components';
import {
  ConsumptionHistoryChart,
  ConsumptionHistoryChartViewModel,
} from './ConsumptionHistoryChart';

const Card = styled.div`
  ${glassSurface};
  height: 480px;
  width: 100%;
  display: grid;
  grid-template-rows: 56px 1fr;

  .header {
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h3 {
      color: #fff;
      font: normal normal normal 14px/17px Lexend;
      text-transform: none;
    }
  }
`;

interface ConsumptionHistoryProps {
  aggregation: Aggregation;
  consumptionHistory: {
    start: string;
    end: string;
    measurements: number;
  }[];
}

export function ConsumptionHistory({
  aggregation,
  consumptionHistory,
}: ConsumptionHistoryProps): ReactElement {
  const data = useMemo((): ConsumptionHistoryChartViewModel[] => {
    return consumptionHistory.map((history) => ({
      start: DateTime.fromISO(history.start),
      end: DateTime.fromISO(history.end),
      measurements: history.measurements,
    }));
  }, [consumptionHistory]);

  return (
    <Card>
      <div className="header">
        <h3>Consumption History</h3>
      </div>
      <ConsumptionHistoryChart aggregation={aggregation} data={data} />
    </Card>
  );
}
