import { Chart, ChartType } from 'chart.js';
import { TooltipModel } from 'chart.js/dist/types';

function getOrCreateTooltip(chart: Chart): HTMLDivElement {
  const parent = chart.canvas.parentNode as HTMLDivElement;
  let tooltipEl = parent.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.display = 'inline-flex';
    tooltipEl.style.padding = '8px';
    tooltipEl.style.flexDirection = 'column';
    tooltipEl.style.gap = '3px';
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.43)';
    tooltipEl.style.backdropFilter = 'blur(4.4px)';
    tooltipEl.style.border = '1px solid rgba(255, 255, 255, 0.12)';
    tooltipEl.style.borderRadius = '4px';
    tooltipEl.style.boxShadow = '0px 2px 4px 0px rgba(0, 0, 0, 0.12);';
    tooltipEl.style.color = '#fff';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    parent.appendChild(tooltipEl);
  }

  return tooltipEl;
}

export function tooltip<TType extends ChartType = ChartType>(context: {
  chart: Chart;
  tooltip: TooltipModel<TType>;
}) {
  // tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  // text
  const titleLines = tooltip.title.map((title) => `<div>${title}</div>`);
  const bodyLines = tooltip.body.map((body, i) => {
    return `<div>${body.lines.join('')}</div>`;
  });
  tooltipEl.innerHTML = `
    <div style="display: flex; flex-direction: column; gap: 4px;">
      <div style="font-size: 14px; font-weight: 300;">${bodyLines}</div>
      <div style="font-size: 11px; line-height: 12px; font-weight: 500;">${titleLines}</div>
    </div>
  `;

  // styles
  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  tooltipEl.style.opacity = '1';
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.padding =
    tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
}
