import { Danger, Drawer, Info, Loading } from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { DeleteOrganizationInput } from '@event-horizon/graphql-api-schema';
import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation, useQuery } from 'urql';
import * as yup from 'yup';
import { useOrganization } from './OrganizationOutlet';

const GetDeleteOrganizationPage = graphql(`
  query GetDeleteOrganizationPage($orgId: ID!) {
    me {
      id
      organization(id: $orgId) {
        id
        apps {
          id
        }
      }
    }
  }
`);

const DeleteOrganization = graphql(`
  mutation DeleteOrganization($input: DeleteOrganizationInput!) {
    deleteOrganization(input: $input)
  }
`);

const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

const OrganizationName = styled.pre`
  color: var(--color-warn-900);
  font-weight: 500;
  background: var(--color-warn-100);
  border-radius: 4px;
  padding: 4px 8px;
  margin: 0 auto 0 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export function DeleteOrganizationPage(): ReactElement {
  const { organization } = useOrganization();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [{ data }] = useQuery({
    query: GetDeleteOrganizationPage,
    variables: {
      orgId: organization.id,
    },
  });
  const [, deleteOrganization] = useMutation(DeleteOrganization);

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup.string().required().equals([organization.name]),
      }),
    [organization.name]
  );

  const { errors, handleChange, handleSubmit, isValid, touched, values } =
    useFormik({
      initialValues: {
        name: '',
      },
      validateOnMount: true,
      validationSchema: validationSchema,
      onSubmit: async () => {
        const input: DeleteOrganizationInput = {
          organizationId: organization.id,
        };
        await deleteOrganization({
          input,
        });
        navigate(`/`);
      },
    });

  const handleOnClose = useCallback(() => {
    setOpen(false);
    navigate(-1);
  }, []);

  if (!data) {
    return <Loading />;
  }

  if (!data.me.organization) {
    throw new Error('Organization not found');
  }

  return (
    <Drawer onClose={handleOnClose} open={open} title="Delete Organization">
      <DrawerContainer>
        {data.me.organization.apps.length > 0 && (
          <Info title={`Cannot delete ${organization.name}`}>
            <p>To delete an organization it must not have any applications.</p>
            <p>
              Please remove all applications from the organization and try
              again.
            </p>
          </Info>
        )}
        {data.me.organization.apps.length === 0 && (
          <>
            <Danger title="Danger!">
              <p>
                Deleting an organization is a permanent action. To proceed, you
                must confirm the deletion be entering the organization name:
              </p>
              <OrganizationName>{organization.name}</OrganizationName>
            </Danger>
            <Form onSubmit={handleSubmit}>
              <TextField
                error={touched.name && Boolean(errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label="Organization Name"
                name="name"
                onChange={handleChange}
                value={values.name}
                variant="outlined"
              />
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="error"
                disabled={!isValid}
              >
                Delete Organization
              </Button>
            </Form>
          </>
        )}
      </DrawerContainer>
    </Drawer>
  );
}
