import { AlertDialog, Drawer } from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import {
  DeleteObjectiveInput,
  UpdateObjectiveInput,
} from '@event-horizon/graphql-api-schema';
import { Button } from '@mui/material';
import { FormikHelpers } from 'formik';
import { ReactElement, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation } from 'urql';
import { ObjectiveForm, ObjectiveFormValues } from './ObjectiveForm';
import { useObjective } from './ObjectiveOutlet';

const DeleteObjective = graphql(`
  mutation DeleteObjective($input: DeleteObjectiveInput!) {
    deleteObjective(input: $input)
  }
`);

const UpdateObjective = graphql(`
  mutation UpdateObjective($input: UpdateObjectiveInput!) {
    updateObjective(input: $input) {
      id
      name
    }
  }
`);

const DrawerContainer = styled.div`
  flex: 1 auto;
  display: flex;
  flex-direction: column;
`;

export interface UpdateObjectiveDrawerProps {
  open: boolean;
  onClose: () => void;
}

export function UpdateObjectiveDrawer({
  open,
  onClose,
}: UpdateObjectiveDrawerProps): ReactElement {
  const { application, organization, objective } = useObjective();
  const navigate = useNavigate();
  const [cancelAlertIsOpen, setCancelAlertIsOpen] = useState(false);
  const [deleteAlertIsOpen, setDeleteAlertIsOpen] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [, deleteObjective] = useMutation(DeleteObjective);
  const [, updateObjective] = useMutation(UpdateObjective);

  const handleOnClose = useCallback(() => {
    if (dirty) {
      setCancelAlertIsOpen(true);
      return;
    }
    onClose();
  }, [onClose, dirty, setCancelAlertIsOpen]);

  const handleOnDelete = useCallback(async () => {
    const input: DeleteObjectiveInput = {
      objectiveId: objective.id,
    };
    const result = await deleteObjective({ input });
    navigate(`/org/${organization.id}/app/${application.id}/objectives`);
  }, [
    application.id,
    deleteObjective,
    navigate,
    objective.id,
    organization.id,
  ]);

  const handleOnSubmit = useCallback(
    async (
      values: ObjectiveFormValues,
      formikHelpers: FormikHelpers<ObjectiveFormValues>
    ) => {
      const input: UpdateObjectiveInput = {
        ...values,
        objectiveId: objective.id,
        threshold: {
          gte: values.threshold.gte ? Number(values.threshold.gte) : null,
          lte: Number(values.threshold.lte),
        },
      };
      const result = await updateObjective({ input });
      if (result.error) {
        formikHelpers.setSubmitting(false);
        formikHelpers.setErrors(result.error);
        return;
      }
      onClose();
    },
    [objective.id, onClose, updateObjective]
  );

  return (
    <>
      <Drawer onClose={handleOnClose} open={open} title="Update Objective">
        <DrawerContainer>
          <ObjectiveForm
            appId={application.id}
            objective={objective}
            onClose={handleOnClose}
            onDelete={() => setDeleteAlertIsOpen(true)}
            onDirty={setDirty}
            onSubmit={handleOnSubmit}
            orgId={organization.id}
          />
        </DrawerContainer>
      </Drawer>
      <AlertDialog
        actions={
          <>
            <Button onClick={() => setDeleteAlertIsOpen(false)}>No</Button>
            <Button onClick={handleOnDelete}>Yes</Button>
          </>
        }
        description="Are you sure you want to delete this objective?"
        onClose={() => setDeleteAlertIsOpen(false)}
        open={deleteAlertIsOpen}
        title="Delete?"
      />
      <AlertDialog
        actions={
          <>
            <Button onClick={() => setCancelAlertIsOpen(false)}>No</Button>
            <Button
              onClick={() => {
                onClose();
                setCancelAlertIsOpen(false);
              }}
            >
              Yes
            </Button>
          </>
        }
        description="Are you sure you want to cancel and discard?"
        onClose={() => setCancelAlertIsOpen(false)}
        open={cancelAlertIsOpen}
        title="Cancel?"
      />
    </>
  );
}
