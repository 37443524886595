import { Loading } from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import {
  App,
  Indicator,
  Objective,
  Organization,
} from '@event-horizon/graphql-api-schema';
import { ReactElement } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { useQuery } from 'urql';

const GetIndicatorOutlet = graphql(`
  query GetIndicatorOutlet($orgId: ID!, $appId: ID!, $indicatorId: ID!) {
    me {
      id
      organization(id: $orgId) {
        id
        name
        app(id: $appId) {
          id
          name
          indicator(id: $indicatorId) {
            id
            name
            operation
            predicateFunction
            window
            description
            objectives {
              id
              name
              state
              stateLastUpdated
            }
            logMessagesConnection {
              edges {
                node {
                  id
                  message
                  timestamp
                }
              }
            }
          }
        }
      }
    }
  }
`);

type ContextType = {
  application: Pick<App, 'id' | 'name'>;
  indicator: Pick<
    Indicator,
    | 'id'
    | 'name'
    | 'operation'
    | 'predicateFunction'
    | 'window'
    | 'description'
    | 'logMessagesConnection'
  >;
  objectives: Pick<Objective, 'id' | 'name' | 'state' | 'stateLastUpdated'>[];
  organization: Pick<Organization, 'id' | 'name'>;
};

export function IndicatorOutlet(): ReactElement {
  const { appId, indicatorId, orgId } = useParams<{
    orgId: string;
    appId: string;
    indicatorId: string;
  }>();

  if (!orgId) {
    throw new Error('The orgId parameter is required.');
  }

  if (!appId) {
    throw new Error('The appId parameter is required.');
  }

  if (!indicatorId) {
    throw new Error('The indicatorId parameter is required.');
  }

  const [{ data }] = useQuery({
    query: GetIndicatorOutlet,
    variables: {
      orgId,
      appId,
      indicatorId,
    },
  });

  if (!data) {
    return <Loading />;
  }

  if (!data.me.organization) {
    throw new Error('The organization was not found.');
  }
  if (!data.me.organization.app) {
    throw new Error('The application was not found.');
  }
  if (!data.me.organization.app.indicator) {
    throw new Error('The indicator was not found.');
  }

  return (
    <Outlet
      context={{
        application: data.me.organization.app,
        indicator: data.me.organization.app.indicator,
        objectives: data.me.organization.app.indicator.objectives,
        organization: data.me.organization,
      }}
    />
  );
}

export function useIndicator() {
  return useOutletContext<ContextType>();
}
