import { Loading } from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import {
  App,
  Organization,
  User,
  UserRole,
} from '@event-horizon/graphql-api-schema';
import { createContext, useContext } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useQuery } from 'urql';

const GetOrganizationOutlet = graphql(`
  query GetOrganizationOutlet($id: ID!) {
    me {
      id
      name
      email
      organization(id: $id) {
        id
        name
        logo
        apps {
          id
          name
        }
        userConnection {
          edges {
            role
          }
        }
      }
    }
  }
`);

type ContextType = {
  apps: Pick<App, 'id' | 'name'>[];
  organization: Pick<Organization, 'id' | 'name' | 'logo'>;
  user: Pick<User, 'id' | 'name' | 'email'>;
  role: UserRole;
};

const OrganizationContext = createContext<ContextType | null>(null);

export function OrganizationOutlet() {
  const { orgId } = useParams<{ orgId: string }>();

  if (!orgId) {
    throw new Error('The orgId parameter is required.');
  }

  const [{ data }] = useQuery({
    query: GetOrganizationOutlet,
    variables: {
      id: orgId,
    },
  });

  if (!data) {
    return <Loading />;
  }

  if (!data.me.organization) {
    throw new Error('The organization was not found.');
  }

  return (
    <OrganizationContext.Provider
      value={{
        apps: data.me.organization.apps,
        organization: data.me.organization,
        user: { email: data.me.email, id: data.me.id, name: data.me.name },
        role: data.me.organization.userConnection.edges[0].role,
      }}
    >
      <Outlet />
    </OrganizationContext.Provider>
  );
}

export function useOrganization() {
  const value = useContext(OrganizationContext);

  if (!value) {
    throw new Error(
      'The useOrganization hook must be used within the Organization context.'
    );
  }

  return value;
}
