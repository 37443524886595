import { useApplication } from '@event-horizon/app-applications';
import { Button, PageContainer, Sdks } from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { Sdk, UpdateAppInput } from '@event-horizon/graphql-api-schema';
import { MDXProps } from 'mdx/types';
import {
  createElement,
  lazy,
  ReactElement,
  Suspense,
  useCallback,
  useMemo,
} from 'react';
import styled from 'styled-components';
import { useMutation } from 'urql';
import { SdkMenu } from './SdkMenu';

const UpdateAppSdk = graphql(`
  mutation UpdateAppSdk($input: UpdateAppInput!) {
    updateApp(input: $input) {
      id
      sdk
    }
  }
`);

interface QuickStartProps extends MDXProps {
  apiKey: string;
}

const AngularQuickStart = lazy(() => import(`./docs/angular/quick-start.mdx`));

const ReactQuickStart = lazy(() => import(`./docs/react/quick-start.mdx`));

const TypeScriptQuickStart = lazy(
  () => import(`./docs/typescript/quick-start.mdx`)
);

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 24px;
  padding: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const Container = styled.div`
  max-width: 80ch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  color: rgba(255, 255, 255, 0.72);
  font: normal normal 300 16px/18px Lexend;

  > h2,
  > h3 {
    background: radial-gradient(
      16413.7% 106.51% at 67.55% 0%,
      #fff 0%,
      rgba(255, 255, 255, 0.6) 100%
    );
    background-clip: text;
    text-transform: unset;
    margin-top: 32px;
  }

  > h2:first-child {
    margin-top: 0;
  }

  > h2 {
    font: normal normal 700 24px/2rem Lexend;
  }

  > h3 {
    font: normal normal 700 18px/1.8rem Lexend;
  }

  > ol,
  > ul {
    padding-left: 36px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  > ol {
    list-style-type: decimal;
  }

  > ul {
    list-style-type: disc;
  }
`;

export function ApplicationDocPage(): ReactElement {
  const { application } = useApplication();
  const [, updateAppSdk] = useMutation(UpdateAppSdk);

  const handleSdkOnChange = useCallback(
    async (sdk: Sdk) => {
      const input: UpdateAppInput = {
        appId: application.id,
        name: application.name,
        webhooks: application.webhooks as unknown as string[],
        sdk: sdk,
      };
      application.sdk = sdk;
      await updateAppSdk({ input });
    },
    [application, updateAppSdk]
  );

  const QuickStart = useMemo(() => {
    switch (application.sdk) {
      case Sdk.ANGULAR:
        return AngularQuickStart;
      case Sdk.REACT:
        return ReactQuickStart;
      case Sdk.TYPESCRIPT:
        return TypeScriptQuickStart;
      default:
        throw new Error('Invalid SDK');
    }
  }, [application.sdk]);

  return (
    <PageContainer>
      <Controls>
        <Actions>
          <Button
            as={'a'}
            color="primary"
            href={`https://getpolaris.ai/docs/${
              Sdks[application.sdk].uri
            }/getting-started/quick-start/`}
            target="_blank"
          >
            Docs
          </Button>
          <SdkMenu onChange={handleSdkOnChange} sdk={application.sdk} />
        </Actions>
      </Controls>
      <Container>
        <Suspense fallback={<div>Loading...</div>}>
          {createElement<QuickStartProps>(QuickStart, {
            apiKey: application.apiKey,
          })}
        </Suspense>
      </Container>
    </PageContainer>
  );
}
