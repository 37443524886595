import { getApiUrl } from './config/api-url';
import { getApiKey } from './config/api-key';
import 'whatwg-fetch';

interface TestConnectionResponse {
  error?: string;
  success?: boolean;
}

/**
 * Uses the API URL to send a connection request.
 * This function throws an error if the API key
 * is not set or is invalid.
 */
export async function testConnection(): Promise<void> {
  const response = await fetch(`${getApiUrl()}/connection`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      apiKey: getApiKey(),
    }),
  });

  const r = (await response.json()) as TestConnectionResponse;

  if (r.error) {
    throw new Error(r.error);
  }
}
