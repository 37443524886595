import { useApplication } from '@event-horizon/app-applications';
import { AlertDialog, Button, Drawer } from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import {
  CreateIndicatorInput,
  IndicatorOperation,
  IndicatorWindow,
} from '@event-horizon/graphql-api-schema';
import { FormikHelpers } from 'formik';
import { ReactElement, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation } from 'urql';
import { IndicatorForm, IndicatorFormValues } from './IndicatorForm';

const CreateIndicator = graphql(`
  mutation CreateIndicator($input: CreateIndicatorInput!) {
    createIndicator(input: $input) {
      id
    }
  }
`);

const DrawerContainer = styled.div`
  flex: 1 auto;
  display: flex;
  flex-direction: column;
`;

export function CreateIndicatorPage(): ReactElement {
  const { application, organization } = useApplication();
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [, createIndicator] = useMutation(CreateIndicator);

  const close = useCallback(() => {
    setDrawerOpen(false);
    navigate(-1);
  }, [navigate, setDrawerOpen]);

  const handleOnClose = useCallback(() => {
    if (dirty) {
      setAlertOpen(true);
      return;
    }
    close();
  }, [close, dirty, setAlertOpen]);

  const handleOnSubmit = useCallback(
    async (
      values: IndicatorFormValues,
      formikHelpers: FormikHelpers<IndicatorFormValues>
    ) => {
      const input: CreateIndicatorInput = {
        ...values,
        appId: application.id,
        operation: values.operation as IndicatorOperation,
        window: values.window as IndicatorWindow,
      };
      const result = await createIndicator({ input });
      if (result.error) {
        formikHelpers.setSubmitting(false);
        formikHelpers.setErrors(result.error);
        return;
      }
      if (result.data?.createIndicator?.id) {
        navigate(
          `/org/${organization.id}/app/${application.id}/indicator/${result.data.createIndicator.id}`
        );
        return;
      }
      navigate(`/org/${organization.id}/app/${application.id}`);
    },
    []
  );

  return (
    <>
      <Drawer
        onClose={handleOnClose}
        open={drawerOpen}
        title="Create Indicator"
      >
        <DrawerContainer>
          <IndicatorForm
            onClose={handleOnClose}
            onDirty={setDirty}
            onSubmit={handleOnSubmit}
          />
        </DrawerContainer>
      </Drawer>
      <AlertDialog
        actions={
          <>
            <Button onClick={() => setAlertOpen(false)}>No</Button>
            <Button color="warn" onClick={close}>
              Yes
            </Button>
          </>
        }
        description="Are you sure you want to cancel and discard?"
        onClose={() => setAlertOpen(false)}
        open={alertOpen}
        title="Cancel?"
      />
    </>
  );
}
