import { AppMenu, Loading } from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { App, Organization, UserRole } from '@event-horizon/graphql-api-schema';
import { createContext, ReactElement, useContext } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from 'urql';

const GetApplicationOutlet = graphql(`
  query GetApplicationOutlet($orgId: ID!, $appId: ID!) {
    me {
      id
      name
      email
      organization(id: $orgId) {
        id
        name
        logo
        app(id: $appId) {
          id
          name
          apiKey
          measurementBudget
          webhooks {
            url
          }
          sdk
        }
        apps {
          id
          name
        }
        userConnection {
          edges {
            role
          }
        }
      }
    }
  }
`);

type ContextType = {
  application: Pick<
    App,
    'id' | 'name' | 'apiKey' | 'measurementBudget' | 'webhooks' | 'sdk'
  >;
  applications: Pick<App, 'id' | 'name'>[];
  organization: Pick<Organization, 'id' | 'name'>;
  role: UserRole;
};

const AppContext = createContext<ContextType | null>(null);

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 960px) {
    flex-direction: row;
  }
`;

const AppContent = styled.div`
  flex: 1 auto;
  display: flex;
  flex-direction: column;

  @media (min-width: 960px) {
    margin-left: 261px;
  }
`;

export function ApplicationOutlet(): ReactElement {
  const { appId, orgId } = useParams<{ orgId: string; appId: string }>();

  if (!orgId) {
    throw new Error('The orgId parameter is required.');
  }

  if (!appId) {
    throw new Error('The appId parameter is required.');
  }

  const [{ data }] = useQuery({
    query: GetApplicationOutlet,
    variables: {
      orgId,
      appId,
    },
  });

  if (!data) {
    return <Loading />;
  }

  if (!data.me.organization) {
    throw new Error('The organization was not found.');
  }
  if (!data.me.organization.app) {
    throw new Error('The application was not found.');
  }

  return (
    <Container>
      <AppMenu
        app={data.me.organization.app}
        apps={data.me.organization.apps}
        organization={data.me.organization}
        user={{ email: data.me.email, name: data.me.name }}
        role={data.me.organization.userConnection.edges[0].role}
      />
      <AppContent>
        <AppContext.Provider
          value={{
            application: data.me.organization.app as any,
            applications: data.me.organization.apps,
            organization: data.me.organization,
            role: data.me.organization.userConnection.edges[0].role,
          }}
        >
          <Outlet />
        </AppContext.Provider>
      </AppContent>
    </Container>
  );
}

export function useApplication(): ContextType {
  const value = useContext(AppContext);

  if (!value) {
    throw new Error(
      'The useApplication hook must be used within the App context.'
    );
  }

  return value;
}
