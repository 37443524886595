import { useApplication } from '@event-horizon/app-applications';
import { AlertDialog, Drawer } from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { CreateObjectiveInput } from '@event-horizon/graphql-api-schema';
import { Button } from '@mui/material';
import { FormikHelpers } from 'formik';
import { ReactElement, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation } from 'urql';
import { ObjectiveForm, ObjectiveFormValues } from './ObjectiveForm';

const CreateObjective = graphql(`
  mutation CreateObjective($input: CreateObjectiveInput!) {
    createObjective(input: $input) {
      id
    }
  }
`);

const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export function CreateObjectivePage(): ReactElement {
  const { application, organization } = useApplication();
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [, createObjective] = useMutation(CreateObjective);

  const close = useCallback(() => {
    setDrawerOpen(false);
    navigate(-1);
  }, [navigate, setDrawerOpen]);

  const handleOnClose = useCallback(() => {
    if (dirty) {
      setAlertOpen(true);
      return;
    }
    close();
  }, [close, dirty, setAlertOpen]);

  const handleOnSubmit = useCallback(
    async (
      values: ObjectiveFormValues,
      formikHelpers: FormikHelpers<ObjectiveFormValues>
    ) => {
      const input: CreateObjectiveInput = {
        ...values,
        indicatorId: values.indicatorId!,
        threshold: {
          gte: values.threshold.gte ? Number(values.threshold.gte) : null,
          lte: Number(values.threshold.lte),
        },
      };
      const result = await createObjective({ input });
      if (result.error) {
        formikHelpers.setSubmitting(false);
        formikHelpers.setErrors(result.error);
        return;
      }
      if (result.data?.createObjective.id) {
        navigate(
          `/org/${organization.id}/app/${application.id}/objective/${result.data.createObjective.id}`
        );
        return;
      }
      navigate(`/org/${organization.id}/app/${application.id}`);
    },
    []
  );

  return (
    <>
      <Drawer
        onClose={handleOnClose}
        open={drawerOpen}
        title="Create Objective"
      >
        <DrawerContainer>
          <ObjectiveForm
            appId={application.id}
            onClose={handleOnClose}
            onDirty={setDirty}
            onSubmit={handleOnSubmit}
            orgId={organization.id}
          />
        </DrawerContainer>
      </Drawer>
      <AlertDialog
        actions={
          <>
            <Button onClick={() => setAlertOpen(false)}>No</Button>
            <Button onClick={close}>Yes</Button>
          </>
        }
        description="Are you sure you want to cancel and discard?"
        onClose={() => setAlertOpen(false)}
        open={alertOpen}
        title="Cancel?"
      />
    </>
  );
}
