import { useAuth } from '@event-horizon/app-auth';
import { Button, Input, Snackbar } from '@event-horizon/app-components';
import { ChevronRight } from '@mui/icons-material';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

interface FormValues {
  email: string;
}

const Container = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 24px;

  &::before {
    content: '';
    position: absolute;
    background: url('/backgrounds/starfield.svg');
    backdrop-filter: blur(24px);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;

const Aurora = styled.div`
  height: 100%;
  max-width: 960px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 auto;
  gap: 32px;

  &::before {
    content: '';
    position: absolute;
    background: url('/backgrounds/aurora.svg') no-repeat;
    background-size: contain;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.12;
    z-index: -1;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const LogoLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  height: 60px;
  width: 70px;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export function LoginPage() {
  const [open, setOpen] = useState(false);
  const { authenticate } = useAuth();
  const validationSchema = yup.object({
    email: yup.string().email().required('Please provide your email address'),
  });

  const handleOnClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOnSubmit = useCallback(
    async (values: FormValues) => {
      try {
        setOpen(true);
        await authenticate(values.email);
      } catch (error) {
        console.error(error);
      }
    },
    [authenticate]
  );

  const { handleBlur, handleChange, handleSubmit, isValid, values } =
    useFormik<FormValues>({
      initialValues: {
        email: '',
      },
      validationSchema: validationSchema,
      onSubmit: handleOnSubmit,
    });

  return (
    <>
      <Container>
        <Aurora>
          <Content>
            <LogoLink href="https://getpolaris.ai">
              <Logo alt="Polaris Logo" src="/icons/polaris-c.svg" />
            </LogoLink>
            <Form onSubmit={handleSubmit}>
              <Input
                fullWidth
                label="Email address"
                size="small"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
              />
              <Button disabled={!isValid} type="submit" raised>
                <ChevronRight />
              </Button>
            </Form>
          </Content>
        </Aurora>
      </Container>
      <Snackbar
        open={open}
        onClose={handleOnClose}
        message="We've sent an email with a link to your inbox"
      />
    </>
  );
}
