import { useAuth } from '@event-horizon/app-auth';
import { Sdk } from '@event-horizon/graphql-api-schema';
import {
  Avatar,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  PopoverOrigin,
} from '@mui/material';
import { ArrowDropDown, Logout } from '@mui/icons-material';
import { MouseEvent, ReactElement, useMemo, useState } from 'react';
import styled from 'styled-components';
import { glassSurface } from './GlassSurface';
import { Sdks } from './Sdks';

interface UserMenuProps {
  anchorOrigin: PopoverOrigin;
  sdk?: Sdk;
  transformOrigin: PopoverOrigin;
  user: {
    email: string;
    name: string | null;
  };
}

const Container = styled.div`
  display: none;

  @media (min-width: 960px) {
    display: flex;
  }
`;

const UserButton = styled.button`
  display: grid;
  grid-template-columns: 24px 1fr 24px;
  gap: 16px;
  align-items: center;
  margin: 24px;

  span {
    color: rgba(255, 255, 255, 0.86);
    font-family: Lexend, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const GlassMenu = styled(Menu)`
  & .MuiPaper-root {
    ${glassSurface};
    min-width: 220px;
  }
`;

const MenuItemLink = styled.a`
  width: 100%;
`;

export function UserMenu({
  anchorOrigin,
  sdk,
  transformOrigin,
  user,
}: UserMenuProps): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const { logout, user: auth0UserProfile } = useAuth();
  const open = Boolean(anchorEl);

  const href = useMemo(() => {
    if (sdk) {
      return `https://getpolaris.ai/docs/${
        Sdks[sdk].uri ?? 'typescript'
      }/getting-started/quick-start/`;
    }
    return `https://getpolaris.ai/docs/typescript/getting-started/quick-start/`;
  }, [sdk]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    setAnchorEl(null);
  };

  return (
    <Container>
      <UserButton
        id="user-menu-button"
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Avatar
          src={auth0UserProfile?.picture}
          sx={{ width: 24, height: 24 }}
        />
        <span>{user.name}</span>
        <ArrowDropDown />
      </UserButton>
      <GlassMenu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'user-menu-button',
        }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <MenuItem>
          <MenuItemLink href={href} target="_blank" rel="noreferrer">
            Docs
          </MenuItemLink>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </GlassMenu>
    </Container>
  );
}
