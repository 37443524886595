import { Cancel } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FormikErrors } from 'formik';
import { ChangeEvent, FocusEvent, ReactElement } from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import { Input } from './Input';

interface ApplicationWebhooksProps {
  errors?: FormikErrors<string | string[]>;
  name: string;
  onAdd: () => void;
  onBlur: (event: FocusEvent<HTMLInputElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onRemove: (index: number) => void;
  touched: boolean | undefined;
  value: string[];
}

const ApplicationWebhooksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ApplicationWebhooksRow = styled.div`
  display: grid;
  grid-template-columns: auto 56px;
  gap: 8px;
`;

export function ApplicationWebhooks(
  props: ApplicationWebhooksProps
): ReactElement {
  return (
    <ApplicationWebhooksContainer>
      {props.value.map((value, i) => (
        <ApplicationWebhooksRow key={`application-webhooks-${i}`}>
          <Input
            error={props.touched && Boolean(props.errors?.[i])}
            fullWidth
            helperText={props.touched && props.errors?.[i]}
            name={`${props.name}.${i.toString()}`}
            onBlur={props.onBlur}
            onChange={props.onChange}
            value={value}
          />
          <IconButton onClick={() => props.onRemove(i)}>
            <Cancel />
          </IconButton>
        </ApplicationWebhooksRow>
      ))}
      <Button onClick={props.onAdd}>Add Webhook</Button>
    </ApplicationWebhooksContainer>
  );
}
