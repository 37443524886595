import { ClickAwayListener, Fade, Popper } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import {
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import { ArrowDropDown, CalendarMonth } from '@mui/icons-material';
import { glassSurface } from './GlassSurface';

interface DateRangeProps {
  onChange: (start: DateTime, end: DateTime) => void;
  start: DateTime;
  end: DateTime;
}

const DateRangeContainer = styled.div`
  ${glassSurface};
  display: flex;
  align-items: stretch;
  gap: 16px;
`;

const Ranges = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
  padding: 16px;

  > ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;

    > li {
      > button {
        color: rgba(255, 255, 255, 0.72);
        font-family: Lexend;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }
  }
`;

const DateRangeLabel = styled.div`
  width: 116px;
  display: flex;
  align-items: center;
`;

const DateCalendars = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px;
`;

export function DateRange({
  start,
  end,
  onChange,
}: DateRangeProps): ReactElement {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const dateRange = useMemo(() => {
    const today = DateTime.local();
    const formatOptions: DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
    };
    if (start.hasSame(today, 'day') && end.hasSame(today, 'day')) {
      return `Today`;
    } else if (
      start.hasSame(today.minus({ days: 1 }), 'day') &&
      end.hasSame(today.minus({ days: 1 }), 'day')
    ) {
      return `Yesterday`;
    } else if (
      start.hasSame(today.minus({ days: 7 }), 'day') &&
      end.hasSame(today, 'day')
    ) {
      return `Last 7 Days`;
    } else if (
      start.hasSame(today.minus({ days: 30 }), 'day') &&
      end.hasSame(today, 'day')
    ) {
      return `Last 30 Days`;
    } else if (
      start.hasSame(today.startOf('month'), 'day') &&
      end.hasSame(today, 'day')
    ) {
      return `This Month`;
    } else if (start.hasSame(end, 'day')) {
      return start.toLocaleString(formatOptions);
    } else {
      return `${start.toLocaleString(formatOptions)} - ${end.toLocaleString(
        formatOptions
      )}`;
    }
  }, [end, start]);

  const handleToggle = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl((prevAnchorEl) => (prevAnchorEl ? null : event.currentTarget));
  }, []);

  const handleClickAway = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setAnchorEl(null);
    }
  }, []);

  const handleOnClickToday = useCallback(() => {
    onChange(DateTime.local().startOf('day'), DateTime.local().endOf('day'));
    setAnchorEl(null);
  }, [onChange, setAnchorEl]);

  const handleOnClickYesterday = useCallback(() => {
    onChange(
      DateTime.local().minus({ days: 1 }).startOf('day'),
      DateTime.local().minus({ days: 1 }).endOf('day')
    );
    setAnchorEl(null);
  }, [onChange, setAnchorEl]);

  const handleOnClickSevenDays = useCallback(() => {
    onChange(
      DateTime.local().minus({ days: 7 }).startOf('day'),
      DateTime.local().endOf('day')
    );
    setAnchorEl(null);
  }, [onChange, setAnchorEl]);

  const handleOnClickLastThirtyDays = useCallback(() => {
    onChange(
      DateTime.local().minus({ days: 30 }).startOf('day'),
      DateTime.local().endOf('day')
    );
    setAnchorEl(null);
  }, [onChange, setAnchorEl]);

  const handleOnClickThisMonth = useCallback(() => {
    onChange(
      DateTime.local().startOf('month'),
      DateTime.local().endOf('month')
    );
    setAnchorEl(null);
  }, [onChange, setAnchorEl]);

  const handleOnChangeStart = useCallback(
    (date: DateTime | null) => {
      if (!date) return;
      onChange(date.startOf('day'), end.endOf('day'));
    },
    [end, onChange]
  );

  const handleOnChangeEnd = useCallback(
    (date: DateTime | null) => {
      if (!date) return;
      onChange(start.startOf('day'), date.endOf('day'));
    },
    [onChange, start]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <Button raised onClick={handleToggle}>
          <CalendarMonth />
          <DateRangeLabel>{dateRange}</DateRangeLabel>
          <ArrowDropDown />
        </Button>
        {open ? (
          <Popper
            open={open}
            anchorEl={anchorEl}
            sx={{ marginTop: '8px !important' }}
            placement="bottom-end"
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <DateRangeContainer>
                  <Ranges>
                    <ul>
                      <li>
                        <button onClick={handleOnClickToday}>Today</button>
                      </li>
                      <li>
                        <button onClick={handleOnClickYesterday}>
                          Yesterday
                        </button>
                      </li>
                      <li>
                        <button onClick={handleOnClickSevenDays}>
                          Last 7 Days
                        </button>
                      </li>
                      <li>
                        <button onClick={handleOnClickLastThirtyDays}>
                          Last 30 Days
                        </button>
                      </li>
                      <li>
                        <button onClick={handleOnClickThisMonth}>
                          This Month
                        </button>
                      </li>
                    </ul>
                  </Ranges>
                  <DateCalendars>
                    <DateCalendar
                      value={start}
                      onChange={handleOnChangeStart}
                    />
                    <DateCalendar value={end} onChange={handleOnChangeEnd} />
                  </DateCalendars>
                </DateRangeContainer>
              </Fade>
            )}
          </Popper>
        ) : null}
      </div>
    </ClickAwayListener>
  );
}
