import { scaleLinear, line, area, curveCatmullRom } from 'd3';
import { DateTime } from 'luxon';
import { useMemo, useRef } from 'react';
import { motion } from 'framer-motion';
import useResizeObserver from 'use-resize-observer';
import styled from 'styled-components';

const CHART_TOP_PADDING = 56;

export interface IndicatorCardChartViewModel {
  date: DateTime;
  value: number;
}

export interface IndicatorCardChartProps {
  data: IndicatorCardChartViewModel[];
}

const pathAnimationProps: React.ComponentProps<typeof motion.path> = {
  transition: {
    duration: 1,
    ease: 'easeInOut',
  },
  variants: {
    hidden: {
      opacity: 0,
      pathLength: 0,
    },
    visible: {
      opacity: 1,
      pathLength: 1,
    },
  },
  initial: 'hidden',
  animate: 'visible',
};

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 88px;
  height: 100%;
  position: relative;
`;

export function IndicatorCardChart({ data }: IndicatorCardChartProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { width = 0, height = 0 } = useResizeObserver<HTMLDivElement>({
    ref: containerRef,
  });
  const xAxis = useMemo(() => {
    const dates = data.map((d) => d.date);
    const minDate = DateTime.min(...dates);
    const maxDate = DateTime.max(...dates);

    return scaleLinear()
      .domain([minDate.toMillis(), maxDate.toMillis()])
      .range([0, width]);
  }, [data, width]);
  const yAxis = useMemo(() => {
    const values = data.map((d) => d.value);
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);

    return scaleLinear()
      .domain([minValue, maxValue])
      .range([height + CHART_TOP_PADDING, CHART_TOP_PADDING])
      .nice();
  }, [data, height]);
  const sparkLineFn = useMemo(() => {
    return line<IndicatorCardChartViewModel>()
      .curve(curveCatmullRom.alpha(0.1))
      .x((d) => xAxis(d.date.toMillis()))
      .y((d) => yAxis(d.value));
  }, [xAxis, yAxis]);
  const sparkAreaFn = useMemo(() => {
    return area<IndicatorCardChartViewModel>()
      .curve(curveCatmullRom.alpha(0.1))
      .x((d) => xAxis(d.date.toMillis()))
      .y0(height + CHART_TOP_PADDING)
      .y1((d) => yAxis(d.value));
  }, [height, xAxis, yAxis]);
  const sparkLinePath = useMemo(() => {
    return sparkLineFn(data) || '';
  }, [data, sparkLineFn]);
  const sparkAreaPath = useMemo(() => {
    return sparkAreaFn(data) || '';
  }, [data, sparkAreaFn]);

  return (
    <Container ref={containerRef}>
      <motion.svg
        width={width}
        height={height + CHART_TOP_PADDING}
        style={{ position: 'absolute', marginTop: `-${CHART_TOP_PADDING}px` }}
      >
        <defs>
          <radialGradient
            id="indicator_card_chart_radial_gradient"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="rotate(17.354) scale(385.55 587.868)"
          >
            <stop stopColor="#10DDD3" />
            <stop offset="0.75" stopColor="#5F75F9" />
            <stop offset="1" stopColor="#B557FF" />
          </radialGradient>
        </defs>
        <motion.path
          d={sparkAreaPath}
          fill="url(#indicator_card_chart_radial_gradient)"
          fillOpacity="0.12"
          style={{ filter: 'blur(40px)' }}
          {...pathAnimationProps}
        />
        <motion.path
          d={sparkAreaPath}
          fill="url(#indicator_card_chart_radial_gradient)"
          fillOpacity="0.12"
          {...pathAnimationProps}
        />
        <motion.path
          d={sparkLinePath}
          fill="none"
          stroke="url(#indicator_card_chart_radial_gradient)"
          strokeWidth="1"
          {...pathAnimationProps}
        />
      </motion.svg>
    </Container>
  );
}
