import { useStripe } from '@stripe/react-stripe-js';
import { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  > h2 {
    font: 400 13px/16px 'Lexend', sans-serif;
  }
`;

const Message = styled.h2<{ success: boolean }>`
  color: ${({ success }) => (success ? '#10DDD3' : '#FF0000')};
`;

interface PaymentIntentProps {
  clientSecret?: string;
}

export function PaymentIntent({
  clientSecret,
}: PaymentIntentProps): ReactElement {
  const stripe = useStripe();
  const [result, setResult] = useState<[boolean, string]>();

  useEffect(() => {
    if (!stripe || !clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          setResult([true, 'Payment succeeded!']);
          break;
        case 'processing':
          setResult([true, 'Your payment is processing.']);
          break;
        case 'requires_payment_method':
          setResult([
            false,
            'Your payment was not successful, please try again.',
          ]);
          break;
        default:
          setResult([false, 'Something went wrong.']);
          break;
      }
    });
  }, [clientSecret, stripe]);

  return (
    <Container>
      {result && (
        <>
          <Message success={!!result?.[0]}>{result?.[1]}</Message>
          <p>
            Credits will be updated once the payment is processed successfully.
          </p>
        </>
      )}
    </Container>
  );
}
