import { LinearProgress } from '@mui/material';
import { FC, ReactElement } from 'react';
import { withAuthenticationRequired } from './withAuthenticationRequired';

interface AuthGuardProps {
  component: FC;
}

export function AuthGuard({ component }: AuthGuardProps): ReactElement {
  const Component = withAuthenticationRequired(component, {
    onLoading: () => {
      return <LinearProgress style={{ width: '100%', borderRadius: '4px' }} />;
    },
  });

  return <Component />;
}
