import { useAuth } from '@event-horizon/app-auth';
import { MeasurementResult } from '@event-horizon/graphql-api-schema';
import { usePolaris } from '@getpolaris.ai/sdk-react';
import { devtoolsExchange } from '@urql/devtools';
import { AuthConfig, authExchange } from '@urql/exchange-auth';
import { ReactElement, ReactNode, useCallback, useMemo, useRef } from 'react';
import {
  createClient,
  fetchExchange,
  makeOperation,
  mapExchange,
  Operation,
  OperationContext,
  OperationResult,
  Provider,
} from 'urql';
import { print } from 'graphql';

interface Context extends OperationContext {
  created: DOMHighResTimeStamp;
}

export function GraphQLProvider(props: {
  children: ReactNode;
  url: string;
  isDevMode: boolean;
}): ReactElement {
  const { getToken } = useAuth();
  const { logMeasurement } = usePolaris();

  const onOperation = useCallback(
    (operation: Operation): Promise<Operation> | Operation | void => {
      return makeOperation(operation.kind, operation, {
        ...operation.context,
        created: performance.now(),
      });
    },
    []
  );

  const onResult = useCallback(
    (
      result: OperationResult
    ): Promise<OperationResult> | OperationResult | void => {
      logMeasurement(
        'request',
        result.error ? MeasurementResult.FAILURE : MeasurementResult.SUCCESS,
        performance.now() - (result.operation.context as Context).created,
        null,
        {
          query: print(result.operation.query),
          variables: result.operation.variables,
          error: result.error,
        }
      );
    },
    [logMeasurement]
  );

  const exchanges = useMemo(() => {
    const exchanges = [
      authExchange(async (utils) => {
        const token = await getToken();
        return {
          addAuthToOperation: (operation) => {
            if (!token) {
              return operation;
            }
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${token}`,
            });
          },
        } as AuthConfig;
      }),
      mapExchange({
        onOperation,
        onResult,
      }),
      fetchExchange,
    ];
    if (props.isDevMode) {
      return [devtoolsExchange, ...exchanges];
    }
    return exchanges;
  }, [getToken, onOperation, onResult, props.isDevMode]);

  const client = useMemo(() => {
    return createClient({
      url: props.url,
      exchanges,
    });
  }, [exchanges, props.url]);

  return <Provider value={client}>{props.children}</Provider>;
}
