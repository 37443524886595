import { glassSurface } from '@event-horizon/app-components';
import styled from 'styled-components';

const Card = styled.div`
  ${glassSurface};
  width: 100%;
  height: 240px;
  overflow: hidden;

  &::after {
    content: '';
    background: linear-gradient(
      90deg,
      rgba(16, 221, 211, 0) 0,
      rgba(16, 221, 211, 0.08) 20%,
      rgba(87, 128, 245, 0.12) 60%,
      rgba(181, 87, 255, 0.06) 80%,
      rgba(181, 87, 255, 0)
    );

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    animation: shimmer 1.5s infinite ease-in-out;
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
`;

export function IndicatorCardGhostElement() {
  return <Card></Card>;
}
