import {
  MeasurementBudget,
  Loading,
  PageContainer,
} from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { Aggregation } from '@event-horizon/graphql-api-schema';
import { DateTime } from 'luxon';
import { ReactElement, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from 'urql';

const GetPaymentsPage = graphql(`
  query GetPaymentsPage(
    $orgId: ID!
    $appId: ID!
    $input: ConsumptionQueryInput!
  ) {
    me {
      id
      organization(id: $orgId) {
        id
        app(id: $appId) {
          id
          name
          measurementBudget
          consumption(input: $input) {
            start
            end
            measurements
          }
        }
      }
    }
  }
`);

const ApplicationOverview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
`;

const NOW = DateTime.now();

export function PaymentsPage(): ReactElement {
  const { appId, orgId } = useParams<{ appId: string; orgId: string }>();
  const [aggregation, setAggregation] = useState<Aggregation>(
    Aggregation.BY_HOUR
  );
  const [start] = useState<DateTime>(NOW.startOf('month'));
  const [end] = useState<DateTime>(NOW.endOf('month'));

  if (!orgId) {
    throw new Error('The orgId parameter is required.');
  }

  if (!appId) {
    throw new Error('The appId parameter is required.');
  }

  const [{ data }] = useQuery({
    query: GetPaymentsPage,
    variables: {
      appId,
      orgId,
      input: {
        end: end.toISO()!,
        start: start.toISO()!,
        aggregation,
      },
    },
  });

  if (!data) {
    return <Loading />;
  }

  if (!data.me.organization) {
    throw new Error('Organization not found');
  }
  if (!data.me.organization.app) {
    throw new Error('Application not found');
  }

  return (
    <PageContainer>
      <ApplicationOverview>
        <MeasurementBudget
          app={data.me.organization.app}
          organization={data.me.organization}
        />
      </ApplicationOverview>
      <Outlet />
    </PageContainer>
  );
}
