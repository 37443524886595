import { ICellRendererParams, IRowNode } from 'ag-grid-community';
import {
  ChangeEventHandler,
  ReactElement,
  ReactNode,
  useCallback,
} from 'react';
import styled from 'styled-components';
import { glassSurface } from './GlassSurface';

export interface SelectCellRendererProps<TData = any, TValue = any>
  extends ICellRendererParams<TData, TValue> {
  options: { value: string; label: ReactNode }[];
  field: string;
  onChange: (data: TData, node: IRowNode<TData>) => void;
}

const Select = styled.select`
  ${glassSurface};
  padding: 4px 8px;
  color: rgba(255, 255, 255, 0.72);
  border: none;
`;

export function SelectCellRenderer(
  props: SelectCellRendererProps
): ReactElement {
  const handleOnChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (event) => {
      props.onChange(
        {
          ...props.data,
          [props.field]: event.target.value,
        },
        props.node
      );
    },
    [props]
  );

  return (
    <Select name={props.field} onChange={handleOnChange} value={props.value}>
      {props.options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
}
