import { Button, glassSurface } from '@event-horizon/app-components';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ConsoleMessagesPreview } from './ConsoleMessagesPreview';
import {
  IndicatorCardChart,
  IndicatorCardChartViewModel,
} from './IndicatorCardChart';
import { graphql } from '@event-horizon/app-graphql';
import { useQuery } from 'urql';
import { useApplication } from '@event-horizon/app-applications';
import { DateTime } from 'luxon';
import {
  Aggregation,
  IndicatorOperation,
  Percentile,
} from '@event-horizon/graphql-api-schema';
import { CircularProgress } from '@mui/material';

const Card = styled.div`
  ${glassSurface};
  height: 240px;
  width: 100%;
  display: grid;
  grid-template-rows: 56px 1fr 64px;

  .header {
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h3 {
      color: #fff;
      font-family: Lexend, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: none;
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    padding: 0 12px 0 16px;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.12);

    .average {
      color: #fff;
      font-family: Lexend, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;

      em {
        font-style: normal;
        opacity: 0.64;
      }
    }
  }
`;

const CenteredBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.54);
  font-style: italic;
  flex-direction: column;
  gap: 8px;
`;

export interface IndicatorCardViewModel {
  id: string;
  name: string;
  operation: IndicatorOperation;
  consoleMessages: {
    warn: number;
    error: number;
    info: number;
  };
}

export interface IndicatorCardProps {
  indicator: IndicatorCardViewModel;
  start: DateTime;
  end: DateTime;
  aggregation: Aggregation;
  percentile: Percentile;
}

const GetHistoryForIndicator = graphql(`
  query GetHistoryForIndicator(
    $orgId: ID!
    $appId: ID!
    $indicatorId: ID!
    $query: IndicatorHistoryQueryInput!
  ) {
    me {
      organization(id: $orgId) {
        app(id: $appId) {
          indicator(id: $indicatorId) {
            history(input: $query) {
              state
              start
              end
              result {
                __typename
                ... on AverageResult {
                  values {
                    percentile
                    value
                  }
                }
                ... on RateResult {
                  value
                }
                ... on CountResult {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`);

export function IndicatorCard({
  indicator,
  start,
  end,
  aggregation,
  percentile,
}: IndicatorCardProps) {
  const { application, organization } = useApplication();
  const [{ data: result, fetching }] = useQuery({
    query: GetHistoryForIndicator,
    variables: {
      appId: application.id,
      orgId: organization.id,
      indicatorId: indicator.id,
      query: {
        aggregation,
        start: start.toISO()!,
        end: end.toISO()!,
      },
    },
  });
  const data = useMemo((): IndicatorCardChartViewModel[] => {
    const indicator = result?.me?.organization?.app?.indicator;

    if (!indicator) return [];

    return indicator.history.map((history) => {
      if (history.result?.__typename === 'AverageResult') {
        const value = history.result.values.find(
          (value) => value.percentile === percentile
        )?.value;

        return {
          date: DateTime.fromISO(history.start),
          value: value ?? 0,
        };
      } else {
        return {
          date: DateTime.fromISO(history.start),
          value: history.result?.value ?? 0,
        };
      }
    });
  }, [result, percentile]);
  const averageValue = useMemo(() => {
    const values = data.map((d) => d.value);
    const sum = values.reduce((acc, value) => acc + value, 0);

    return Math.round(sum / values.length);
  }, [data]);
  const formattedValue = useMemo(() => {
    if (isNaN(averageValue)) return '';

    if (indicator.operation === IndicatorOperation.AVERAGE) {
      return `${averageValue} ms`;
    } else if (indicator.operation === IndicatorOperation.RATE) {
      return `${averageValue * 100}%`;
    } else {
      return `${averageValue}`;
    }
  }, [averageValue, indicator]);

  return (
    <Card>
      <div className="header">
        <h3>{indicator.name}</h3>
        <ConsoleMessagesPreview messages={indicator.consoleMessages} />
      </div>
      {fetching ? (
        <CenteredBox>
          <CircularProgress />
        </CenteredBox>
      ) : (
        <IndicatorCardChart data={data} />
      )}
      <div className="footer">
        <div className="average">{formattedValue}</div>
        <Button as={Link} to={`../indicator/${indicator.id}`}>
          Explore
        </Button>
      </div>
    </Card>
  );
}
