interface MeasurementQueueItem {
  id: string;
  phase: string;
  actualDuration: number;
}

const queue: MeasurementQueueItem[] = [];

export function getQueue() {
  return queue;
}

export function push(id: string, phase: string, actualDuration: number) {
  queue.push({ id, phase, actualDuration });
}

export function flush() {
  queue.splice(0, queue.length);
}
