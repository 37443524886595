import { ContentCopy } from '@mui/icons-material';
import { ReactElement, ReactNode, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { glassSurface } from './GlassSurface';

interface ClickToCopyProps {
  children: ReactNode;
}

const Button = styled.button`
  ${glassSurface};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 16px;
  overflow: hidden;

  > .inset {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(100%);
    transition: transform 0.2s ease-in-out;

    > div {
      display: flex;
      gap: 8px;
      align-items: center;
      padding: 8px 16px;
      border: 1px solid rgba(255, 255, 255, 0.12);
      border-radius: 4px;
      font-size: 11px;
    }
  }

  > pre {
    font-family: 'Operator Mono', monospace;
    font-size: 16px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.12);
    display: none;
  }

  &:hover {
    > .inset {
      transform: translateY(0);
    }

    &::before {
      display: block;
    }
  }
`;

export function ClickToCopy({ children }: ClickToCopyProps): ReactElement {
  const contentRef = useRef<HTMLPreElement>(null);
  const [text, setText] = useState<string>('Click To Copy');

  const handleOnClick = useCallback(async () => {
    const selection = getSelection();
    const range = document.createRange();
    range.selectNodeContents(contentRef.current!);
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
      await navigator.clipboard.writeText(selection.toString());
      setText('Copied!');
      selection.removeAllRanges();
      setTimeout(() => {
        setText('Click To Copy');
      }, 2000);
    }
  }, []);

  return (
    <Button onClick={handleOnClick} type="button">
      <div className="inset">
        <div>
          <ContentCopy fontSize="inherit" />
          {text}
        </div>
      </div>
      <pre ref={contentRef}>{children}</pre>
    </Button>
  );
}
