import { Button, glassSurface } from '@event-horizon/app-components';
import { useMemo } from 'react';
import styled from 'styled-components';
import { IndicatorCardChart } from './IndicatorCardChart';
import { graphql } from '@event-horizon/app-graphql';
import {
  Aggregation,
  IndicatorOperation,
  IndicatorWindow,
} from '@event-horizon/graphql-api-schema';
import { useMutation, useQuery } from 'urql';
import { useApplication } from '@event-horizon/app-applications';
import { DateTime } from 'luxon';

const PreviewIndicatorQuery = graphql(`
  query PreviewIndicator(
    $orgId: ID!
    $appId: ID!
    $input: PreviewIndicatorInput!
  ) {
    me {
      organization(id: $orgId) {
        app(id: $appId) {
          previewIndicator(input: $input) {
            history {
              start
              end
              state
              result {
                __typename
                ... on RateResult {
                  value
                }
                ... on AverageResult {
                  values {
                    percentile
                    value
                  }
                }
                ... on CountResult {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`);

const SaveSuggestedIndicatorMutation = graphql(`
  mutation SaveSuggestedIndicator($input: CreateIndicatorInput!) {
    createIndicator(input: $input) {
      id
    }
  }
`);

const Card = styled.div`
  ${glassSurface};
  width: 368px;
  height: 240px;
  display: grid;
  grid-template-rows: 56px 1fr 64px;

  .header {
    padding: 16px;
    display: flex;
    flex-direction: row;

    h3 {
      color: #fff;
      font-family: Lexend;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: none;
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
    padding: 0 12px 0 16px;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
`;

export interface SuggestedIndicatorCardViewModel {
  appId: string;
  name: string;
  operation: IndicatorOperation;
  predicateFunction: string;
  window: IndicatorWindow;
}

export interface SuggestedIndicatorCardProps {
  indicator: SuggestedIndicatorCardViewModel;
  onSave?: () => void;
}

export function SuggestedIndicatorCard({
  indicator,
  onSave,
}: SuggestedIndicatorCardProps) {
  const { application, organization } = useApplication();
  const now = useMemo(() => DateTime.now().toISODate()!, []);
  const yesterday = useMemo(
    () => DateTime.now().minus({ hours: 24 }).toISODate()!,
    []
  );
  const [{ data: result }] = useQuery({
    query: PreviewIndicatorQuery,
    variables: {
      orgId: organization.id,
      appId: application.id,
      input: {
        operation: indicator.operation,
        predicateFunction: indicator.predicateFunction,
        history: {
          aggregation: Aggregation.BY_HOUR,
          start: yesterday,
          end: now,
        },
      },
    },
  });
  const [{ data: saveResult }, saveSuggestedIndicator] = useMutation(
    SaveSuggestedIndicatorMutation
  );
  const chartData = useMemo(() => {
    const value = result?.me.organization?.app?.previewIndicator.history.map(
      (historyItem) => ({
        date: DateTime.fromISO(historyItem.start),
        value:
          (historyItem?.result?.__typename === 'RateResult'
            ? historyItem.result.value
            : historyItem?.result?.__typename === 'CountResult'
            ? historyItem.result.value
            : historyItem?.result?.values[0]?.value) ?? 0,
      })
    );

    return value || [];
  }, [result]);

  function onClickSaveButton() {
    saveSuggestedIndicator({
      input: {
        appId: application.id,
        name: indicator.name,
        operation: indicator.operation,
        predicateFunction: indicator.predicateFunction,
        window: indicator.window,
      },
    }).then(() => {
      if (onSave) {
        onSave();
      }
    });
  }

  return (
    <Card>
      <div className="header">
        <h3>{indicator.name}</h3>
      </div>
      {chartData.length > 0 ? <IndicatorCardChart data={chartData} /> : <div />}
      <div className="footer">
        <Button onClick={onClickSaveButton}>Save</Button>
      </div>
    </Card>
  );
}
