import { LinearProgress } from '@mui/material';
import { ReactElement } from 'react';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function Loading(): ReactElement {
  return (
    <LoadingContainer>
      <LinearProgress style={{ width: '100%', borderRadius: '4px' }} />
    </LoadingContainer>
  );
}
