import { CheckCircle } from '@mui/icons-material';
import { ReactElement, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { glassSurface } from './GlassSurface';
import { RadioGroupContext } from './RadioGroup';

interface OptionProps {
  children?: ReactElement;
  icon?: ReactElement;
  label: string;
  value: string;
}

const Label = styled.label<{ icon: boolean; selected: boolean }>`
  display: grid;
  grid-template-columns: ${({ icon }) => (icon ? '56px 1fr 56px' : '1fr 56px')};
  grid-template-rows: 56px;
  align-items: center;
  width: 100%;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.64);
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);

  &:last-child {
    border-bottom: none;
  }

  ${({ selected }) => (selected ? glassSurface : '')};
  --glass-border-opacity: 0%;
  --glass-border-radius: 0px;
`;

const IconContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 8px 16px;
  color: rgba(255, 255, 255, 0.72);
`;

const NameLabel = styled.span`
  font: 400 13px/16px 'Lexend', sans-serif;
`;

const NameDescription = styled.span`
  font: 400 11px/13px 'Lexend', sans-serif;
`;

const ValueContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckCircleIcon = styled(CheckCircle)<{ selected: boolean }>`
  height: 16px !important;
  width: 16px !important;

  color: ${({ selected }) => (selected ? '#fff' : 'rgba(255, 255, 255, 0.32)')};
`;

export function Option({
  children,
  icon,
  label,
  value,
}: OptionProps): ReactElement {
  const {
    name,
    onBlur,
    onChange,
    value: radioGroupValue,
  } = useContext(RadioGroupContext);
  const selected = useMemo(
    () => value === radioGroupValue,
    [radioGroupValue, value]
  );

  return (
    <Label icon={!!icon} selected={selected}>
      {icon && <IconContainer>{icon}</IconContainer>}
      <NameContainer>
        <NameLabel>{label}</NameLabel>
        {children && <NameDescription>{children}</NameDescription>}
      </NameContainer>
      <ValueContainer>
        <input
          type="radio"
          name={name}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          style={{ display: 'none' }}
        />
        <CheckCircleIcon selected={selected} />
      </ValueContainer>
    </Label>
  );
}
