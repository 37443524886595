import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

interface ListDetailProps {
  children: ReactNode | ReactNode[];
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  color: var(--color-slate-500);

  > svg {
    height: 13px;
    width: 13px;
  }
`;

export function ListDetail({ children }: ListDetailProps): ReactElement {
  return <Container>{children}</Container>;
}
