import { Danger, Drawer, Info, Loading } from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { DeleteAppInput } from '@event-horizon/graphql-api-schema';
import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation, useQuery } from 'urql';
import * as yup from 'yup';
import { useApplication } from './ApplicationOutlet';

const GetDeleteApplicationPage = graphql(`
  query GetDeleteApplicationPage($orgId: ID!, $appId: ID!) {
    me {
      id
      organization(id: $orgId) {
        id
        app(id: $appId) {
          id
          name
          objectives {
            id
          }
          indicators {
            id
          }
        }
      }
    }
  }
`);

const DeleteApp = graphql(`
  mutation DeleteApp($input: DeleteAppInput!) {
    deleteApp(input: $input)
  }
`);

const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

const ApplicationName = styled.pre`
  color: var(--color-warn-900);
  font-weight: 500;
  background: var(--color-warn-100);
  border-radius: 4px;
  padding: 4px 8px;
  margin: 0 auto 0 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export function DeleteApplicationPage(): ReactElement {
  const { application, organization } = useApplication();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [{ data }] = useQuery({
    query: GetDeleteApplicationPage,
    variables: {
      orgId: organization.id,
      appId: application.id,
    },
  });
  const [, deleteApp] = useMutation(DeleteApp);

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup.string().required().equals([application.name]),
      }),
    [application.name]
  );

  const { errors, handleChange, handleSubmit, isValid, touched, values } =
    useFormik({
      initialValues: {
        name: '',
      },
      validateOnMount: true,
      validationSchema: validationSchema,
      onSubmit: async () => {
        const input: DeleteAppInput = { appId: application.id };
        await deleteApp({ input: input });
        navigate(`/org/${organization.id}`);
      },
    });

  const handleOnClose = useCallback(() => {
    setOpen(false);
    navigate(-1);
  }, []);

  if (data) {
    if (!data.me.organization) {
      throw new Error('Organization not found');
    }
    if (!data.me.organization.app) {
      throw new Error('Application not found');
    }
  }

  return (
    <Drawer onClose={handleOnClose} open={open} title="Delete Application">
      <DrawerContainer>
        {!data && <Loading />}
        {data?.me.organization &&
          data.me.organization.app &&
          (data.me.organization.app.objectives.length > 0 ||
            data.me.organization.app.indicators.length > 0) && (
            <Info title={`Cannot delete ${application.name}`}>
              <p>
                To delete an application it must not have any objectives or
                indicators.
              </p>
              <p>
                Please remove all objectives and indicators from the application
                and try again.
              </p>
            </Info>
          )}
        {data?.me.organization &&
          data.me.organization.app &&
          data.me.organization.app.objectives.length === 0 &&
          data.me.organization.app.indicators.length === 0 && (
            <>
              <Danger title="Danger!">
                <p>
                  Deleting an application is a permanent action. To proceed, you
                  must confirm the deletion be entering the application name:
                </p>
                <ApplicationName>{application.name}</ApplicationName>
              </Danger>
              <Form onSubmit={handleSubmit}>
                <TextField
                  error={touched.name && Boolean(errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Application Name"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  variant="outlined"
                />
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  color="error"
                  disabled={!isValid}
                >
                  Delete Application
                </Button>
              </Form>
            </>
          )}
      </DrawerContainer>
    </Drawer>
  );
}
