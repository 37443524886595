import { createGlobalStyle } from 'styled-components';
import 'prism-themes/themes/prism-dracula.css';

export const GlobalStyle = createGlobalStyle`
  :root {
   --color-primary-50: #e2fbfa;
    --color-primary-100: #b7f5f2;
    --color-primary-200: #87eee9;
    --color-primary-300: #59e7e0;
    --color-primary-400: #32e2da;
    --color-primary-500: #10ddd3;
    --color-primary-600: #0fd9ce;
    --color-primary-700: #0ad4c8;
    --color-primary-800: #04cfc1;
    --color-primary-900: #03c7b7;

    --color-gray-50: #f9fafb;
    --color-gray-100: #f3f4f6;
    --color-gray-200: #e5e7eb;
    --color-gray-300: #d1d5db;
    --color-gray-400: #9ca3af;
    --color-gray-500: #6b7280;
    --color-gray-600: #4b5563;
    --color-gray-700: #374151;
    --color-gray-800: #1f2937;
    --color-gray-900: #111827;

    --color-slate-50: #f8fafc;
    --color-slate-100: #f1f5f9;
    --color-slate-200: #e2e8f0;
    --color-slate-300: #cbd5e1;
    --color-slate-400: #94a3b8;
    --color-slate-500: #64748b;
    --color-slate-600: #475569;
    --color-slate-700: #334155;
    --color-slate-800: #1e293b;
    --color-slate-900: #0f172a;

    --color-neutral-50: #fafafa;
    --color-neutral-100: #f5f5f5;
    --color-neutral-200: #e5e5e5;
    --color-neutral-300: #d4d4d4;
    --color-neutral-400: #a3a3a3;
    --color-neutral-500: #737373;
    --color-neutral-600: #525252;
    --color-neutral-700: #404040;
    --color-neutral-800: #262626;
    --color-neutral-900: #171717;

    --color-accent-50: #eceefe;
    --color-accent-100: #cfd6fd;
    --color-accent-200: #afbafc;
    --color-accent-300: #8f9efb;
    --color-accent-400: #788afb;
    --color-accent-500: #5f75f9;
    --color-accent-600: #576df9;
    --color-accent-700: #4d62f7;
    --color-accent-800: #4358f6;
    --color-accent-900: #3245f5;

    --color-success-50: #f3faec;
    --color-success-100: #e1f2cf;
    --color-success-200: #cdeaaf;
    --color-success-300: #b8e18f;
    --color-success-400: #a9da77;
    --color-success-500: #9ad45f;
    --color-success-600: #92cf57;
    --color-success-700: #88c94d;
    --color-success-800: #7ec343;
    --color-success-900: #6cb932;
    --color-success-A100: #ffffff;
    --color-success-A200: #e1ffcd;
    --color-success-A400: #c3ff9a;
    --color-success-A700: #b4ff80;

    --color-warn-50: #fbeceb;
    --color-warn-100: #f6cecc;
    --color-warn-200: #f0aeaa;
    --color-warn-300: #e98e88;
    --color-warn-400: #e5756f;
    --color-warn-500: #e05d55;
    --color-warn-600: #dc554e;
    --color-warn-700: #d84b44;
    --color-warn-800: #d3413b;
    --color-warn-900: #cb302a;
    --color-warn-A100: #ffffff;
    --color-warn-A200: #ffd9d8;
    --color-warn-A400: #ffa8a5;
    --color-warn-A700: #ff8f8b;
  }

  @font-face {
    font-family: 'Singolare';
    src: url('/fonts/singolare-100.woff2') format('woff2'),
    url('/fonts/singolare-100.woff') format('woff');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'Singolare';
    src: url('/fonts/singolare-300.woff2') format('woff2'),
    url('/fonts/singolare-300.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Singolare';
    src: url('/fonts/singolare-400.woff2') format('woff2'),
    url('/fonts/singolare-400.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Singolare';
    src: url('/fonts/singolare-700.woff2') format('woff2'),
    url('/fonts/singolare-700.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Singolare';
    src: url('/fonts/singolare-900.woff2') format('woff2'),
    url('/fonts/singolare-900.woff') format('woff');
    font-weight: 900;
    font-style: normal;
  }

  * {
    box-sizing: border-box;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  html {
    height: 100%;
  }

  body {
    background: #101016;
    color: #fff;
    font-family: 'Lexend', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.005em;
    height: 100%;
  }

  #root {
    height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Singolare', sans-serif;
    text-transform: uppercase;
  }

  pre {
    font-family: 'Operator Mono', monospace;
  }

  pre[class*="language-"] {
    background: none;
    margin: 0;
    padding: 0;
    border-radius: unset;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button {
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    outline: none;
    color: inherit;
    font-family: 'Lexend', sans-serif;
  }

  .eh-drawer {
    background: var(--color-slate-900) !important;
    width: 100%;
  }

  @media(min-width: 960px) {
    .eh-drawer {
      width: 600px;
    }
  }

  @media(min-width: 1280px) {
    .eh-drawer {
      width: 960px;
    }
  }
`;
