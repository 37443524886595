import { useApplication } from '@event-horizon/app-applications';
import {
  Button,
  CannedDateRange,
  Loading,
  MeasurementBudget,
  PageContainer,
  TechnologyList,
} from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import {
  Aggregation,
  Sdk,
  UpdateAppInput,
} from '@event-horizon/graphql-api-schema';
import { DateTime } from 'luxon';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation, useQuery } from 'urql';
import { ConsumptionHistory } from './ConsumptionHistory';
import { ApplicationStatistics } from './ApplicationStatistics';

const GetApplicationPage = graphql(`
  query GetApplicationPage(
    $orgId: ID!
    $appId: ID!
    $input: ConsumptionQueryInput!
  ) {
    me {
      id
      organization(id: $orgId) {
        id
        name
        app(id: $appId) {
          id
          name
          measurementBudget
          consumptionHistory(input: $input) {
            start
            end
            measurements
          }
          objectives {
            id
            name
            state
            stateLastUpdated
          }
          indicators {
            id
          }
          webhooks {
            url
          }
        }
      }
    }
  }
`);

const UpdateAppSdk = graphql(`
  mutation UpdateAppSdk($input: UpdateAppInput!) {
    updateApp(input: $input) {
      id
      sdk
    }
  }
`);

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 24px;
  padding: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const ApplicationOverview = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
`;

const NOW = DateTime.now();

export function ApplicationOverviewPage(): ReactElement {
  const { application, organization } = useApplication();
  const [aggregation, setAggregation] = useState<Aggregation>(
    Aggregation.BY_DAY
  );
  const [start, setStart] = useState<DateTime>(
    NOW.startOf('hour').minus({ month: 1 })
  );
  const [end, setEnd] = useState<DateTime>(NOW.startOf('hour'));
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [, updateAppSdk] = useMutation(UpdateAppSdk);

  const handleOnDateRangeChange = useCallback(
    (start: DateTime, end: DateTime, aggregation: Aggregation) => {
      searchParams.set('aggregation', aggregation);
      searchParams.set('start', start.toISODate()!);
      searchParams.set('end', end.toISODate()!);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const handleSdkOnClick = useCallback(
    async (sdk: Sdk) => {
      const input: UpdateAppInput = {
        appId: application.id,
        name: application.name,
        webhooks: application.webhooks as unknown as string[],
        sdk: sdk,
      };
      await updateAppSdk({ input });
      navigate('../getting-started');
    },
    [application, navigate, updateAppSdk]
  );

  const [{ data }] = useQuery({
    query: GetApplicationPage,
    variables: {
      appId: application.id,
      orgId: organization.id,
      input: {
        end: end.toISO()!,
        start: start.toISO()!,
        aggregation: aggregation,
      },
    },
  });

  useEffect(() => {
    if (searchParams.has('aggregation')) {
      setAggregation((searchParams.get('aggregation') as Aggregation) ?? '');
    }
    if (searchParams.has('start')) {
      setStart(DateTime.fromISO(searchParams.get('start') ?? ''));
    }
    if (searchParams.has('end')) {
      setEnd(DateTime.fromISO(searchParams.get('end') ?? ''));
    }
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.has('aggregation')) {
      searchParams.set('aggregation', aggregation);
    }
    if (!searchParams.has('start')) {
      searchParams.set('start', start.toISODate()!);
    }
    if (!searchParams.has('end')) {
      searchParams.set('end', end.toISODate()!);
    }
    setSearchParams(searchParams, { replace: true });
  }, []);

  if (!data) {
    return <Loading />;
  }

  if (!data.me.organization) {
    throw new Error('Organization not found');
  }
  if (!data.me.organization.app) {
    throw new Error('Application not found');
  }

  return (
    <PageContainer>
      <Controls>
        <Actions>
          <Button as={Link} to={`../getting-started`}>
            Getting Started
          </Button>
          <CannedDateRange
            start={start}
            end={end}
            now={NOW}
            onChange={handleOnDateRangeChange}
          />
          <Button
            as={Link}
            to={`/org/${data.me.organization.id}/app/${data.me.organization.app.id}/payments/add`}
            color="primary"
            raised
          >
            Add to Balance
          </Button>
        </Actions>
      </Controls>
      <ApplicationOverview>
        {data.me.organization.app.indicators.length === 0 ? (
          <TechnologyList onClick={handleSdkOnClick} />
        ) : (
          <>
            <MeasurementBudget
              app={data.me.organization.app}
              organization={data.me.organization}
            />
            <ApplicationStatistics
              indicators={data.me.organization.app.indicators}
              objectives={data.me.organization.app.objectives}
              webhooks={data.me.organization.app.webhooks}
            />
            <ConsumptionHistory
              aggregation={aggregation}
              consumptionHistory={data.me.organization.app.consumptionHistory}
            />
          </>
        )}
      </ApplicationOverview>
    </PageContainer>
  );
}
