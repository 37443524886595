import { useApplication } from '@event-horizon/app-applications';
import { Drawer } from '@event-horizon/app-components';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { PaymentIntent } from './PaymentIntent';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const DrawerContainer = styled.div`
  flex: 1 auto;
  display: flex;
  flex-direction: column;
`;

export function PaymentReturnPage(): ReactElement {
  const { application, organization } = useApplication();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [searchParams] = useSearchParams();

  const clientSecret = useMemo(
    () => searchParams.get('payment_intent_client_secret') ?? undefined,
    [searchParams]
  );

  const close = useCallback(() => {
    setDrawerOpen(false);
    navigate(`/org/${organization.id}/app/${application.id}/payments`);
  }, [application.id, navigate, organization.id]);

  return (
    <Drawer onClose={close} open={drawerOpen} title="Add to Balance">
      <DrawerContainer>
        <Elements options={{ clientSecret }} stripe={stripePromise}>
          <PaymentIntent clientSecret={clientSecret} />
        </Elements>
      </DrawerContainer>
    </Drawer>
  );
}
