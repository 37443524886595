import { useApplication } from '@event-horizon/app-applications';
import { AlertDialog, Button, Drawer } from '@event-horizon/app-components';
import { Check } from '@mui/icons-material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { ReactElement, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from 'urql';
import {
  PaymentAmountForm,
  PaymentAmountFormValues,
} from './PaymentAmountForm';
import { PaymentForm } from './PaymentForm';
import { graphql } from '@event-horizon/app-graphql';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const GetCreatePaymentPage = graphql(`
  query GetCreatePaymentPage {
    me {
      id
      email
      name
    }
  }
`);

const DrawerContainer = styled.div`
  flex: 1 auto;
  display: flex;
  flex-direction: column;
`;

const Info = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: rgba(255, 255, 255, 0.72);
  font: 400 13px/16px 'Lexend', sans-serif;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);

  > ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
    list-style: none;

    > li {
      display: flex;
      gap: 8px;
      align-items: center;

      > svg {
        height: 16px;
        width: 16px;
        color: var(--color-primary-600);
      }
    }
  }
`;

export function CreatePaymentPage(): ReactElement {
  const { application, organization } = useApplication();
  const navigate = useNavigate();
  const [amount, setAmount] = useState<number | undefined>();
  const [alertOpen, setAlertOpen] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [dirty, setDirty] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);

  const [{ fetching, data }] = useQuery({
    query: GetCreatePaymentPage,
  });

  const close = useCallback(() => {
    setDrawerOpen(false);
    navigate(-1);
  }, [navigate, setDrawerOpen]);

  const handleOnClose = useCallback(() => {
    if (dirty) {
      setAlertOpen(true);
      return;
    }
    close();
  }, [close, dirty, setAlertOpen]);

  const handleOnSubmit = useCallback(
    (values: PaymentAmountFormValues) => {
      const amount = Number(values.amount);
      if (isNaN(amount) || amount <= 0) {
        return;
      }
      setAmount(amount);
      const body = {
        amount: amount * 100,
        appId: application.id,
        user: data?.me,
      };
      fetch(`${import.meta.env.VITE_POLARIS_API_URL}/payments/intent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret));
    },
    [application.id, data?.me]
  );

  const options: StripeElementsOptions = {
    clientSecret,
    appearance: {
      theme: 'night',
      variables: {
        colorPrimary: '#10ddd3',
        colorBackground: '#131723',
      },
    },
  };

  return (
    <>
      <Drawer onClose={handleOnClose} open={drawerOpen} title="Add to Balance">
        <DrawerContainer>
          <Info>
            {amount ? (
              <p>
                You are purchasing{' '}
                <span>
                  {new Intl.NumberFormat('en-us', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(amount)}
                </span>{' '}
                USD in credits.
              </p>
            ) : (
              <p>Purchase additional credits.</p>
            )}
            <ul>
              <li>
                <Check />
                <span>$1 per 1k measurements</span>
              </li>
              <li className="flex gap-2">
                <Check />
                <span>Unlimited applications</span>
              </li>
              <li className="flex gap-2">
                <Check />
                <span>Unlimited objectives</span>
              </li>
              <li className="flex gap-2">
                <Check />
                <span>Unlimited indicators</span>
              </li>
              <li className="flex gap-2">
                <Check />
                <span>Unlimited real-time notifications</span>
              </li>
            </ul>
          </Info>
          {!clientSecret && (
            <PaymentAmountForm app={application} onSubmit={handleOnSubmit} />
          )}
          {clientSecret && amount && (
            <Elements options={options} stripe={stripePromise}>
              <PaymentForm
                amount={amount}
                app={application}
                organization={organization}
              />
            </Elements>
          )}
        </DrawerContainer>
      </Drawer>
      <AlertDialog
        actions={
          <>
            <Button onClick={() => setAlertOpen(false)}>No</Button>
            <Button color="warn" onClick={close}>
              Yes
            </Button>
          </>
        }
        description="Are you sure you want to cancel?"
        onClose={() => setAlertOpen(false)}
        open={alertOpen}
        title="Cancel?"
      />
    </>
  );
}
