import { Drawer as MuiDrawer, Icon, IconButton } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { glassSurface } from './GlassSurface';

const StyledDrawer = styled(MuiDrawer)`
  & .MuiDrawer-paper {
    width: 420px;
    ${glassSurface};
    --glass-border-radius: 0;
  }
`;

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  width: 100%;
  height: 72px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  flex-shrink: 0;

  > span {
    color: #fff;
    font-family: Lexend, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
`;

export function Drawer(props: {
  children: ReactNode;
  onClose?: () => void;
  open: boolean;
  title: string;
}): ReactElement {
  return (
    <StyledDrawer
      anchor="right"
      variant="temporary"
      open={props.open}
      onClose={props.onClose}
    >
      <DrawerHeader>
        <span>{props.title}</span>
        <IconButton onClick={props.onClose}>
          <Icon>close</Icon>
        </IconButton>
      </DrawerHeader>
      {props.children}
    </StyledDrawer>
  );
}
