import {
  Fab,
  List,
  ListItem,
  ListRow,
  Loading,
  StatusPillBox,
  UserMenu,
} from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { Aggregation, ObjectiveState } from '@event-horizon/graphql-api-schema';
import { Add, ChevronRight } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from 'urql';
import { SignUp } from './SignUp';

const GetOrganizationsPage = graphql(`
  query GetOrganizationsPage($objectiveQuery: ObjectiveHistoryQueryInput!) {
    me {
      id
      name
      email
      organizations {
        id
        name
        logo
        apps {
          id
          name
          objectives {
            history(input: $objectiveQuery) {
              start
              end
              state
            }
          }
        }
      }
    }
  }
`);

const Container = styled.div`
  height: 100%;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background: url('/backgrounds/starfield.svg');
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;

const FabWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;

  @media (min-width: 960px) {
    padding: 24px;
  }
`;

const Aurora = styled.div`
  height: 100%;
  max-width: 960px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 auto;

  &::before {
    content: '';
    position: absolute;
    background: url('/backgrounds/aurora.svg') no-repeat;
    background-size: contain;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.12;
    z-index: -1;
  }
`;

const Menu = styled.div`
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  height: 40px;
  width: 40px;
`;

const Organizations = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  gap: 16px;
`;

const Organization = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
`;

const OrganizationTitle = styled.h2`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 22px;
  font-weight: 700;
`;

const OrganizationLogo = styled.img`
  height: 40px;
  width: 40px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 24px 16px;
`;

const ListRowLeft = styled.span``;

const ListRowRight = styled.span`
  display: grid;
  grid-template-columns: 200px auto;
  align-items: center;
  gap: 16px;
`;

const NOW = DateTime.now();

export function OrganizationsPage() {
  const [{ data }] = useQuery({
    query: GetOrganizationsPage,
    variables: {
      objectiveQuery: {
        start: NOW.minus({ hours: 24 }).toISO()!,
        end: NOW.toISO()!,
        aggregation: Aggregation.BY_HOUR,
      },
    },
  });

  const history = useMemo(() => {
    if (!data) {
      return null;
    }
    return data.me.organizations.reduce((prev, org) => {
      prev[org.id] = org.apps.reduce((prev, app) => {
        prev[app.id] = app.objectives.reduce((prev, objective) => {
          prev = objective.history.reduce((prev, history) => {
            if (prev[history.start]) {
              if (
                prev[history.start] === ObjectiveState.PASSING &&
                history.start === ObjectiveState.FAILING
              ) {
                prev[history.start] = ObjectiveState.FAILING;
              } else if (
                prev[history.start] === ObjectiveState.FAILING &&
                history.start === ObjectiveState.PASSING
              ) {
                prev[history.start] = ObjectiveState.PASSING;
              } else if (prev[history.start] === ObjectiveState.UNKNOWN) {
                prev[history.start] = history.state;
              }
              return prev;
            }
            prev[history.start] = history.state;
            return prev;
          }, {} as Record<string, ObjectiveState>);
          return prev;
        }, {} as Record<string, any>);
        return prev;
      }, {} as Record<string, Record<string, ObjectiveState>>);
      return prev;
    }, {} as Record<string, Record<string, Record<string, ObjectiveState>>>);
  }, [data]);

  if (!data) {
    return <Loading />;
  }

  return (
    <>
      <Container>
        <Aurora>
          <Menu>
            <Logo src="/icons/polaris-c.svg" />
            <UserMenu
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              user={{
                email: data.me.email,
                name: data.me.name,
              }}
            />
          </Menu>
          {data.me.organizations.length === 0 && <SignUp />}
          {data.me.organizations.length > 0 && (
            <Organizations>
              {data.me.organizations.map((org) => (
                <Organization key={org.id}>
                  <OrganizationTitle>
                    {org.logo && (
                      <OrganizationLogo
                        style={{ backgroundImage: `url('${org.logo}'` }}
                      />
                    )}
                    {org.name}
                  </OrganizationTitle>
                  <List>
                    {org.apps.map((app) => (
                      <ListItem key={app.id}>
                        <ListRow
                          as={Link}
                          to={`/org/${org.id}/app/${app.id}/overview`}
                          style={{ height: `64px` }}
                        >
                          <ListRowLeft>{app.name}</ListRowLeft>
                          <ListRowRight>
                            {history && (
                              <StatusPillBox
                                history={history[org.id][app.id]}
                              />
                            )}
                            <ChevronRight />
                          </ListRowRight>
                        </ListRow>
                      </ListItem>
                    ))}
                  </List>
                </Organization>
              ))}
            </Organizations>
          )}
        </Aurora>
      </Container>
      {data.me.organizations.length > 0 && (
        <FabWrapper>
          <Tooltip title="Create Application" enterDelay={350}>
            <Fab as={NavLink} to="./add">
              <Add />
            </Fab>
          </Tooltip>
        </FabWrapper>
      )}
    </>
  );
}
