import { setApiKey } from './config/api-key';
import { setApiUrl } from './config/api-url';
import { testConnection } from './test-connection';
import { ConnectionOptions } from './types/connection-options';
import webVitals from './performance/web-vitals';

export const createConnection = async (
  connectionOptions: ConnectionOptions
): Promise<void> => {
  setApiKey(connectionOptions.apiKey);

  if (connectionOptions.apiUrl) {
    setApiUrl(connectionOptions.apiUrl);
  }

  await testConnection();

  if (connectionOptions.collectWebVitals) {
    webVitals();
  }
};
