import styled from 'styled-components';
import { glassSurface } from './GlassSurface';

export interface FabProps {
  variant?: 'regular' | 'small';
  extended?: boolean;
}

export const Fab = styled.button<FabProps>`
  ${glassSurface};
  --glass-border-radius: 64px;
  --glass-background-color-a: rgba(16, 221, 211, 0.56);
  --glass-background-color-b: rgba(95, 117, 249, 0.56);
  --glass-background-color-c: rgba(181, 87, 255, 0.56);
  width: ${(props) => (props.variant === 'small' ? '40px' : '64px')};
  height: ${(props) => (props.variant === 'small' ? '40px' : '64px')};
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  color: black;
  transition: --glass-background-color-a 0.3s ease-in-out,
    --glass-background-color-b 0.3s ease-in-out,
    --glass-background-color-c 0.3s ease-in-out;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 516px;
    z-index: -1;
    opacity: 0.24;
    background: radial-gradient(
      circle at 0% 0%,
      rgba(16, 221, 211, 0.24) 0%,
      rgba(95, 117, 249, 0.24) 75%,
      rgba(181, 87, 255, 0.24) 100%
    );
    filter: ${(props) =>
      props.variant === 'small' ? 'blur(8px)' : 'blur(16px)'};
    transition: opacity 0.3s ease-in-out, filter 0.3s ease-in-out;
  }

  &:hover {
    --glass-background-color-a: rgba(16, 221, 211, 0.72);
    --glass-background-color-b: rgba(95, 117, 249, 0.72);
    --glass-background-color-c: rgba(181, 87, 255, 0.72);

    &:before {
      opacity: 0.56;
      filter: ${(props) =>
        props.variant === 'small' ? 'blur(16px)' : 'blur(24px)'};
    }
  }
`;
