import { css } from 'styled-components';

if (
  typeof window !== 'undefined' &&
  'CSS' in window &&
  window.CSS &&
  'paintWorklet' in CSS
) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  CSS.paintWorklet.addModule('/worklets/gradient-border.js');
}

export const gradientBorder = css`
  border-radius: var(--glass-border-radius);
  background: paint(gradientBorder);
  --glass-border-radius: 4px;
  --glass-border-opacity: 12%;
`;
