import { Button, Input } from '@event-horizon/app-components';
import { Help } from '@mui/icons-material';
import { FormikHelpers, useFormik } from 'formik';
import { ReactElement, useEffect } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

export interface InviteFormValues {
  email: string;
}

interface InviteFormProps {
  onClose: () => void;
  onDirty: (dirty: boolean) => void;
  onSubmit: (
    values: InviteFormValues,
    formikHelpers: FormikHelpers<InviteFormValues>
  ) => void | Promise<any>;
  submitText?: string;
}

const Info = styled.div`
  padding: 24px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 24px;
  color: rgba(255, 255, 255, 0.72);
  font: 400 13px/16px 'Lexend', sans-serif;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);

  > a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.72);
  }
`;

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
`;

const Spacer = styled.div`
  flex: 1 auto;
`;

const Actions = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 24px;
`;

export function InviteForm(props: InviteFormProps): ReactElement {
  const validationSchema = yup.object({
    email: yup.string().email().required(),
  });

  const {
    dirty,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    touched,
    values,
  } = useFormik({
    initialValues: {
      email: '',
    },
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: props.onSubmit,
  });

  useEffect(() => {
    props.onDirty(dirty);
  }, [dirty, props.onDirty]);

  return (
    <>
      <Info>
        <Help />
        <p>
          Invite a new user to join your organization. They will receive an
          email with a link to sign in.
        </p>
      </Info>
      <Form onSubmit={handleSubmit}>
        <FormBody>
          <Input
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            label="Email"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email}
          />
        </FormBody>
        <Spacer />
        <Actions>
          <Button type="button" onClick={props.onClose}>
            Cancel
          </Button>
          <Button type="submit" disabled={!isValid} raised>
            Send Invite
          </Button>
        </Actions>
      </Form>
    </>
  );
}
