import { Aggregation } from '@event-horizon/graphql-api-schema';
import { ArrowDropDown, CalendarMonth } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { DateTime } from 'luxon';
import { MouseEvent, useMemo, useState } from 'react';
import { Button } from './Button';
import { GlassMenu } from './GlassMenu';

export interface CannedDateRangeProps {
  start: DateTime;
  end: DateTime;
  now: DateTime;
  onChange: (
    startDate: DateTime,
    endDate: DateTime,
    aggregation: Aggregation
  ) => void;
}

const LABELS = ['24h', '1w', '1m'] as const;

export function CannedDateRange({
  start,
  end,
  now,
  onChange,
}: CannedDateRangeProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const open = Boolean(anchorEl);

  const endDate = useMemo(() => now.startOf('hour'), [now]);
  const values = useMemo(() => {
    return [
      [endDate.minus({ days: 1 }), endDate, Aggregation.BY_HOUR],
      [endDate.minus({ days: 7 }), endDate, Aggregation.BY_DAY],
      [endDate.minus({ month: 1 }), endDate, Aggregation.BY_DAY],
    ] as [DateTime, DateTime, Aggregation][];
  }, [endDate]);

  const selected = useMemo(() => {
    return values.findIndex(([startDate, endDate]) => {
      return start.hasSame(startDate, 'day') && end.hasSame(endDate, 'day');
    });
  }, [start, end, values]);

  const label = useMemo(() => {
    if (selected > -1) {
      return LABELS[selected];
    }
    return `${start.toLocaleString({
      month: 'short',
      day: 'numeric',
    })} - ${end.toLocaleString({ month: 'short', day: 'numeric' })}`;
  }, [selected, start, end]);

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="canned-date-range-menu-button"
        aria-controls={open ? 'canned-date-range-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleMenuClick}
        raised
        color="secondary"
      >
        <CalendarMonth />
        {label}
        <ArrowDropDown />
      </Button>

      <GlassMenu
        id="canned-date-range-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'canned-date-range-menu-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {values.map(([start, end, aggregation], index) => (
          <MenuItem
            key={index}
            onClick={() => {
              onChange(start, end, aggregation);
              handleMenuClose();
            }}
            selected={selected === index}
          >
            {LABELS[index]}
          </MenuItem>
        ))}
      </GlassMenu>
    </>
  );
}
