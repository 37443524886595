import { useApplication } from '@event-horizon/app-applications';
import {
  CannedDateRange,
  Fab,
  Ghost,
  Input,
  PageContainer,
} from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { Aggregation, ObjectiveState } from '@event-horizon/graphql-api-schema';
import { Add, Bolt, Search } from '@mui/icons-material';
import { InputAdornment, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, Outlet, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from 'urql';
import { ObjectiveCard, ObjectiveCardViewModel } from './ObjectiveCard';

const GetObjectivesPage = graphql(`
  query GetObjectivesPage(
    $orgId: ID!
    $appId: ID!
    $query: ObjectiveHistoryQueryInput!
  ) {
    me {
      id
      organization(id: $orgId) {
        id
        name
        logo
        app(id: $appId) {
          id
          name
          objectives {
            id
            name
            percentile
            state
            history(input: $query) {
              start
              end
              state
            }
            threshold {
              ... on LowerThreshold {
                __typename
                lte
              }
              ... on BoundedThreshold {
                __typename
                lte
                gte
              }
              ... on UpperThreshold {
                __typename
                gte
              }
            }
          }
        }
      }
    }
  }
`);

const FabWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;

  @media (min-width: 960px) {
    padding: 24px;
  }
`;

const GroupHeader = styled.h2`
  color: rgba(255, 255, 255, 0.87);
  font-family: Singolare, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  padding: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
`;

const FilterInput = styled(Input)`
  width: 100%;
  max-width: 300px;
`;

const ControlActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const FetchingFilteredObjectives = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding: 24px;
`;

const FilteredObjectives = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding: 24px;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CardGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @media (min-width: 960px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
`;

const NOW = DateTime.now();

export function ObjectivesPage() {
  const { application, organization } = useApplication();
  const [aggregation, setAggregation] = useState<Aggregation>(
    Aggregation.BY_HOUR
  );
  const [start, setStart] = useState<DateTime>(
    NOW.minus({ hours: 24 }).set({ minute: 0, second: 0, millisecond: 0 })
  );
  const [end, setEnd] = useState<DateTime>(
    NOW.set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
  );
  const [query, setQuery] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [{ data, fetching }] = useQuery({
    query: GetObjectivesPage,
    variables: {
      appId: application.id,
      orgId: organization.id,
      query: {
        start: start.toISO()!,
        end: end.toISO()!,
        aggregation: aggregation,
      },
    },
  });

  const objectivesByGroup = useMemo(() => {
    return (
      data?.me.organization?.app?.objectives.reduce((acc, objective) => {
        const [groupName, objectiveName] = objective.name.split('/');
        const group = acc.find(([name]) => name === groupName);
        const viewModel: ObjectiveCardViewModel = {
          id: objective.id,
          name: objectiveName ?? objective.name,
          percentile: objective.percentile,
          state: objective.state,
          threshold: objective.threshold,
          history: objective.history.reduce(
            (prev, history) => ({
              ...prev,
              [history.start]: history.state,
            }),
            {} as Record<string, ObjectiveState>
          ),
        };
        if (group) {
          group[1].push(viewModel);
        } else {
          acc.push([groupName, [viewModel]]);
        }
        return acc;
      }, [] as [name: string, objectives: ObjectiveCardViewModel[]][]) ?? []
    );
  }, [data]);

  const filteredObjectives = useMemo(() => {
    if (query.length === 0) return objectivesByGroup;
    const regEx = new RegExp(`${query}`, 'i');
    return objectivesByGroup.map(([group, objectives]) => {
      return [
        group,
        objectives.filter((objective) => {
          return (
            objective.name.search(regEx) > -1 ||
            objective.state.search(regEx) > -1
          );
        }),
      ] as [string, ObjectiveCardViewModel[]];
    });
  }, [objectivesByGroup, query]);

  const handleOnDateRangeChange = useCallback(
    (start: DateTime, end: DateTime, aggregation: Aggregation) => {
      searchParams.set('aggregation', aggregation);
      searchParams.set('start', start.toISODate()!);
      searchParams.set('end', end.toISODate()!);
      setSearchParams(searchParams);
    },
    []
  );

  const handleOnQueryChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      searchParams.set('query', event.target.value);
      setSearchParams(searchParams);
    },
    []
  );

  useEffect(() => {
    if (searchParams.has('aggregation')) {
      setAggregation((searchParams.get('aggregation') as Aggregation)!);
    }
    if (searchParams.has('query')) {
      setQuery(searchParams.get('query')!);
    }
    if (searchParams.has('start')) {
      setStart(DateTime.fromISO(searchParams.get('start')!));
    }
    if (searchParams.has('end')) {
      setEnd(DateTime.fromISO(searchParams.get('end')!));
    }
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.has('aggregation')) {
      searchParams.set('aggregation', aggregation);
    }
    if (!searchParams.has('start')) {
      searchParams.set('start', start.toISODate()!);
    }
    if (!searchParams.has('end')) {
      searchParams.set('end', end.toISODate()!);
    }
    setSearchParams(searchParams);
  }, []);

  return (
    <PageContainer>
      <Controls>
        <FilterInput
          label="Filter"
          variant="outlined"
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          value={query}
          onChange={handleOnQueryChange}
        />
        <ControlActions>
          <CannedDateRange
            start={start}
            end={end}
            now={NOW}
            onChange={handleOnDateRangeChange}
          />
        </ControlActions>
      </Controls>
      {fetching && (
        <FetchingFilteredObjectives>
          <Group>
            <Ghost height="24px" width="24%" />
            <CardGroup>
              <Ghost height="148px" />
              <Ghost height="148px" />
              <Ghost height="148px" />
            </CardGroup>
          </Group>
          <Group>
            <Ghost height="24px" width="36%" />
            <CardGroup>
              <Ghost height="148px" />
              <Ghost height="148px" />
            </CardGroup>
          </Group>
        </FetchingFilteredObjectives>
      )}
      {!fetching && (
        <FilteredObjectives>
          {filteredObjectives
            .filter(([, objectives]) => objectives.length > 0)
            .map(([group, objectives]) => (
              <Group key={group}>
                <GroupHeader>{group}</GroupHeader>
                <CardGroup>
                  {objectives.map((objective) => (
                    <ObjectiveCard
                      key={objective.id}
                      objective={objective}
                      start={start}
                      end={end}
                    />
                  ))}
                </CardGroup>
              </Group>
            ))}
        </FilteredObjectives>
      )}
      <Outlet />
      <FabWrapper>
        <Tooltip title="Generate Objectives with AI" enterDelay={350}>
          <Fab as={NavLink} to="./suggest" variant="small">
            <Bolt />
          </Fab>
        </Tooltip>
        <Tooltip title="Create Objective" enterDelay={350}>
          <Fab as={NavLink} to="./add">
            <Add />
          </Fab>
        </Tooltip>
      </FabWrapper>
    </PageContainer>
  );
}
