import { chartDefaults } from '@event-horizon/app-chart';
import { Color, createTheme, ThemeProvider } from '@mui/material';
import { ReactNode, useEffect, useMemo } from 'react';
import styled from 'styled-components';

const Main = styled.main`
  height: 100%;
`;

function Layout(props: { children: ReactNode }) {
  const getDesignTokens = () => {
    const primary: Partial<Color> = {
      50: '#e2fbfa',
      100: '#b7f5f2',
      200: '#87eee9',
      300: '#59e7e0',
      400: '#32e2da',
      500: '#10ddd3',
      600: '#0fd9ce',
      700: '#0ad4c8',
      800: '#04cfc1',
      900: '#03c7b7',
      A400: '#32e2da',
    };

    const secondary: Partial<Color> = {
      50: '#f6ebff',
      100: '#e9cdff',
      200: '#daabff',
      300: '#cb89fe',
      400: '#c070fe',
      500: '#b557ff',
      600: '#ae4ffe',
      700: '#a546fe',
      800: '#9e3cfe',
      900: '#8d2cfd',
      A400: '#c070fe',
    };

    return {
      palette: {
        mode: 'dark' as const,
        primary,
        secondary,
        background: {
          default: '#020408',
          paper: 'var(--color-gray-900)',
        },
        error: {
          main: '#dc554e',
        },
        warning: {
          main: '#fcc67a',
        },
        info: {
          main: '#475569',
        },
        success: {
          main: '#7ec343',
        },
      },
      shape: {
        borderRadius: 8,
      },
      typography: {
        fontFamily: ['Lexend', 'sans-serif'].join(','),
      },
    };
  };

  const theme = useMemo(() => createTheme(getDesignTokens()), []);

  useEffect(() => {
    chartDefaults();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Main>{props.children}</Main>
    </ThemeProvider>
  );
}

export default Layout;
