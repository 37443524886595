import {
  Fab,
  glassSurface,
  GridWrapper,
  Input,
  PageContainer,
} from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { useOrganization } from '@event-horizon/app-organizations';
import { Add, Search } from '@mui/icons-material';
import { InputAdornment, Tooltip } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { DateTime } from 'luxon';
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { NavLink, Outlet, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from 'urql';

const GetOrganizationInvitesPage = graphql(`
  query GetOrganizationInvitesPage($orgId: ID!) {
    me {
      id
      organization(id: $orgId) {
        id
        invitesConnection {
          edges {
            node {
              email
              createdAt
            }
          }
        }
      }
    }
  }
`);

type TData = {
  createdAt: string;
  email: string;
};

const FabWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;

  @media (min-width: 960px) {
    padding: 24px;
  }
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  padding: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
`;

const OrganizationInvites = styled.div`
  flex: 1 auto;
  padding: 24px;
`;

const Card = styled.div`
  ${glassSurface};
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

const Header = styled.header`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > h3 {
    color: #b557ff;
    font-size: 11px;
    font-weight: 900;
    text-transform: uppercase;
    margin-top: -8px;
  }

  > h2 {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: -6px;
  }
`;

const OrganizationInvitesGridWrapper = styled(GridWrapper)`
  .ag-cell,
  .ag-header-cell {
    border-right: none;
  }
`;

const FilterInput = styled(Input)`
  width: 100%;
  max-width: 300px;
`;

export function OrganizationInvitesPage(): ReactElement {
  const { organization } = useOrganization();
  const [defaultColDef] = useState<ColDef>({
    sortable: false,
    filter: false,
    width: 130,
  });
  const [colDefs] = useState<ColDef<TData>[]>([
    { field: 'email', width: 260 },
    {
      field: 'createdAt',
      headerName: 'Sent Date',
      valueFormatter: ({ value }) => {
        return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED);
      },
      width: 180,
    },
  ]);
  const [query, setQuery] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [{ fetching, data }] = useQuery({
    query: GetOrganizationInvitesPage,
    variables: {
      orgId: organization.id,
    },
  });

  const invites = useMemo(() => {
    if (!data) {
      return [];
    }
    return (
      data.me.organization?.invitesConnection.edges.map((edge) => edge.node) ??
      []
    );
  }, [data]);

  const filteredInvites = useMemo(() => {
    if (query.length === 0) return invites;
    const regEx = new RegExp(`${query}`, 'i');
    return invites.filter((invite) => {
      return regEx.test(invite.email);
    });
  }, [invites, query]);

  const handleOnQueryChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      searchParams.set('query', event.target.value);
      setSearchParams(searchParams);
    },
    []
  );

  useEffect(() => {
    if (searchParams.has('query')) {
      setQuery(searchParams.get('query') ?? '');
    }
  }, [searchParams]);

  return (
    <>
      <PageContainer>
        <Controls>
          <FilterInput
            label="Filter"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
            value={query}
            onChange={handleOnQueryChange}
          />
        </Controls>
        <OrganizationInvites>
          <Card>
            <Header>
              <Heading>
                <h3>{organization.name}</h3>
                <h2>Invites</h2>
              </Heading>
            </Header>
            <OrganizationInvitesGridWrapper className="ag-theme-material">
              <AgGridReact
                defaultColDef={defaultColDef}
                columnDefs={colDefs}
                rowData={filteredInvites}
                suppressRowHoverHighlight={true}
                suppressCellFocus={true}
              />
            </OrganizationInvitesGridWrapper>
          </Card>
        </OrganizationInvites>
        <FabWrapper>
          <Tooltip title="Invite User" enterDelay={350}>
            <Fab as={NavLink} to="./add">
              <Add />
            </Fab>
          </Tooltip>
        </FabWrapper>
      </PageContainer>
      <Outlet
        context={{
          organization,
        }}
      />
    </>
  );
}
