import { ReactElement, useMemo } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;

const Budget = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const SubTitle = styled.div`
  font-family: Lexend, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.72);
`;

const Balance = styled.div`
  font-family: Lexend, sans-serif;
  font-size: 32px;
  font-weight: 500;
  color: #fff;
`;

export interface MeasurementBudgetProps {
  app: {
    id: string;
    measurementBudget: number;
  };
  organization: {
    id: string;
  };
}

export function MeasurementBudget({
  app,
  organization,
}: MeasurementBudgetProps): ReactElement {
  const balance = useMemo(() => {
    return app.measurementBudget / 1_000;
  }, [app]);

  return (
    <Container>
      <Budget>
        <SubTitle>Credit Balance</SubTitle>
        <Balance>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }).format(balance)}
        </Balance>
      </Budget>
    </Container>
  );
}
