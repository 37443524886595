import { getInstrument, logMeasurement, measure } from '@getpolaris.ai/sdk';
import { createContext } from 'react';

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <PolarisContext>.');
};

export interface SdkContextInterface {
  measure: typeof measure;
  getInstrument: typeof getInstrument;
  logMeasurement: typeof logMeasurement;
}

const initialContext: SdkContextInterface = {
  measure: stub,
  getInstrument: stub,
  logMeasurement: stub,
};

export const SdkContext = createContext<SdkContextInterface>(initialContext);
