import { IHeaderParams } from 'ag-grid-community';
import { ComponentType, ReactElement } from 'react';
import styled from 'styled-components';

export interface ClickableColumnHeaderProps extends IHeaderParams {
  icon: ComponentType<any>;
  onClick: () => void;
}

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border: none;
  width: 100%;

  > svg {
    height: 16px;
    width: 16px;
  }
`;

export function ClickableColumnHeader({
  displayName,
  icon: Icon,
  onClick,
}: ClickableColumnHeaderProps): ReactElement {
  return (
    <StyledButton onClick={onClick}>
      {displayName}
      <Icon />
    </StyledButton>
  );
}
