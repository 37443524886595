import { Danger, Drawer, Loading } from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { DeleteObjectiveInput } from '@event-horizon/graphql-api-schema';
import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation, useQuery } from 'urql';
import * as yup from 'yup';
import { useObjective } from './ObjectiveOutlet';

const GetDeleteObjectivePage = graphql(`
  query GetDeleteObjectivePage($orgId: ID!, $appId: ID!, $objectiveId: ID!) {
    me {
      id
      organization(id: $orgId) {
        id
        app(id: $appId) {
          id
          objective(id: $objectiveId) {
            id
            name
          }
        }
      }
    }
  }
`);

const DeleteObjective = graphql(`
  mutation DeleteObjective($input: DeleteObjectiveInput!) {
    deleteObjective(input: $input)
  }
`);

const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

const ObjectiveName = styled.pre`
  color: var(--color-warn-900);
  font-weight: 500;
  background: var(--color-warn-100);
  border-radius: 4px;
  padding: 4px 8px;
  margin: 0 auto 0 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export function DeleteObjectivePage(): ReactElement {
  const { application, objective, organization } = useObjective();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [{ data }] = useQuery({
    query: GetDeleteObjectivePage,
    variables: {
      orgId: organization.id,
      appId: application.id,
      objectiveId: objective.id,
    },
  });
  const [, deleteObjective] = useMutation(DeleteObjective);

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup.string().required().equals([objective.name]),
      }),
    [objective.name]
  );

  const { errors, handleChange, handleSubmit, isValid, touched, values } =
    useFormik({
      initialValues: {
        name: '',
      },
      validateOnMount: true,
      validationSchema: validationSchema,
      onSubmit: async () => {
        const input: DeleteObjectiveInput = { objectiveId: objective.id };
        await deleteObjective({ input });
        navigate(`/org/${organization.id}/app/${application.id}`);
      },
    });

  const handleOnClose = useCallback(() => {
    setOpen(false);
    navigate(-1);
  }, [navigate]);

  if (data) {
    if (!data.me.organization) {
      throw new Error('Organization not found');
    }
    if (!data.me.organization.app) {
      throw new Error('Application not found');
    }
    if (!data.me.organization.app.objective) {
      throw new Error('Objective not found');
    }
  }

  return (
    <Drawer onClose={handleOnClose} open={open} title="Delete Objective">
      <DrawerContainer>
        {!data && <Loading />}
        {data?.me.organization &&
          data.me.organization.app &&
          data.me.organization.app.objective && (
            <>
              <Danger title="Danger!">
                <p>
                  Deleting an objective is a permanent action. To proceed, you
                  must confirm the deletion be entering the objective name:
                </p>
                <ObjectiveName>{objective.name}</ObjectiveName>
              </Danger>
              <Form onSubmit={handleSubmit}>
                <TextField
                  error={touched.name && Boolean(errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Objective Name"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  variant="outlined"
                />
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  color="error"
                  disabled={!isValid}
                >
                  Delete Objective
                </Button>
              </Form>
            </>
          )}
      </DrawerContainer>
    </Drawer>
  );
}
