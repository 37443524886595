import { AvTimer, Numbers, Percent } from '@mui/icons-material';
import { ChangeEvent, FocusEvent, ReactElement } from 'react';
import styled from 'styled-components';
import { Option } from './Option';
import { RadioGroup } from './RadioGroup';

interface IndicatorOperationProps {
  errors?: string;
  name: string;
  onBlur: (event: FocusEvent<HTMLInputElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  touched?: boolean;
  value: string;
}

const IndicatorOperationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export function IndicatorOperation(
  props: IndicatorOperationProps
): ReactElement {
  return (
    <IndicatorOperationContainer>
      <RadioGroup
        name={props.name}
        value={props.value}
        onBlur={props.onBlur}
        onChange={props.onChange}
      >
        <Option icon={<Percent />} label="Average" value="AVERAGE">
          <span>Measure how long workflows are taking</span>
        </Option>
        <Option icon={<AvTimer />} label="Rate" value="RATE">
          <span>Measure how often workflows report errors</span>
        </Option>
        <Option icon={<Numbers />} label="Count" value="COUNT">
          <span>Measure how many workflows occur</span>
        </Option>
      </RadioGroup>
    </IndicatorOperationContainer>
  );
}
