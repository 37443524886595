import {
  AlertDialog,
  Button,
  Drawer,
  Snackbar,
} from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { useOrganization } from '@event-horizon/app-organizations';
import { CreateInviteInput } from '@event-horizon/graphql-api-schema';
import { FormikHelpers } from 'formik';
import { ReactElement, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation } from 'urql';
import { InviteForm, InviteFormValues } from './InviteForm';

const CreateInvite = graphql(`
  mutation CreateInvite($input: CreateInviteInput!) {
    createInvite(input: $input) {
      email
    }
  }
`);

const DrawerContainer = styled.div`
  flex: 1 auto;
  display: flex;
  flex-direction: column;
`;

export function InviteUserDrawer(): ReactElement {
  const { organization } = useOrganization();
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [, createInvite] = useMutation(CreateInvite);

  const close = useCallback(() => {
    setDrawerOpen(false);
    navigate(-1);
  }, [navigate, setDrawerOpen]);

  const handleOnClose = useCallback(() => {
    if (dirty) {
      setAlertOpen(true);
      return;
    }
    close();
  }, [close, dirty, setAlertOpen]);

  const handleOnSubmit = useCallback(
    async (
      values: InviteFormValues,
      formikHelpers: FormikHelpers<InviteFormValues>
    ) => {
      const input: CreateInviteInput = {
        ...values,
        organizationId: organization.id,
      };
      const result = await createInvite({ input });
      if (result.error) {
        formikHelpers.setSubmitting(false);
        setErrorOpen(true);
        return;
      }
      close();
    },
    [createInvite, close, organization.id]
  );

  return (
    <>
      <Drawer onClose={handleOnClose} open={drawerOpen} title="Invite User">
        <DrawerContainer>
          <InviteForm
            onClose={handleOnClose}
            onDirty={setDirty}
            onSubmit={handleOnSubmit}
          />
        </DrawerContainer>
      </Drawer>
      <AlertDialog
        actions={
          <>
            <Button onClick={() => setAlertOpen(false)}>No</Button>
            <Button color="warn" onClick={close}>
              Yes
            </Button>
          </>
        }
        description="Are you sure you want to cancel and discard?"
        onClose={() => setAlertOpen(false)}
        open={alertOpen}
        title="Cancel?"
      />
      <Snackbar
        open={errorOpen}
        onClose={handleOnClose}
        message="There was an error sending the invite."
      />
    </>
  );
}
