import {
  Button,
  GlassMenu,
  Input,
  Loading,
  Option,
  RadioGroup,
  Sdks,
} from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { CreateAppInput, Sdk } from '@event-horizon/graphql-api-schema';
import { ArrowDropDown } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import {
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation, useQuery } from 'urql';
import * as yup from 'yup';

const GetCreateApplicationPage = graphql(`
  query GetCreateApplicationPage {
    me {
      id
      organizations {
        id
        name
      }
    }
  }
`);

const CreateApp = graphql(`
  mutation CreateApp($input: CreateAppInput!) {
    createApp(input: $input) {
      id
    }
  }
`);

interface FormValues {
  organizationId: string;
  name: string;
  sdk: Sdk;
}

const Container = styled.div`
  height: 100%;
  position: relative;
  padding: 0 24px;

  &::before {
    content: '';
    position: absolute;
    background: url('/backgrounds/starfield.svg');
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;

const Aurora = styled.div`
  height: 100%;
  max-width: 960px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 auto;
  gap: 32px;

  &::before {
    content: '';
    position: absolute;
    background: url('/backgrounds/aurora.svg') no-repeat;
    background-size: contain;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.12;
    z-index: -1;
  }
`;

const Logo = styled.img`
  height: 40px;
  width: 40px;
`;

const Form = styled.form`
  width: 100%;
  max-width: 32rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const OrganizationMenuButton = styled(Button)`
  width: 100%;
  justify-content: space-between;
`;

export function CreateApplicationPage(): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [{ data }] = useQuery({
    query: GetCreateApplicationPage,
  });
  const navigate = useNavigate();
  const [, createApp] = useMutation(CreateApp);
  const open = Boolean(anchorEl);

  const handleOnSubmit = useCallback(
    async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const input: CreateAppInput = {
        ...values,
        webhooks: [],
      };
      const result = await createApp({ input });
      if (result.error) {
        formikHelpers.setSubmitting(false);
        formikHelpers.setErrors(result.error);
        return;
      }
      if (result.data?.createApp?.id) {
        navigate(
          `/org/${values.organizationId}/app/${result.data.createApp.id}`
        );
        return;
      }
      // todo: show error
    },
    []
  );

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const validationSchema = yup.object({
    name: yup.string().required('Please provide an application name'),
  });

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    setFieldValue,
    touched,
    values,
  } = useFormik<FormValues>({
    initialValues: {
      organizationId: '',
      name: '',
      sdk: Sdk.TYPESCRIPT,
    },
    validationSchema: validationSchema,
    onSubmit: handleOnSubmit,
  });

  useEffect(() => {
    setFieldValue('organizationId', data?.me.organizations[0].id ?? '');
  }, [data, setFieldValue]);

  if (!data) {
    return <Loading />;
  }

  return (
    <Container>
      <Aurora>
        <Form onSubmit={handleSubmit}>
          <Logo src="/icons/polaris-c.svg" />
          <OrganizationMenuButton
            id="organization-menu-button"
            aria-controls={open ? 'organization-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleMenuClick}
            raised
            color="secondary"
            type="button"
          >
            {
              data?.me.organizations.find(
                (organization) => organization.id === values.organizationId
              )?.name
            }
            <ArrowDropDown />
          </OrganizationMenuButton>
          <GlassMenu
            id="organization-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            MenuListProps={{
              'aria-labelledby': 'organization-menu',
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {data?.me.organizations.map((organization) => (
              <MenuItem
                key={organization.id}
                onClick={() => {
                  setFieldValue('organizationId', organization.id);
                  handleMenuClose();
                }}
              >
                {organization.name}
              </MenuItem>
            ))}
          </GlassMenu>
          <Input
            error={touched.name && Boolean(errors.name)}
            fullWidth
            helperText={touched.name && errors.name}
            label="Application Name"
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
          />
          <RadioGroup
            name="sdk"
            value={values.sdk}
            onBlur={handleBlur}
            onChange={handleChange}
          >
            {Object.keys(Sdk).map((sdk) => (
              <Option
                key={sdk}
                icon={Sdks[sdk as Sdk].icon}
                label={Sdks[sdk as Sdk].title}
                value={sdk}
              />
            ))}
          </RadioGroup>
          <Button type="submit" disabled={!isValid} raised>
            Create Application
          </Button>
        </Form>
      </Aurora>
    </Container>
  );
}
