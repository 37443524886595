import { IndicatorWindow as EventHorizonIndicatorWindow } from '@event-horizon/graphql-api-schema';
import { FormControl, FormHelperText, MenuItem } from '@mui/material';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { ReactElement } from 'react';
import { Select } from './Select';

interface IndicatorWindowProps {
  errors?: string;
  name: string;
  onBlur: SelectInputProps['onBlur'];
  onChange: SelectInputProps['onChange'];
  touched?: boolean;
  value: string;
}

export function IndicatorWindow(props: IndicatorWindowProps): ReactElement {
  return (
    <FormControl>
      <Select
        name={props.name}
        onBlur={props.onBlur}
        onChange={props.onChange}
        value={props.value}
      >
        <MenuItem value={EventHorizonIndicatorWindow.LAST_MINUTE}>
          Last Minute
        </MenuItem>
        <MenuItem value={EventHorizonIndicatorWindow.LAST_FIVE_MINUTES}>
          Last Five Minutes
        </MenuItem>
        <MenuItem value={EventHorizonIndicatorWindow.LAST_TEN_MINUTES}>
          Last Ten Minutes
        </MenuItem>
        <MenuItem value={EventHorizonIndicatorWindow.LAST_FIFTEEN_MINUTES}>
          Last Fifteen Minutes
        </MenuItem>
        <MenuItem value={EventHorizonIndicatorWindow.LAST_THIRTY_MINUTES}>
          Last Thirty Minutes
        </MenuItem>
        <MenuItem value={EventHorizonIndicatorWindow.LAST_HOUR}>
          Last Hour
        </MenuItem>
        <MenuItem value={EventHorizonIndicatorWindow.LAST_SIX_HOURS}>
          Last Six Hours
        </MenuItem>
        <MenuItem value={EventHorizonIndicatorWindow.LAST_TWELVE_HOURS}>
          Last Twelve Hours
        </MenuItem>
        <MenuItem value={EventHorizonIndicatorWindow.LAST_DAY}>
          Last Day
        </MenuItem>
        <MenuItem value={EventHorizonIndicatorWindow.LAST_WEEK}>
          Last Week
        </MenuItem>
        <MenuItem value={EventHorizonIndicatorWindow.LAST_TWO_WEEKS}>
          Last Two Weeks
        </MenuItem>
      </Select>
      {props.touched && Boolean(props.errors) && (
        <FormHelperText>{props.errors}</FormHelperText>
      )}
    </FormControl>
  );
}
