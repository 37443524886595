import { Danger, Drawer, Info, Loading } from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { DeleteIndicatorInput } from '@event-horizon/graphql-api-schema';
import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation, useQuery } from 'urql';
import * as yup from 'yup';
import { useIndicator } from './IndicatorOutlet';

const GetDeleteIndicatorPage = graphql(`
  query GetDeleteIndicatorPage($orgId: ID!, $appId: ID!, $indicatorId: ID!) {
    me {
      id
      organization(id: $orgId) {
        id
        app(id: $appId) {
          id
          indicator(id: $indicatorId) {
            id
            name
            objectives {
              id
              name
            }
          }
        }
      }
    }
  }
`);

const DeleteIndicator = graphql(`
  mutation DeleteIndicator($input: DeleteIndicatorInput!) {
    deleteIndicator(input: $input)
  }
`);

const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

const IndicatorName = styled.pre`
  color: var(--color-warn-900);
  font-weight: 500;
  background: var(--color-warn-100);
  border-radius: 4px;
  padding: 4px 8px;
  margin: 0 auto 0 0;
`;

const ObjectivesList = styled.ul`
  list-style: disc;
  padding: 0;
  margin: 0 0 0 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export function DeleteIndicatorPage(): ReactElement {
  const { application, indicator, organization } = useIndicator();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [{ data }] = useQuery({
    query: GetDeleteIndicatorPage,
    variables: {
      orgId: organization.id,
      appId: application.id,
      indicatorId: indicator.id,
    },
  });
  const [, deleteIndicator] = useMutation(DeleteIndicator);

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup.string().required().equals([indicator.name]),
      }),
    [indicator.name]
  );

  const { errors, handleChange, handleSubmit, isValid, touched, values } =
    useFormik({
      initialValues: {
        name: '',
      },
      validateOnMount: true,
      validationSchema: validationSchema,
      onSubmit: async () => {
        const input: DeleteIndicatorInput = { indicatorId: indicator.id };
        await deleteIndicator({ input: input });
        navigate(`/org/${organization.id}/app/${application.id}`);
      },
    });

  const handleOnClose = useCallback(() => {
    setOpen(false);
    navigate(-1);
  }, []);

  if (!data) {
    return <Loading />;
  }

  return (
    <Drawer onClose={handleOnClose} open={open} title="Delete Indicator">
      <DrawerContainer>
        {!data && <Loading />}
        {data.me.organization &&
          data.me.organization.app &&
          data.me.organization.app.indicator &&
          data.me.organization.app.indicator.objectives.length > 0 && (
            <Info title={`Cannot delete ${indicator.name}`}>
              <p>
                To delete an indicator it must not be used by any objectives.
              </p>
              <p>
                Please remove the following objectives from the application and
                try again.
              </p>
              <ObjectivesList>
                {data.me.organization.app.indicator.objectives.map(
                  (objective) => (
                    <li key={objective.id}>{objective.name}</li>
                  )
                )}
              </ObjectivesList>
            </Info>
          )}
        {data.me.organization &&
          data.me.organization.app &&
          data.me.organization.app.indicator &&
          data.me.organization.app.indicator.objectives.length === 0 && (
            <>
              <Danger title="Danger!">
                <p>
                  Deleting an indicator is a permanent action. To proceed, you
                  must confirm the deletion be entering the indicator name:
                </p>
                <IndicatorName>{indicator.name}</IndicatorName>
              </Danger>
              <Form onSubmit={handleSubmit}>
                <TextField
                  error={touched.name && Boolean(errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Indicator Name"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  variant="outlined"
                />
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  color="error"
                  disabled={!isValid}
                >
                  Delete Indicator
                </Button>
              </Form>
            </>
          )}
      </DrawerContainer>
    </Drawer>
  );
}
