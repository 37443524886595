import { Color, ScriptableContext } from 'chart.js';

export function borderColor(context: ScriptableContext<'line'>): Color {
  const gradient = context.chart.ctx.createRadialGradient(
    context.chart.canvas.width / 2,
    context.chart.canvas.height / 2,
    0,
    context.chart.canvas.width / 2,
    context.chart.canvas.height / 2,
    context.chart.canvas.width / 2
  );
  gradient.addColorStop(0, 'rgba(95, 116, 249, 1)');
  gradient.addColorStop(0.578125, 'rgba(16, 221, 211, 1)');
  gradient.addColorStop(1, 'rgba(95, 116, 249, 1)');
  return gradient;
}
