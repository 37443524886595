import styled from 'styled-components';
import { glassSurface } from './GlassSurface';

interface ButtonProps {
  condensed?: boolean | 'true' | 'false';
  color?: 'primary' | 'secondary' | 'warn';
  raised?: boolean | 'true' | 'false';
}

export const Button = styled('button').withConfig<ButtonProps>({
  shouldForwardProp: (prop) => !['condensed', 'color', 'raised'].includes(prop),
})<ButtonProps>`
  ${glassSurface};
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  display: inline-flex;
  gap: 8px;
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  line-height: normal;
  --glass-background-color-a: rgba(16, 221, 211, 0);
  --glass-background-color-b: rgba(95, 117, 249, 0);
  --glass-background-color-c: rgba(181, 87, 255, 0);
  --glass-noise: none;
  transition: --glass-background-color-a 0.3s ease-in-out,
    --glass-background-color-b 0.3s ease-in-out,
    --glass-background-color-c 0.3s ease-in-out,
    --glass-border-opacity 0.3s ease-in-out,
    --glass-border-radius 0.3s ease-in-out;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${({ color, raised }) => {
    switch (raised) {
      case true:
      case 'true':
        switch (color) {
          case 'warn':
            return `
              --glass-background-color-a: rgba(255, 0, 0, 0.16);
              --glass-background-color-b: rgba(255, 0, 0, 0.10);
              --glass-background-color-c: rgba(255, 0, 0, 0.23);
              --glass-border-opacity: 12%;
              --glass-noise: url('/backgrounds/noise.png');
            `;
          case 'secondary':
            return `
              --glass-background-color-a: rgba(208, 215, 255, 0.25);
              --glass-background-color-b: rgba(194, 203, 255, 0.13);
              --glass-background-color-c: rgba(181, 191, 255, 0.20);
              --glass-border-opacity: 12%;
              --glass-noise: url('/backgrounds/noise.png');
            `;
          case 'primary':
          default:
            return `
              --glass-background-color-a: rgba(16, 221, 211, 0.40);
              --glass-background-color-b: rgba(95, 117, 249, 0.25);
              --glass-background-color-c: rgba(181, 87, 255, 0.40);
              --glass-border-opacity: 12%;
              --glass-noise: url('/backgrounds/noise.png');
            `;
        }
      case false:
      case 'false':
      default:
        return `--glass-border-opacity: 0%;`;
    }
  }};

  color: ${({ color, raised }) => {
    switch (color) {
      case 'warn':
        switch (raised) {
          case true:
          case 'true':
            return 'rgba(255, 255, 255, 0.8)';
          case false:
          case 'false':
          default:
            return 'rgba(255, 0, 0, 0.6)';
        }
      case 'secondary':
      case 'primary':
      default:
        return 'rgba(255, 255, 255, 0.8)';
    }
  }};

  font-size: ${({ condensed }) => {
    switch (condensed) {
      case true:
      case 'true':
        return '12px';
      case false:
      default:
        return '13px';
    }
  }};

  height: ${({ condensed }) => {
    switch (condensed) {
      case true:
      case 'true':
        return '32px';
      case false:
      case 'false':
      default:
        return '40px';
    }
  }};

  padding: ${({ condensed }) => {
    switch (condensed) {
      case true:
        return '0 12px';
      case false:
      default:
        return '0 16px';
    }
  }};

  & svg {
    width: 16px;
    height: 16px;
  }

  &:hover:not(:disabled) {
    ${({ color, raised }) => {
      switch (raised) {
        case true:
        case 'true':
          switch (color) {
            case 'warn':
              return `
                --glass-background-color-a: rgba(255, 0, 0, 0.27);
                --glass-background-color-b: rgba(255, 0, 0, 0.18);
                --glass-background-color-c: rgba(255, 0, 0, 0.39);
                --glass-border-opacity: 12%;
              `;
            case 'secondary':
              return `
                --glass-background-color-a: rgba(255, 255, 255, 0.26);
                --glass-background-color-b: rgba(255, 255, 255, 0.13);
                --glass-background-color-c: rgba(255, 255, 255, 0.19);
                --glass-border-opacity: 12%;
              `;
            case 'primary':
            default:
              return `
                --glass-background-color-a: rgba(16, 221, 211, 0.80);
                --glass-background-color-b: rgba(95, 117, 249, 0.72);
                --glass-background-color-c: rgba(181, 87, 255, 0.60);
                --glass-border-opacity: 12%;
              `;
          }
        case false:
        case 'false':
        default:
          switch (color) {
            case 'warn':
              return `
                --glass-background-color-a: rgba(255, 0, 0, 0.08);
                --glass-background-color-b: rgba(255, 0, 0, 0.05);
                --glass-background-color-c: rgba(255, 0, 0, 0.12);
                --glass-border-opacity: 12%;
              `;
            case 'secondary':
              return `
                --glass-background-color-a: rgba(255, 255, 255, 0.08);
                --glass-background-color-b: rgba(255, 255, 255, 0.05);
                --glass-background-color-c: rgba(255, 255, 255, 0.12);
                --glass-border-opacity: 12%;
                --glass-noise: url('/backgrounds/noise.png');
              `;
            case 'primary':
            default:
              return `
                --glass-background-color-a: rgba(16, 221, 211, 0.08);
                --glass-background-color-b: rgba(95, 117, 249, 0.05);
                --glass-background-color-c: rgba(181, 87, 255, 0.12);
                --glass-border-opacity: 12%;
                --glass-noise: url('/backgrounds/noise.png');
              `;
          }
      }
    }};
  }
`;
