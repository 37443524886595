import { UserRole } from '@event-horizon/graphql-api-schema';
import { Divider, Menu, MenuItem } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { useState, MouseEvent } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { glassSurface } from './GlassSurface';

interface AppSwitcherProps {
  app?: {
    id: string;
    name: string;
  };
  apps: {
    id: string;
    name: string;
  }[];
  organization: {
    id: string;
    logo: string | null;
    name: string;
  };
  role: UserRole;
}

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    display: inline-flex;
    align-items: center;
    text-align: left;
    color: rgba(255, 255, 255, 0.87);
    font-family: 'Singolare', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
`;

const GlassMenu = styled(Menu)`
  & .MuiPaper-root {
    ${glassSurface};
  }
`;

export function AppSwitcher(props: AppSwitcherProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledButton
        id="app-switcher-button"
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <span>{props.app ? props.app.name : props.organization.name}</span>
        <ArrowDropDown />
      </StyledButton>
      <GlassMenu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'user-menu-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {props.apps.map((app) => (
          <MenuItem
            key={app.id}
            component={NavLink}
            onClick={handleClose}
            to={`/org/${props.organization.id}/app/${app.id}/overview`}
          >
            {app.name}
          </MenuItem>
        ))}
        <Divider />
        {props.role === UserRole.OWNER && (
          <MenuItem
            component={NavLink}
            onClick={handleClose}
            to={`/org/${props.organization.id}/edit`}
          >
            Edit Organization
          </MenuItem>
        )}
        <MenuItem component={NavLink} onClick={handleClose} to="/">
          View All
        </MenuItem>
      </GlassMenu>
    </>
  );
}
