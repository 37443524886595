import {
  BackLink,
  Button,
  glassSurface,
  PageContainer,
} from '@event-horizon/app-components';
import { Settings } from '@mui/icons-material';
import { ReactElement, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { ConsoleMessagesPreview } from './ConsoleMessagesPreview';
import { useIndicator } from './IndicatorOutlet';
import { IndicatorNav } from './IndicatorNav';
import { UpdateIndicatorDrawer } from './UpdateIndicatorDrawer';

const IndicatorContainer = styled.div`
  padding: 24px;
  flex: 1 auto;
`;

const IndicatorCard = styled.div`
  ${glassSurface};
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
`;

const Header = styled.header`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > h3 {
    color: #b557ff;
    font-size: 11px;
    font-weight: 900;
    text-transform: uppercase;
    margin-top: -8px;
  }

  > h2 {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: -6px;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 16px;
`;

export function IndicatorPage(): ReactElement {
  const { application, indicator, objectives, organization } = useIndicator();
  const [editorOpen, setEditorOpen] = useState(false);
  const [group, name] = useMemo(() => {
    const [group, name] = indicator.name.split(' / ');
    if (!name) {
      return ['', group];
    }
    return [group, name];
  }, [indicator]);

  const consoleMessages = useMemo(() => {
    return indicator.logMessagesConnection.edges.reduce(
      (acc, { node }) => {
        const parsed = JSON.parse(node.message);

        switch (parsed.level) {
          case 'error':
            acc.error++;
            break;
          case 'warn':
            acc.warn++;
            break;
          case 'info':
            acc.info++;
            break;
        }

        return acc;
      },
      {
        error: 0,
        warn: 0,
        info: 0,
      }
    );
  }, [indicator.logMessagesConnection.edges]);

  return (
    <PageContainer
      header={<BackLink to="../../indicators">Back to Indicators</BackLink>}
    >
      <IndicatorContainer>
        <IndicatorCard>
          <Header>
            <Heading>
              <h3>{group}</h3>
              <h2>{name}</h2>
            </Heading>
            <Nav>
              <IndicatorNav />
              <ConsoleMessagesPreview messages={consoleMessages} />
              <Button
                onClick={() => setEditorOpen(true)}
                color="secondary"
                raised
                condensed
              >
                <Settings />
              </Button>
            </Nav>
          </Header>
          <Outlet
            context={{
              application,
              indicator,
              objectives,
              organization,
            }}
          />
        </IndicatorCard>
      </IndicatorContainer>
      <UpdateIndicatorDrawer
        open={editorOpen}
        onClose={() => setEditorOpen(false)}
      />
    </PageContainer>
  );
}
