import { Warning } from '@mui/icons-material';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

interface WarnProps {
  children: ReactNode;
  title: string;
}

const WarnContainer = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 16px;
  margin: 16px 0;
  padding: 16px;
  border: 1px solid var(--color-accent-800);
  border-radius: 8px;
  background-color: var(--color-accent-400);
`;

const WarningIcon = styled(Warning)`
  color: var(--color-slate-800);
`;

const WarnContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const WarnTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: var(--color-slate-800);
`;

const WarnChildren = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 15px;
  font-weight: 300;
  color: var(--color-slate-800);

  a {
    color: var(--color-slate-800);
    text-decoration: underline;
  }
`;

export function Warn(props: WarnProps): ReactElement {
  return (
    <WarnContainer>
      <WarningIcon />
      <WarnContent>
        <WarnTitle>{props.title}</WarnTitle>
        <WarnChildren>{props.children}</WarnChildren>
      </WarnContent>
    </WarnContainer>
  );
}
