import {
  ClickableColumnHeader,
  ClickableColumnHeaderProps,
  GridWrapper,
  RemovableColumnHeader,
  RemovableColumnHeaderProps,
} from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { MeasurementResult } from '@event-horizon/graphql-api-schema';
import { Add } from '@mui/icons-material';
import { ColDef, RowSelectedEvent } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { AgGridReact } from 'ag-grid-react';
import { getQuickJS } from 'quickjs-emscripten';
import {
  ComponentType,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';
import { AddPropertyDialog, AddPropertyFormValues } from './AddPropertyDialog';
import { useIndicator } from './IndicatorOutlet';

const GetIndicatorMeasurements = graphql(`
  query GetIndicatorMeasurements(
    $orgId: ID!
    $appId: ID!
    $indicatorId: ID!
    $size: Int!
    $after: String
  ) {
    me {
      organization(id: $orgId) {
        app(id: $appId) {
          indicator(id: $indicatorId) {
            measurementsConnection(first: $size, after: $after) {
              pageInfo {
                endCursor
                hasNextPage
              }
              edges {
                node {
                  id
                  duration
                  metadata {
                    connectionQuality
                    deviceType
                    userMetadata
                  }
                  name
                  result
                  timestamp
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`);

const toTitleCase = (str?: string) => {
  if (!str) return '';

  return str
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export function IndicatorMeasurementsPage(): ReactElement {
  const { application, indicator, organization } = useIndicator();
  const navigate = useNavigate();
  const [addPropertyDialogOpen, setAddPropertyDialogOpen] =
    useState<boolean>(false);
  const [customColDefs, setCustomColDefs] = useState<ColDef[]>([]);
  const [{ fetching, data }] = useQuery({
    query: GetIndicatorMeasurements,
    variables: {
      orgId: organization.id,
      appId: application.id,
      indicatorId: indicator.id,
      size: 500,
      after: null,
    },
  });

  const measurements = useMemo(() => {
    if (!data) return [];
    return (
      data.me.organization?.app?.indicator?.measurementsConnection.edges.map(
        (edge) => ({
          ...edge.node,
          metadata: {
            ...edge.node.metadata,
            userMetadata: JSON.parse(edge.node.metadata.userMetadata),
          },
        })
      ) ?? []
    );
  }, [data]);

  const defaultColDef: ColDef = {
    sortable: false,
    filter: false,
    width: 130,
  };

  const colDefs = useMemo((): ColDef[] => {
    return [
      {
        headerName: 'Date',
        field: 'timestamp',
        valueFormatter: ({ value }) => {
          return new Date(value).toLocaleDateString();
        },
      },
      {
        headerName: 'Time',
        field: 'timestamp',
        valueFormatter: ({ value }) => {
          return new Date(value).toLocaleTimeString();
        },
      },
      {
        headerName: 'Name',
        field: 'name',
      },
      {
        headerName: 'Result',
        field: 'result',
        valueFormatter: ({ value }) => toTitleCase(value),
        cellClass: ({ value }) => {
          if (!value) return '';

          switch (value) {
            case MeasurementResult.SUCCESS:
              return 'measurement-success';
            case MeasurementResult.FAILURE:
              return 'measurement-failure';
          }
        },
      },
      {
        headerName: 'Duration',
        field: 'duration',
        valueFormatter: ({ value }) => {
          return new Intl.NumberFormat('en-US', {
            style: 'unit',
            maximumFractionDigits: 3,
            minimumFractionDigits: 3,
            unit: 'second',
          }).format(value / 1000);
        },
      },
      {
        headerName: 'Connection',
        field: 'metadata.connectionQuality',
        valueFormatter: ({ value }) => toTitleCase(value),
      },
      {
        headerName: 'Device',
        field: 'metadata.deviceType',
        valueFormatter: ({ value }) => toTitleCase(value),
      },
      {
        headerName: 'URL',
        field: 'url',
      },
      ...customColDefs,
      {
        headerName: 'Add Property...',
        headerComponent: 'clickableHeader',
        headerComponentParams: {
          icon: Add as ComponentType<any>,
          onClick: () => {
            setAddPropertyDialogOpen(true);
          },
        } as ClickableColumnHeaderProps,
        width: 180,
      },
    ];
  }, [customColDefs]);

  const components = useMemo(
    () => ({
      clickableHeader: ClickableColumnHeader,
      removableHeader: RemovableColumnHeader,
    }),
    []
  );

  const handleAddProperty = useCallback(
    async (values: AddPropertyFormValues) => {
      const QuickJs = await getQuickJS();
      const vm = QuickJs.newContext();
      vm.evalCode(values.mapFunction);
      const mapFnHandle = vm.unwrapResult(vm.evalCode('map'));

      function mapFunction(measurement: any) {
        // Convert the measurement to a string and then parse it into a JSON object
        const measurementStringHandle = vm.newString(
          JSON.stringify(measurement)
        );

        // Get the JSON.parse function from the QuickJS context
        const JSONHandle = vm.getProp(vm.global, 'JSON');
        const parseHandle = vm.getProp(JSONHandle, 'parse');
        const stringifyHandle = vm.getProp(JSONHandle, 'stringify');

        // Call the JSON.parse function with the measurement string to
        // convert it into a JSON object
        const measurementHandle = vm.unwrapResult(
          vm.callFunction(parseHandle, JSONHandle, measurementStringHandle)
        );

        // Call the main function with the parsed measurement
        const resultHandle = vm.unwrapResult(
          vm.callFunction(mapFnHandle, vm.undefined, measurementHandle)
        );

        // Convert the result back into a string
        const resultStringHandle = vm.unwrapResult(
          vm.callFunction(stringifyHandle, JSONHandle, resultHandle)
        );

        // Convert the result string back into a JSON object
        const result = JSON.parse(vm.dump(resultStringHandle));

        return result;
      }

      const colDef: ColDef = {
        colId: values.headerName,
        headerName: values.headerName,
        headerComponent: 'removableHeader',
        headerComponentParams: {
          onClick: (colId) => {
            setCustomColDefs((customColDefs) =>
              customColDefs.filter((colDef) => colDef.colId !== colId)
            );
          },
        } as RemovableColumnHeaderProps,
        valueGetter: ({ data }) => {
          return mapFunction(data);
        },
      };
      setCustomColDefs((customColDefs) => [...customColDefs, colDef]);
    },
    []
  );

  const handleRowSelected = useCallback(
    (event: RowSelectedEvent) => {
      navigate(`./${event.node.data.id}`);
    },
    [navigate]
  );

  if (fetching) return <GridWrapper />;

  return (
    <>
      <GridWrapper className="ag-theme-material" rowSelection="single">
        <AgGridReact
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          components={components}
          rowData={measurements}
          rowSelection="single"
          suppressRowHoverHighlight={true}
          suppressCellFocus={true}
          onRowSelected={handleRowSelected}
        />
      </GridWrapper>
      <AddPropertyDialog
        open={addPropertyDialogOpen}
        onClose={() => setAddPropertyDialogOpen(false)}
        onSubmit={handleAddProperty}
      />
      <Outlet />
    </>
  );
}
