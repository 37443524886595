import {
  Button,
  Input,
  Loading,
  ObjectivePercentile,
  ObjectiveThreshold,
  Select,
  Warn,
} from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { ThresholdType } from '@event-horizon/app-models';
import {
  BoundedThreshold,
  Objective,
  Percentile,
} from '@event-horizon/graphql-api-schema';
import { Help } from '@mui/icons-material';
import { FormControl, FormHelperText, MenuItem, Tooltip } from '@mui/material';
import {
  FormikHelpers,
  useFormik,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from 'urql';
import * as yup from 'yup';

const GetIndicators = graphql(`
  query GetIndicators($orgId: ID!, $appId: ID!) {
    me {
      id
      organization(id: $orgId) {
        id
        app(id: $appId) {
          id
          indicators {
            id
            name
          }
        }
      }
    }
  }
`);

export interface ObjectiveFormValues {
  indicatorId?: string;
  name: string;
  threshold: {
    gte?: string;
    lte?: string;
  };
  percentile: Percentile;
}

interface ObjectiveFormProps {
  appId: string;
  objective?: Pick<
    Objective,
    'name' | 'indicator' | 'threshold' | 'percentile'
  >;
  onClose: () => void;
  onDelete?: () => void;
  onDirty: (dirty: boolean) => void;
  onSubmit: (
    values: ObjectiveFormValues,
    formikHelpers: FormikHelpers<ObjectiveFormValues>
  ) => void | Promise<void>;
  orgId: string;
}

const Info = styled.div`
  padding: 24px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 24px;
  color: rgba(255, 255, 255, 0.72);
  font: 400 13px/16px 'Lexend', sans-serif;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);

  > a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.72);
  }
`;

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
`;

const Anchor = styled.a`
  display: flex;
  align-items: center;
`;

const HelpIcon = styled(Help)`
  height: 16px !important;
  width: 16px !important;
`;

const Spacer = styled.div`
  flex: 1 auto;
`;

const Actions = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 24px;
`;

export function ObjectiveForm(props: ObjectiveFormProps): ReactElement {
  const [thresholdType, setThresholdType] = useState<ThresholdType | null>(
    (props.objective?.threshold as BoundedThreshold)?.gte
      ? ThresholdType.BOUNDED
      : props.objective
      ? ThresholdType.LOWER
      : null
  );
  const [{ data }] = useQuery({
    query: GetIndicators,
    variables: {
      orgId: props.orgId,
      appId: props.appId,
    },
  });

  const validationSchema = yup.object({
    indicatorId: yup
      .string()
      .when(
        '$objective',
        (
          objective: ObjectiveFormProps['objective'],
          schema: yup.StringSchema
        ) => {
          if (objective) {
            return schema;
          }
          return schema.required(
            'Please select an indicator for this objective.'
          );
        }
      ),
    name: yup.string().required('Please provide a name for this objective.'),
    threshold: yup.object({
      lte: yup
        .number()
        .positive()
        .required('Please provide a threshold value.'),
      gte: yup
        .number()
        .positive()
        .when(
          '$thresholdType',
          (type: ThresholdType, schema: yup.NumberSchema) => {
            if (type === ThresholdType.BOUNDED) {
              return schema.required('Please provide a threshold value.');
            }
            return schema;
          }
        ),
    }),
  });

  const {
    dirty,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    touched,
    values,
  } = useFormik<ObjectiveFormValues>({
    initialValues: {
      name: props.objective?.name ?? '',
      threshold: props.objective
        ? {
            gte: (
              props.objective.threshold as BoundedThreshold
            )?.gte?.toString(),
            lte: (
              props.objective.threshold as BoundedThreshold
            ).lte?.toString(),
          }
        : { lte: '', gte: '' },
      percentile: props.objective?.percentile ?? Percentile.NINETY_NINTH,
    },
    validate: async (values) => {
      try {
        await validateYupSchema(values, validationSchema, true, {
          objective: props.objective,
          thresholdType,
        });
      } catch (e) {
        return yupToFormErrors(e);
      }
      return {};
    },
    validateOnMount: true,
    onSubmit: props.onSubmit,
  });

  useEffect(() => {
    props.onDirty(dirty);
  }, [dirty, props.onDirty]);

  if (!data?.me.organization?.app) {
    return <Loading />;
  }

  if (data.me.organization.app.indicators.length === 0) {
    return (
      <Warn title="No Indicators">
        <p>
          You must create at least one indicator for this application before
          creating an objective.
        </p>
        <p>
          <Link to={`/org/${props.orgId}/app/${props.appId}/indicator/add`}>
            Create an Indicator
          </Link>
        </p>
      </Warn>
    );
  }

  return (
    <>
      <Info>
        <Tooltip title="Learn more about objectives" enterDelay={350}>
          <a
            href="https://getpolaris.ai/docs/react/guides/objectives/"
            target="_blank"
            rel="noreferrer"
          >
            <Help />
          </a>
        </Tooltip>
        <p>
          It is critical to establish performance and reliability objectives and
          key results for your application. Objectives are simply what you want
          to accomplish. Indicators are the key results.
        </p>
      </Info>
      <Form onSubmit={handleSubmit}>
        <FormBody>
          <Input
            error={touched.name && Boolean(errors.name)}
            fullWidth
            helperText={
              touched.name && errors.name
                ? errors.name
                : 'TIP: use "/" to group objectives'
            }
            label="Objective Name"
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
          />
          {!props.objective && (
            <FormControl>
              <Select
                error={touched.indicatorId && Boolean(errors.indicatorId)}
                name="indicatorId"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.indicatorId ?? ''}
              >
                {data?.me.organization?.app?.indicators?.map((indicator) => (
                  <MenuItem
                    key={`objective-indicator-${indicator.id}`}
                    value={indicator.id}
                  >
                    {indicator.name}
                  </MenuItem>
                ))}
              </Select>
              {touched.indicatorId && errors.indicatorId && (
                <FormHelperText>{errors.indicatorId}</FormHelperText>
              )}
            </FormControl>
          )}
          <ObjectiveThreshold
            errors={errors.threshold}
            name="threshold"
            onBlur={handleBlur}
            onChange={handleChange}
            onThresholdTypeChange={setThresholdType}
            thresholdType={thresholdType}
            touched={touched.threshold}
            value={values.threshold}
          />
          <ObjectivePercentile
            errors={errors.percentile}
            name="percentile"
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched.percentile}
            value={values.percentile}
          />
        </FormBody>
        <Spacer />
        <Actions>
          {props.objective && props.onDelete && (
            <Button type="button" onClick={props.onDelete} color="warn">
              Delete Objective
            </Button>
          )}
          <Button type="button" onClick={props.onClose}>
            Cancel
          </Button>
          <Button type="submit" disabled={!isValid} raised>
            Save Objective
          </Button>
        </Actions>
      </Form>
    </>
  );
}
