import { useApplication } from '@event-horizon/app-applications';
import { Drawer } from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'urql';
import { SuggestedIndicatorCard } from './SuggestedIndicatorCard';
import styled from '@emotion/styled';
import { IndicatorCardGhostElement } from './IndicatorCardGhostElement';

const SuggestIndicatorsMutation = graphql(`
  mutation SuggestIndicators($input: SuggestIndicatorsInput!) {
    suggestIndicators(input: $input) {
      appId
      name
      operation
      predicateFunction
      window
    }
  }
`);

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;

  p {
    color: rgba(255, 255, 255, 0.72);
    font-family: Lexend;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

export function SuggestIndicatorsPage() {
  const { application } = useApplication();
  const [{ data: result }, load] = useMutation(SuggestIndicatorsMutation);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  if (!isLoading) {
    setIsLoading(true);

    load({
      input: {
        appId: application.id,
      },
    });
  }

  return (
    <Drawer
      title="Suggest Indicators"
      open={true}
      onClose={() => navigate('../')}
    >
      <CardList>
        <p>
          Our AI suggests indicators based on the measurements you send to
          Polaris along with your existing indicators.
        </p>
        {result ? (
          result.suggestIndicators.map((indicator) => (
            <SuggestedIndicatorCard
              indicator={indicator}
              key={indicator.name}
            />
          ))
        ) : (
          <IndicatorCardGhostElement />
        )}
      </CardList>
    </Drawer>
  );
}
