import {
  Fab,
  glassSurface,
  GridWrapper,
  Input,
  PageContainer,
  SelectCellRenderer,
  SelectCellRendererProps,
} from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { useOrganization } from '@event-horizon/app-organizations';
import { UserRole } from '@event-horizon/graphql-api-schema';
import { Add, Search } from '@mui/icons-material';
import { InputAdornment, Tooltip } from '@mui/material';
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
  IRowNode,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { NavLink, Outlet, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation, useQuery } from 'urql';
import { UpdateUserDrawer } from './UpdateUserDrawer';

const GetOrganizationUsersPage = graphql(`
  query GetOrganizationUsersPage($orgId: ID!) {
    me {
      id
      name
      email
      organization(id: $orgId) {
        id
        usersConnection {
          edges {
            role
            node {
              id
              name
              email
            }
          }
        }
      }
    }
  }
`);

const UpdateUserRole = graphql(`
  mutation UpdateUserRole($input: UpdateUserRoleInput!) {
    updateUserRole(input: $input) {
      __typename
    }
  }
`);

type TData = {
  id: string;
  name: string | null;
  email: string;
  role: string;
};

const FabWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;

  @media (min-width: 960px) {
    padding: 24px;
  }
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  padding: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
`;

const OrganizationUsers = styled.div`
  flex: 1 auto;
  padding: 24px;
`;

const Card = styled.div`
  ${glassSurface};
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

const Header = styled.header`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > h3 {
    color: #b557ff;
    font-size: 11px;
    font-weight: 900;
    text-transform: uppercase;
    margin-top: -8px;
  }

  > h2 {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: -6px;
  }
`;

const OrganizationUsersGridWrapper = styled(GridWrapper)`
  .ag-cell,
  .ag-header-cell {
    border-right: none;
  }
`;

const FilterInput = styled(Input)`
  width: 100%;
  max-width: 300px;
`;

export function OrganizationUsersPage(): ReactElement {
  const { organization } = useOrganization();
  const [api, setApi] = useState<GridApi | null>(null);
  const [editorOpen, setEditorOpen] = useState(false);
  const [query, setQuery] = useState<string>('');
  const [selectedRowData, setSelectedRowData] = useState<TData | undefined>(
    undefined
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [{ fetching, data }] = useQuery({
    query: GetOrganizationUsersPage,
    variables: {
      orgId: organization.id,
    },
  });
  const [, updateUserRole] = useMutation(UpdateUserRole);

  const edges = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.me.organization?.usersConnection.edges ?? [];
  }, [data]);

  const filteredRowData = useMemo<TData[]>(() => {
    if (query.length === 0)
      return edges.map((edge) => ({ ...edge.node, role: edge.role }));
    const regEx = new RegExp(`${query}`, 'i');
    return edges
      .filter((edge) => {
        return (
          regEx.test(edge.node.name ?? '') ||
          regEx.test(edge.node.email) ||
          regEx.test(edge.role)
        );
      })
      .map((edge) => ({
        ...edge.node,
        role: edge.role,
      }));
  }, [edges, query]);

  const handleOnChange = useCallback(
    (data: TData, node: IRowNode<TData>) => {
      node.setData(data);
      updateUserRole({
        input: {
          userId: data.id,
          organizationId: organization.id,
          role: data.role as UserRole,
        },
      });
    },
    [organization.id, updateUserRole]
  );

  const handleOnGridReady = useCallback((event: GridReadyEvent) => {
    setApi(event.api);
    event.api.sizeColumnsToFit({
      columnLimits: [{ key: 'role', maxWidth: 120 }],
    });
  }, []);

  const handleOnQueryChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      searchParams.set('query', event.target.value);
      setSearchParams(searchParams);
    },
    []
  );

  const handleOnCellClicked = useCallback(
    (event: CellClickedEvent<TData>) => {
      if (event.column.getId() === 'role') {
        return;
      }
      setSelectedRowData(event.data);
      setEditorOpen(true);
      if (api) {
        api.deselectAll();
      }
    },
    [api]
  );

  const [defaultColDef] = useState<ColDef>({
    sortable: false,
    filter: false,
    width: 130,
  });

  const [colDefs] = useState<ColDef<TData>[]>([
    { field: 'name', flex: 1 },
    { field: 'email', flex: 1, minWidth: 260 },
    {
      field: 'role',
      cellRenderer: SelectCellRenderer,
      cellRendererParams: {
        options: [
          { value: UserRole.MEMBER, label: 'Member' },
          { value: UserRole.OWNER, label: 'Owner' },
        ],
        field: 'role',
        onChange: handleOnChange,
      } as SelectCellRendererProps<TData, string>,
      minWidth: 160,
      suppressMovable: true,
    },
  ]);

  useEffect(() => {
    if (searchParams.has('query')) {
      setQuery(searchParams.get('query') ?? '');
    }
  }, [searchParams]);

  return (
    <>
      <PageContainer>
        <Controls>
          <FilterInput
            label="Filter"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
            value={query}
            onChange={handleOnQueryChange}
          />
        </Controls>
        <OrganizationUsers>
          <Card>
            <Header>
              <Heading>
                <h3>{organization.name}</h3>
                <h2>Members</h2>
              </Heading>
            </Header>
            <OrganizationUsersGridWrapper
              className="ag-theme-material"
              rowSelection="single"
            >
              <AgGridReact
                defaultColDef={defaultColDef}
                columnDefs={colDefs}
                rowData={filteredRowData}
                suppressRowHoverHighlight={true}
                suppressCellFocus={true}
                onGridReady={handleOnGridReady}
                onCellClicked={handleOnCellClicked}
              />
            </OrganizationUsersGridWrapper>
          </Card>
        </OrganizationUsers>
        <UpdateUserDrawer
          open={editorOpen}
          onClose={() => setEditorOpen(false)}
          organization={organization}
          user={selectedRowData}
        />
        <FabWrapper>
          <Tooltip title="Invite User" enterDelay={350}>
            <Fab as={NavLink} to="./add">
              <Add />
            </Fab>
          </Tooltip>
        </FabWrapper>
      </PageContainer>
      <Outlet
        context={{
          organization,
        }}
      />
    </>
  );
}
