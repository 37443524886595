import { Drawer, Loading, ObjectTree } from '@event-horizon/app-components';
import { graphql } from '@event-horizon/app-graphql';
import { MeasurementResult } from '@event-horizon/graphql-api-schema';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from 'urql';

const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    color: rgba(255, 255, 255, 0.64);
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
`;

const Measurement = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  padding: 24px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 4px;

    > p {
      font: 400 13px/16px Lexend, sans-serif;

      > span {
        color: rgba(255, 255, 255, 0.64);
        margin: 0 0.5rem;
      }

      &.measurement-success {
        font-weight: 600;
        color: rgba(16, 221, 211, 1);
      }

      &.measurement-failure {
        font-weight: 600;
        color: rgba(255, 0, 0, 1);
      }
    }
  }
`;

const Payload = styled.div`
  display: flex;
  flex-direction: column;

  > h2 {
    padding: 24px 24px 0;
  }

  > div {
    padding: 16px 24px;
  }
`;

const GetMeasurementPage = graphql(`
  query GetMeasurementPage(
    $orgId: ID!
    $appId: ID!
    $indicatorId: ID!
    $measurementId: ID!
  ) {
    me {
      id
      organization(id: $orgId) {
        id
        name
        app(id: $appId) {
          id
          name
          indicator(id: $indicatorId) {
            id
            name
            measurement(id: $measurementId) {
              id
              duration
              metadata {
                connectionQuality
                deviceType
                userMetadata
              }
              name
              result
              timestamp
              url
            }
          }
        }
      }
    }
  }
`);

export function MeasurementPage(): ReactElement {
  const { appId, indicatorId, measurementId, orgId } = useParams<{
    orgId: string;
    appId: string;
    indicatorId: string;
    measurementId: string;
  }>();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);

  if (!orgId) {
    throw new Error('The orgId parameter is required.');
  }

  if (!appId) {
    throw new Error('The appId parameter is required.');
  }

  if (!indicatorId) {
    throw new Error('The indicatorId parameter is required.');
  }

  if (!measurementId) {
    throw new Error('The measurementId parameter is required.');
  }

  const [{ data, fetching }] = useQuery({
    query: GetMeasurementPage,
    variables: {
      orgId,
      appId,
      indicatorId,
      measurementId,
    },
  });

  const measurement = useMemo(() => {
    if (!data) {
      return null;
    }
    const measurement = data.me.organization?.app?.indicator?.measurement;
    if (!measurement) {
      return null;
    }
    return {
      ...measurement,
      metadata: {
        ...measurement.metadata,
        userMetadata: JSON.parse(measurement.metadata.userMetadata),
      },
    };
  }, [data]);

  const handleOnClose = useCallback(() => {
    setOpen(false);
    navigate('../');
  }, []);

  const resultClassName = useCallback((result: MeasurementResult) => {
    if (!result) {
      return '';
    }
    switch (result) {
      case MeasurementResult.SUCCESS:
        return 'measurement-success';
      case MeasurementResult.FAILURE:
        return 'measurement-failure';
      default:
        return '';
    }
  }, []);

  return (
    <Drawer onClose={handleOnClose} open={open} title="Measurement Explorer">
      <DrawerContainer>
        {fetching && <Loading />}
        {!fetching && measurement && (
          <>
            <Measurement>
              <div>
                <h2>Timestamp</h2>
                <p>
                  {new Date(measurement.timestamp).toLocaleDateString()}
                  <span>on</span>
                  {new Date(measurement.timestamp).toLocaleTimeString()}
                </p>
              </div>
              <div>
                <h2>Result</h2>
                <p className={resultClassName(measurement.result)}>
                  {measurement.result}
                </p>
              </div>
              <div>
                <h2>Duration</h2>
                <p>
                  {new Intl.NumberFormat('en-US', {
                    style: 'unit',
                    maximumFractionDigits: 3,
                    minimumFractionDigits: 3,
                    unit: 'second',
                  }).format(measurement.duration / 1000)}
                </p>
              </div>
            </Measurement>
            <Payload>
              <h2>Payload</h2>
              <div>
                <ObjectTree value={measurement} />
              </div>
            </Payload>
          </>
        )}
      </DrawerContainer>
    </Drawer>
  );
}
